import React, { useEffect, useState } from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";

import { CSVLink } from "react-csv";
import FilterRecords from "../../shared/FilterRecords";
import DataTable from "react-data-table-component";
import DataTableView from "../../shared/DataTable";
import { ToastContainer } from "react-toastify";
import { ErrorNotify, DismissToast, LoadingNotify, SuccessNotify } from "../../shared/Toast";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import $, { removeData } from "jquery";

import { components } from "react-select";
import Select from 'react-select';
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
// var data = [];

var csvReport = {
  data: [],
};
var pdfReport = {
  data: [],
};
var xlsReport = {
  data: [],
};

var columns = [
  {
    Header: "Action",
    accessor: "action", // accessor is the "key" in the data
  },
  {
    Header: "CallID",
    accessor: "call_id",
  },
  {
    Header: "Agent Name",
    accessor: "agent_name",
  },
  {
    Header: "Agent ID",
    accessor: "agent_id",
  },
  {
    Header: "Supervisor Name",
    accessor: "supervisor_name",
  },

  {
    Header: "Supervisor ID",
    accessor: "supervisor_id",
  },

  {
    Header: "Total Score",
    accessor: "total_score",
  },
  {
    Header: "Overall Score",
    accessor: "overall_score",
  },

  {
    Header: "Call Date & Time",
    accessor: "call_date_time",
  },

  {
    Header: "Call Duration",
    accessor: "call_duration",
  },
  {
    Header: "Evaluation Date & Time",
    accessor: "evaluation_date_time",
  },
  {
    Header: "Evaluation Duration",
    accessor: "evaluation_duration",
  },
  {
    Header: "Evaluator",
    accessor: "evaluator",
  },

  {
    Header: "Last Reviewed By",
    accessor: "last_reviewed_by",
  },
  {
    Header: "Last Reviewed Date",
    accessor: "last_reviewed_date",
  },
  {
    Header: "Audit By",
    accessor: "audit_by",
  },

  {
    Header: "Form Name",
    accessor: "form_name",
  },

  // {
  //     Header: 'Affiliation',
  //     accessor: 'affiliation',
  // },

  {
    Header: "ATA Status",
    accessor: "ata_status",
  },

  // {
  //   Header: "Language",
  //   accessor: "language",
  // },

  // {
  //   Header: "Conversation Or Recording Duration",
  //   accessor: "conversation_or_recording_duration",
  // },

  // {
  //   Header: "Type Of Evaluations",
  //   accessor: "type_of_evaluations",
  // },

  // {
  //   Header: "Call Type",
  //   accessor: "call_type",
  // },
  // {
  //   Header: "Call CRM",
  //   accessor: "call_crm",
  // },
  {
    Header: "Evaluation Status",
    accessor: "evaluation_status",
  },
]

const headers = [
  { label: "Call ID", key: "call_id" },
  { label: "Agent Name", key: "agent_name" },
  { label: "Agent ID", key: "agent_id" },
  { label: "Supervisor Name", key: "supervisor_name" },

  { label: "Supervisor ID", key: "supervisor_id" },
  { label: "Total Score", key: "total_score" },
  { label: "Overall Score", key: "overall_score" },

  { label: "Call Date & Time", key: "call_date_time" },
  { label: "Evaluation Date & Time", key: "evaluation_date_time" },

  { label: "Evaluation Duration", key: "evaluation_duration" },
  { label: "Evaluator Name", key: "evaluator_name" },

  { label: "Last Reviewed By", key: "last_reviewed_by" },
  { label: "Last Reviewed Date", key: "last_reviewed_date" },

  { label: "Audit By", key: "audit_by" },
  { label: "Form Name", key: "display_name" },
  { label: "Campaign", key: "Campaign" },
  { label: "LOB", key: "LOB" },
  { label: "Vendor", key: "Vendor" },
  { label: "Location", key: "Location" },

  { label: "Affiliation", key: "affiliation" },
  { label: "ATA Status", key: "ata_status" },
  { label: "Evaluation Status", key: "evaluation_status" },
];

const data = [
  {
    emp_id: "Warren",
    name: "Morrow",
    email: "sokyt@mailinator.com",
    status: "active",
  },
];

const EvaluationSummary = () => {
  TabTitle("Evaluation Summary :: NEQQO");

  const [evaLisspanata, setEvaLisspanata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pending, setPending] = React.useState(true);
  const [filterPayload, setFilterPayload] = React.useState();
  const [from_date, setFromDate] = useState(
    moment().clone().startOf("month").format("YYYY-MM-DD")
  );
  const [to_date, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const [call_id, setCallId] = useState();
  const [agent_id, setAgentId] = useState("");
  const [cardData, setCardData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [callIdForAccept, setCallIdForAccept] = useState("");
  const [comment, setComment] = useState("")
  const [selectDecision, setSelectDecision] = useState("")
  const [passOrFail, setPassOrFail] = useState("")
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [fromDateForDispute, setFromDateForDispute] = useState()
    const [toDateForDispute, setToDateForDispute] = useState()
    const [evalAcceptLoad, setEvalAcceptLoad] = useState(false)



  


  const [acceptDecisionPass, setAcceptDecisionPass] = useState([{label:"Maps Feedback",value:"maps_feedback"},{label:"Coaching",value:"coaching"}])
  const [acceptDecisionFail, setAcceptDecisionFail] = useState([
    {label:"Maps Feedback",value:"maps_feedback"},
    {label:"PIP",value:"pip"},
    {label:"Action Contract",value:"action_contract"},
    {label:"Coaching",value:"coaching"},
    {label:"Formal Discplinary",value:"formal_discplinary"}

])

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;

    if ((acceptDecisionPass && acceptDecisionPass?.length > 0) || (acceptDecisionFail && acceptDecisionFail?.length > 0)) {
      return (
        <components.ValueContainer {...props}>
          {`${acceptDecisionPass?.length || acceptDecisionFail?.length } items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {

    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
  }, []);
  const [hierarchyData, setHierarchyData] = useState([]);
  useEffect(() => {
    const getCustomHierarchyData = async () => {
      var url = `${base_url}hierarchy/get-custom-hierarchy`;

      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("loginToken"),
        },
      };

      await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          console.log("hierarchy data======", data);
          if (isNaN(data.status)) {
            // Logout();
            return;
          }
          var arr = [];
          if (data?.status == 200) {
            data.data.map((i, j) => {
              arr[i.custom1_id] = i.custom1_name;
              arr[i.custom2_id] = i.custom2_name;
              arr[i.custom3_id] = i.custom3_name;
              arr[i.custom4_id] = i.custom4_name;
            });
            setHierarchyData(arr);
             columns =  [
              {
                Header: "Action",
                accessor: "action", // accessor is the "key" in the data
              },
              {
                Header: "CallID",
                accessor: "call_id",
              },
              {
                Header: "Agent Name",
                accessor: "agent_name",
              },
              {
                Header: "Agent ID",
                accessor: "agent_id",
              },
              {
                Header: "Supervisor Name",
                accessor: "supervisor_name",
              },
        
              {
                Header: "Supervisor ID",
                accessor: "supervisor_id",
              },
        
              {
                Header: "Total Score",
                accessor: "total_score",
              },
              
        
              {
                Header: "Call Date & Time",
                accessor: "call_date_time",
              },
        
              {
                Header: "Call Duration",
                accessor: "call_duration",
              },
              {
                Header: "Evaluation Date & Time",
                accessor: "evaluation_date_time",
              },
              {
                Header: "Evaluation Duration",
                accessor: "evaluation_duration",
              },
              {
                Header: "Evaluator",
                accessor: "evaluator",
              },
        
              {
                Header: "Last Reviewed By",
                accessor: "last_reviewed_by",
              },
              {
                Header: "Last Reviewed Date",
                accessor: "last_reviewed_date",
              },
              {
                Header: "Audit By",
                accessor: "audit_by",
              },
        
              {
                Header: "Form Name",
                accessor: "form_name",
              },
        
              {
                Header: `${arr.c1 === undefined ? "" : arr.c1}`,
                accessor: arr.c1, // accessor is the "key" in the data
              },
              {
                Header: `${arr.c2 === undefined ? "" : arr.c2}`,
                accessor: arr.c2,
              },
              {
                Header: `${arr.c3 === undefined ? "" : arr.c3}`,
                accessor: arr.c3,
              },
              {
                Header: `${arr.c4 === undefined ? "" : arr.c4}`,
                accessor: arr.c4,
              },
        
              // {
              //     Header: 'Affiliation',
              //     accessor: 'affiliation',
              // },
        
              {
                Header: "ATA Status",
                accessor: "ata_status",
              },
        
              // {
              //   Header: "Language",
              //   accessor: "language",
              // },
        
              // {
              //   Header: "Conversation Or Recording Duration",
              //   accessor: "conversation_or_recording_duration",
              // },
        
              // {
              //   Header: "Type Of Evaluations",
              //   accessor: "type_of_evaluations",
              // },
        
              // {
              //   Header: "Call Type",
              //   accessor: "call_type",
              // },
              // {
              //   Header: "Call CRM",
              //   accessor: "call_crm",
              // },
              {
                Header: "Evaluation Status",
                accessor: "evaluation_status",
              },
            ]
          } else if (data?.status >= 400 || data?.status <= 422) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            setHierarchyData([]);

            return;
          }
        })
        .catch((err) => {
          DismissToast();
          ErrorNotify("Something went wrong.Please contact to Administrator !", {
            icon: "error",
          });
          setIsLoading(false);
        });
    };
    getCustomHierarchyData();
  }, []);

  useEffect(() => {
    var url = `${base_url}uk-dispute-listing`;
    
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        from_date: from_date,
        to_date: to_date,
        date_type: "call_date",
        affiliation: "",
        custom1: [],
        custom2: [],
        custom3: [],
        custom4: [],
        card_type: "",
        form: [],
        manager3: "",
        summary_type: 1,
        manager2: "",
        manager1: "",
        supervisor: [],
        agent: [],
        call_id: "",
        agent_id: [],
        // "user_id": localStorage.getItem('loginUserUniqueid'),
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_user_name:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("proxyUserName")
            : "",
      }),
    };
    setIsLoading(true);
    disputeListing(url,requestOptions)
    const cardCountUrl = `${base_url}dispute-summary-count`;
    gespanata(cardCountUrl, requestOptions);
  }, [hierarchyData]);




  const gespanata = (url, requestOptions) => {
    fetch(url, requestOptions)
     .then((res) => res.json())
     .then((data) => {
       console.log("setEvaLisspanata=========", data?.data);

       if (data?.status == 200 || data?.status == 201) {
         setCardData(data?.data);
         setIsLoading(false);
       } else if (data?.status >= 400 || data?.status <= 422) {
         if (data?.status == 401) {
           ErrorNotify(data.message, {
             icon: "error",
           });
           localStorage.clear();
           navigate("/");
           setIsLoading(false);
           return;
         } else {
           ErrorNotify(data.message, {
             icon: "error",
           });
           setIsLoading(false);
           setCardData([]);
           setEvaLisspanata([]);
           csvReport = {
             data: [],
             headers: headers,
             filename: "evaluationsummary.csv",
           };
           return;
         }
       } else {
         ErrorNotify(data.message, {
           icon: "error",
         });
         setIsLoading(false);
         setCardData([]);
         setEvaLisspanata([]);
         csvReport = {
           data: [],
           headers: headers,
           filename: "evaluationsummary.csv",
         };
         return;
       }
     })
     .catch((err) => {
       DismissToast();
       ErrorNotify("Something went wrong. Please contact to Administrator !", {
         icon: "error",
       });
       setIsLoading(false);
       return;
       // console.log('========================', data.data);
     });
 };

 const disputeListing = (url, requestOptions) => {
   fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log("dispute Listing=========", data?.data);

      if (data?.status == 200 || data?.status == 201) {
        setIsLoading(false);
        let csvArr = data?.data;
        if (csvArr?.length > 0) {
          csvArr?.map((item) => {
            Object.entries(item).forEach(([key, val]) => {
              if (key == "custom1") {
                delete item.custom1
                let c1Key = hierarchyData?.c1
                item[c1Key] = val
              }
              if (key == "custom2") {
                delete item.custom2
                let c2Key = hierarchyData?.c2
                item[c2Key] = val
              }
              if (key == "custom3") {
                delete item.custom3
                let c3Key = hierarchyData?.c3
                item[c3Key] = val
              }
              if (key == "custom4") {
                delete item.custom4
                let c4Key = hierarchyData?.c4
                item[c4Key] = val
              }
              
            });
              item['display_name'] = item.display_name + "(V" + item.form_version+")"
          });
          
        }
        setEvaLisspanata(csvArr);
        
        csvReport = {
          data: csvArr,
          headers: headers,
          filename: "evaluationsummary.csv",
        };
      } else if (data?.status >= 400 || data?.status <= 422) {
        if (data?.status == 401) {
          ErrorNotify(data.message, {
            icon: "error",
          });
          localStorage.clear();
          navigate("/");
          setIsLoading(false);
          return;
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setIsLoading(false);
          setCardData([]);
          setEvaLisspanata([]);
          csvReport = {
            data: [],
            headers: headers,
            filename: "evaluationsummary.csv",
          };
          return;
        }
      } else {
        ErrorNotify(data.message, {
          icon: "error",
        });
        setIsLoading(false);
        setCardData([]);
        setEvaLisspanata([]);
        csvReport = {
          data: [],
          headers: headers,
          filename: "evaluationsummary.csv",
        };
        return;
      }
    })
    .catch((err) => {
      DismissToast();
      ErrorNotify("Something went wrong. Please contact to Administrator !", {
        icon: "error",
      });
      setIsLoading(false);
      return;
      // console.log('========================', data.data);
    });
};

  const acceptEval = (j) => {
    
    $(`#accept_modal_${j}`).attr("data-target", "#accept_modal");
  }

  const data = React.useMemo(() =>
    evaLisspanata
      ?.filter((item) => {
        return searchValue.toLowerCase() == ""
          ? item && item
          : (item.call_id &&
              item.call_id.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.display_name &&
                item.display_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.custom1 &&
                item.custom1
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.custom2 &&
                item.custom2
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.custom3 &&
                item.custom3
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.custom4 &&
                item.custom4
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.agent_name &&
                item.agent_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.agent_id &&
                item.agent_id.toLowerCase().includes(searchValue)) ||
              (item.supervisor_id &&
                item.supervisor_id.toLowerCase().includes(searchValue)) ||
              (item.supervisor_name &&
                item.supervisor_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.ata_status &&
                item.ata_status
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.evaluator &&
                item.evaluator
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.evaluation_status &&
                item.evaluation_status
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()));
      })
      .map((i, j) => ({
        action: [
          <span className="col-actions sorting_1">
            <ul className="action-strip primary iconic">
              {(
                localStorage.getItem("settedProxy") !== null &&
                localStorage.getItem("settedProxy") !== undefined
                  ? localStorage.getItem("proxyUserId")
                  : localStorage.getItem("loginUserUniqueid")
              ) ? (
                <li>
                  <Link
                    className=""
                    to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=review&fromList=evalSummary&call_id=${i.call_id}`}
                    target="_blank"
                  >
                    <i className="las la-search"></i>
                  </Link>
                  <span className="iconic-tooltip">Review</span>
                </li>
              ) : (
                ""
              )}
              {(localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("proxyUserRole")
                : localStorage.getItem("loginUserRole")) !== "QS" ? (
                  <>
                  {

                    i?.decision===0?

                  
                <li>
                  <Link
                    className=""
                    to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=escalate&call_id=${i.call_id}`}
                    target="_blank"
                  >
                    <i className="las la-exclamation-triangle"></i>
                  </Link>
                  {/* <span className="iconic-tooltip">Escalate</span> */}
                </li>
                :
                <li>

                <i className="las la-exclamation-triangle" style={{fontSize:"22px",color:"#c0c0c0",}}></i>
                </li>

              }
                {
                    i?.decision===0?
                  
                  <li>
                    <a
                      data-toggle="modal"
                      id={`accept_modal_${j}`}
                      onClick={() => [acceptEval(j),setCallIdForAccept(i?.call_id),setComment(""),setSelectDecision(""),setPassOrFail((parseInt(i?.total_score)?.toFixed(0) >= (window.location.host?.split(".")[0] === "hyundai" ? 90 : 85)) ? "pass" : "fail")]}
                      // onClick={() => [setAcceptModal(true)]}
                    >
                      <i class="las la-check-square" style={{ color: "green" }}></i>
                    </a>
                    <span className="iconic-tooltip">Accept</span>
                  </li>
                  :
                  <li>
                    
                  <i class="las la-check-square" style={{ color:"#c0c0c0",fontSize:"20px",marginLeft:"2px" }}></i>
                  
                  </li>
              
                 
                  }
                </>
              ) : (
                ""
              )}
              {(localStorage.getItem("settedProxy") !== null &&
              localStorage.getItem("settedProxy") !== undefined
                ? localStorage.getItem("proxyUserRole")
                : localStorage.getItem("loginUserRole")) !== "QS" ? (
                <li>
                  <Link
                    className=""
                    to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=feedback&call_id=${i.call_id}`}
                    target="_blank"
                  >
                    <i className="las la-comment-dots"></i>
                  </Link>
                  <span className="iconic-tooltip">Coach</span>
                </li>
              ) : (
                ""
              )}{" "}
            </ul>{" "}
          </span>,
        ],

        call_id: [
          <Link
            target="_blank"
            to={`/new-form-preview?formName=${i.form_name}&formVersion=${i.form_version}&authSecondId=${i.authorized_second_id}&resolvedSecId=${i.resolved_second_id}&moduleName=evaluate&fromList=evalSummary&call_id=${i.call_id}&decision=${i?.decision}&passOrFail=${i?.total_score}&from_date=${fromDateForDispute?fromDateForDispute:moment().clone().startOf("month").format("YYYY-MM-DD")}&to_date=${toDateForDispute?toDateForDispute:moment().format("YYYY-MM-DD")}`}
          >
            {i.call_id}
          </Link>,
        ],
        agent_name: [<span>{i.agent_name}</span>],
        agent_id: [<span>{i.agent_id}</span>],
        supervisor_name: [<span>{i.supervisor_name}</span>],
        supervisor_id: [<span>{i.supervisor_id}</span>],
        total_score: [<span>{i.total_score}</span>],
        overall_score: [<span>{i.overall_score}</span>],
        call_date_time: [<span>{i.call_date_time}</span>],
        call_duration: [<span>{i.call_duration}</span>],
        evaluation_date_time: [<span>{i.evaluation_date_time}</span>],
        evaluation_duration: [<span>{i.evaluation_duration}</span>],
        evaluator: [
          <span>
            {i.evaluator_name}
            {" ("}
            {i.evaluator_id}
            {")"}
          </span>,
        ],
        last_reviewed_by: [<span>{i.last_reviewed_by}</span>],
        last_reviewed_date: [<span>{i.last_reviewed_date}</span>],
        audit_by: [
          <span>
            {i.audit_by_name}
            {i.audit_by_id !== "" && " ("}
            {i.audit_by_id}
            {i.audit_by_id !== "" && ")"}
          </span>,
        ],
        form_name: [
          <span>
            {i.display_name}
          </span>,
        ],
        Campaign: [<span>{i.Campaign}</span>],
        LOB: [<span>{i.LOB}</span>],
        Vendor: [<span>{i.Vendor}</span>],
        Location: [<span>{i.Location}</span>],
        // affiliation: [<span>{i.affiliation}</span>],
        ata_status: [
          <span
            className={`${
              i.ata_status === "Pending" || i.ata_status === "pending"
                ? "badge beta pending"
                : i.ata_status === "abort" || i.ata_status === "Abort"
                ? "badge beta declined"
                : "badge beta accepted"
            }`}
          >
            {i.ata_status}
          </span>,
        ],
        language: [<span>{i.language}</span>],
        conversation_or_recording_duration: [
          <span>{i.conversation_duration}</span>,
        ],
        type_of_evaluations: [<span>{i.type_of_evaluations}</span>],
        call_type: [<span>{i.call_type}</span>],
        call_crm: [<span>{i.call_crm}</span>],
        evaluation_status: [
          <span
            className={`${
              i.evaluation_status === "Pending" ||
              i.evaluation_status === "pending"
                ? "badge beta pending"
                : i.evaluation_status === "abort" ||
                  i.evaluation_status === "Abort"
                ? "badge beta declined"
                : "badge beta accepted"
            }`}
          >
            {i.evaluation_status}
          </span>,
        ],
      }))
  );

  console.log(evaLisspanata,"eval Data")

  const setListingDataFunc = (val) => {
    console.log("searching data====================", val);
    // setEvaLisspanata(val);

    let csvArr = val;
          if (csvArr?.length > 0) {
            csvArr?.map((item) => {
              Object.entries(item).forEach(([key, val]) => {
                if (key == "custom1") {
                  delete item.custom1
                  let c1Key = hierarchyData?.c1
                  item[c1Key] = val
                }
                if (key == "custom2") {
                  delete item.custom2
                  let c2Key = hierarchyData?.c2
                  item[c2Key] = val
                }
                if (key == "custom3") {
                  delete item.custom3
                  let c3Key = hierarchyData?.c3
                  item[c3Key] = val
                }
                if (key == "custom4") {
                  delete item.custom4
                  let c4Key = hierarchyData?.c4
                  item[c4Key] = val
                }
              });
              item['display_name'] = item.display_name + "(V" + item.form_version+")"
            });
          }
          setEvaLisspanata(csvArr);
          
          csvReport = {
            data: csvArr,
            headers: headers,
            filename: "evaluationsummary.csv",
          };
  };

  const searchData = () => {
    var url = `${base_url}uk-dispute-listing`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        quick_search:
          call_id !== undefined || agent_id !== undefined ? true : false,
        from_date: "",
        to_date: "",
        date_type: "call_date",
        affiliation: "",
        custom1: [],
        custom2: [],
        custom3: [],
        custom4: [],
        form: [],
        form_version: "",
        manager2: "",
        manager1: "",
        supervisor: [],
        agent: [],
        call_id: call_id,
        agent_id: agent_id,
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_user_name:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("proxyUserName")
            : "",
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("filter data======", data);

        if (data?.status == 200 || data?.status == 201) {
          setEvaLisspanata(data.data);
          
          columns =  [
            {
              Header: "Action",
              accessor: "action", // accessor is the "key" in the data
            },
            {
              Header: "CallID",
              accessor: "call_id",
            },
            {
              Header: "Agent Name",
              accessor: "agent_name",
            },
            {
              Header: "Agent ID",
              accessor: "agent_id",
            },
            {
              Header: "Supervisor Name",
              accessor: "supervisor_name",
            },
      
            {
              Header: "Supervisor ID",
              accessor: "supervisor_id",
            },
      
            {
              Header: "Total Score",
              accessor: "total_score",
            },
      
            {
              Header: "Call Date & Time",
              accessor: "call_date_time",
            },
      
            {
              Header: "Call Duration",
              accessor: "call_duration",
            },
            {
              Header: "Evaluation Date & Time",
              accessor: "evaluation_date_time",
            },
            {
              Header: "Evaluation Duration",
              accessor: "evaluation_duration",
            },
            {
              Header: "Evaluator",
              accessor: "evaluator",
            },
      
            {
              Header: "Last Reviewed By",
              accessor: "last_reviewed_by",
            },
            {
              Header: "Last Reviewed Date",
              accessor: "last_reviewed_date",
            },
            {
              Header: "Audit By",
              accessor: "audit_by",
            },
      
            {
              Header: "Form Name",
              accessor: "form_name",
            },
      
            {
              Header: `${hierarchyData.c1 === undefined ? "" : hierarchyData.c1}`,
              accessor: hierarchyData.c1, // accessor is the "key" in the data
            },
            {
              Header: `${hierarchyData.c2 === undefined ? "" : hierarchyData.c2}`,
              accessor: hierarchyData.c2,
            },
            {
              Header: `${hierarchyData.c3 === undefined ? "" : hierarchyData.c3}`,
              accessor: hierarchyData.c3,
            },
            {
              Header: `${hierarchyData.c4 === undefined ? "" : hierarchyData.c4}`,
              accessor: hierarchyData.c4,
            },
      
            // {
            //     Header: 'Affiliation',
            //     accessor: 'affiliation',
            // },
      
            {
              Header: "ATA Status",
              accessor: "ata_status",
            },
      
            // {
            //   Header: "Language",
            //   accessor: "language",
            // },
      
            // {
            //   Header: "Conversation Or Recording Duration",
            //   accessor: "conversation_or_recording_duration",
            // },
      
            // {
            //   Header: "Type Of Evaluations",
            //   accessor: "type_of_evaluations",
            // },
      
            // {
            //   Header: "Call Type",
            //   accessor: "call_type",
            // },
            // {
            //   Header: "Call CRM",
            //   accessor: "call_crm",
            // },
            {
              Header: "Evaluation Status",
              accessor: "evaluation_status",
            },
          ]
          setIsLoading(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setIsLoading(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setIsLoading(false);
          return;
        }

        if (data.status != 200) {
          ErrorNotify(data.message);
          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
        // console.log('========================', data.data);
      });
  };

  const getDisputeByCardType = (key) => {
    setIsLoading(true);
    LoadingNotify("Please Wait...");
    var url = `${base_url}dispute-listing`;
    
    

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        quick_search:
          filterPayload?.call_id !== undefined ||
          filterPayload?.agent_id !== undefined
            ? true
            : false,
        from_date: filterPayload?.from_date
          ? filterPayload?.from_date
          : from_date,
        to_date: filterPayload?.to_date ? filterPayload?.to_date : to_date,
        date_type: filterPayload?.date_type
          ? filterPayload?.date_type
          : "call_date",
        affiliation: filterPayload?.affiliation,
        custom1: filterPayload?.custom1 ? filterPayload?.custom1 : [],
        custom2: filterPayload?.custom2 ? filterPayload?.custom2 : [],
        custom3: filterPayload?.custom3 ? filterPayload?.custom3 : [],
        custom4: filterPayload?.custom4 ? filterPayload?.custom4 : [],
        form: filterPayload?.form ? filterPayload?.form : [],
        summary_type: 1,
        // form_version: filterPayload?,
        manager2: filterPayload?.manager2 ? filterPayload?.manager2 : "",
        manager1: filterPayload?.manager1 ? filterPayload?.manager1 : "",
        supervisor: filterPayload?.supervisor ? filterPayload?.supervisor : [],
        agent: filterPayload?.agent ? filterPayload?.agent : [],
        card_type: key,
        call_id: filterPayload?.call_id ? filterPayload?.call_id : "",
        agent_id: filterPayload?.agent_id ? filterPayload?.agent_id : [],
        proxy_user_id:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? JSON.parse(localStorage.getItem("settedProxy"))?.value
            : "",
        proxy_user_name:
          localStorage.getItem("settedProxy") !== null &&
          localStorage.getItem("settedProxy") !== undefined
            ? localStorage.getItem("proxyUserName")
            : "",
      }),
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("filter data======", data);
        DismissToast();
        if (data?.status == 200 || data?.status == 201) {
          let csvArr = data?.data;
          if (csvArr?.length > 0) {
            csvArr?.map((item) => {
              Object.entries(item).forEach(([key, val]) => {
                if (key == "custom1") {
                  delete item.custom1
                  let c1Key = hierarchyData?.c1
                  item[c1Key] = val
                }
                if (key == "custom2") {
                  delete item.custom2
                  let c2Key = hierarchyData?.c2
                  item[c2Key] = val
                }
                if (key == "custom3") {
                  delete item.custom3
                  let c3Key = hierarchyData?.c3
                  item[c3Key] = val
                }
                if (key == "custom4") {
                  delete item.custom4
                  let c4Key = hierarchyData?.c4
                  item[c4Key] = val
                }
                
              });
                item['display_name'] = item.display_name + "(V" + item.form_version+")"
            });
            
          }
          setEvaLisspanata(csvArr);
          
          csvReport = {
            data: csvArr,
            headers: headers,
            filename: "evaluationsummary.csv",
          };
          setIsLoading(false);
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setIsLoading(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setIsLoading(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setIsLoading(false);
          return;
        }

        if (data.status != 200) {
          ErrorNotify(data.message);
          setIsLoading(false);
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
        // console.log('========================', data.data);
      });
  };
  const agentId = (agent_id) => {
    setAgentId(agent_id);
  };

  const callId = (call_id) => {
    setCallId(call_id);
    // if(call_id === '' && agent_id === '') {
    //     getCallData()
    // }
  };
  console.log("filterPayload", filterPayload);

  const handleEvlDecision = () => {
    setEvalAcceptLoad(true)
    var url = `${base_url}dispute/add-decision`;
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        call_id: callIdForAccept,
        decision: selectDecision,
        comment: comment,
        
      }),
    };

    callApi(url, requestOptions, "eval-decision");
  }

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "eval-decision":
            if(data?.status===200 || data?.status===201 || data?.status==="200" || data?.status==="201"){
              setTimeout(() => {
                window.location.reload()
              }, 2000);
              return SuccessNotify("Evaluation Accepted Successfully!")
            }
          break;
        }
      })
    }

  return (
    <div className="page-wrapper" style={{ backgroundImage: "none" }}>
      {/* <Header />
            <Sidebar /> */}
      <main>
        <ToastContainer />
        <div className="main-contents">
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div
                className="alert alert-info text-center"
                id="success_msg"
              ></div>
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg"></button>
            </div>
          </div>
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header d-flex justify-content-between align-items-center">
                <h3 className="sc-title d-flex align-items-center">
                  <span>Evaluation Summary</span>
                </h3>
                <div className="sc-controls">
                  <button
                    type="button"
                    className="btn btn-circle-primary filter-btn drawer-open"
                    data-target="filter_drawer"
                  >
                    <i className="las la-filter"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <nav className="ip-breadcrumb" aria-label="breadcrumb">
            <ul className="breadcrumb mb-0">
              <li className="breadcrumb-item active">
                <span>Evaluation Summary</span>
              </li>
            </ul>
          </nav>

          <FilterRecords
            setListingDataFunc={setListingDataFunc}
            setCardData={setCardData}
            setFilterPayload={setFilterPayload}
            uri="uk-dispute-listing"
            countUri="dispute-summary-count"
            fromPage="eval-summary"
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            setFromDateForDispute={setFromDateForDispute}
            setToDateForDispute={setToDateForDispute}
            
          />

          <div className="statistics-section">
            <div className="statistics-inner">
              <div className="container-fluid d-flex align-items-center">
              <div className="statistics-cards">
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluations which have been evaluated on the tool and have not been even viewed by anyone"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("new")}>
                        {cardData?.new ? cardData?.new : 0}
                      </a>
                    </h4>
                    <p>New</p>
                  </div>
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluations which have been evaluated on the tool and have been viewed by someone"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("view")}>
                        {cardData?.viewed ? cardData?.viewed : 0}

                      </a>
                    </h4>
                    <p>Viewed</p>
                  </div>
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluation where ‘Coaching’ has been accepted on the tool"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("accept")}>
                        {cardData?.accepted ? cardData?.accepted : 0}

                      </a>
                    </h4>
                    <p>Accepted</p>
                  </div>
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluations which have been disputed/escalated on the tool and are pending review/resolution"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("escalate")}>
                        {cardData?.escalated ? cardData?.escalated : 0}

                      </a>
                    </h4>
                    <p>Escalated</p>
                  </div>
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluations which have been disputed/escalated more than once on the tool and are pending review/ resolution"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("re-escalate")}>
                      {cardData?.re_escalated ? cardData?.re_escalated : 0}
                      </a>
                    </h4>
                    <p>Re-Escalated</p>
                  </div>
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluations where escalation originator has accepted escalation’s / dispute’s resolution"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("closed")}>
                      {cardData?.escalation_closed ? cardData?.escalation_closed : 0}

                      </a>
                    </h4>
                    <p>Escalation Closed</p>
                  </div>
                  <div
                    className="statistics-item"
                    data-toggle="tooltip"
                    title="This field shows count of evaluations where resolution has been provided to the dispute / escalation"
                  >
                    <h4 className="stat_count">
                      <a onClick={() => getDisputeByCardType("reviewed")}>
                      {cardData?.escalation_reviewed ? cardData?.escalation_reviewed : 0}

                      </a>
                    </h4>
                    <p>Escalation Reviewed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>


            
          <div class="modal fade" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document" style={{width:"25%"}}>
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Accept Evaluation</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                

                <div class="modal-body">
                <div className="row">
                <div className="col-md-12 col-lg-12 col-sm-12">
                          
                          <Select
                            options={passOrFail==="pass"?acceptDecisionPass:acceptDecisionFail}
                            //placeholder="Select a decision option"
                            placeholder={passOrFail==="pass"?"Select an option":"Disciplinary/Performance Management"}
                            isSearchable={true}
                            // isMulti
                            className=""
                            value={(selectDecision !== '' ? acceptDecisionPass?.find((option, index) => option.value === selectDecision) : '')||
                            (selectDecision !== '' ? acceptDecisionFail?.find((option, index) => option.value === selectDecision) : '')
                          }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options

                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            // components={{
                            //   Option,
                            //   ValueContainer: ValueContainer2,
                            // }}
                            // value={selectDecision}
                            // onChange={(e, event) => [handleTagReportChange(e, event),setTags(e?.value)]}
                            onChange={(e)=>setSelectDecision(e?.value)}
                          />
                          </div>
                          {/* <div className="col-md-6 col-lg-6 col-sm-6">
                            <input type="text" className="form-control" value={comment} onChange={(e)=>setComment(e?.target.value)} placeholder="Write a comment" />
                          </div> */}
                          </div>
                          

                          <div className="my-4">
                            <textarea  rows="4" cols="50" className="form-control" style={{maxHeight:"80px"}} value={comment} onChange={(e)=>setComment(e?.target.value)} placeholder="Write a comment">
                              </textarea>
                          </div>
                          
                </div>
                
                
                
                <div class="modal-footer">
                  {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                  <button type="button" class="btn btn-primary" onClick={()=>handleEvlDecision()}>Submit {evalAcceptLoad&&
                                            <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                          </div>}</button>
                </div>
              </div>
            </div>
          </div>



          <DataTableView
            title="Evaluation Summary"
            isPending={isLoading}
            columns={columns}
            data={data}
            searchValue={searchValue}
            allData={evaLisspanata}
            isPDFReport={true}
            isXLSReport={true}
            pdfReport={pdfReport}
            xlsReport={xlsReport}
            setSearchValue={setSearchValue}
            loading={false}
            totalRec={data?.length}
            quickSearch={true}
            evaluatorSearch={false}
            csvReport={csvReport}
            searchData={searchData}
            isCSVReport={true}
            agentId={agentId}
            callId={callId}
          />
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default EvaluationSummary;
