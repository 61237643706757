import React, { useState } from 'react'
import { SuccessNotify, ErrorNotify } from '../../shared/Toast';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


const FormCreation = () => {
    const base_url = process.env.REACT_APP_AUTH_BASE_URL;
    const navigate = useNavigate();
    const [file, setFile] = useState({});
    const [isLoadingBulkUpload, setIsLoadingBulkUpload] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyAdded, setAlreadyAdded] = useState([]);

    const handleCSVUpload = (e) => {
        e.preventDefault();
        setIsLoadingBulkUpload(true);
        const fileInput = document.getElementById('CSVUpload'); // Replace 'yourFileInputId' with the actual ID of your file input element
        const file = fileInput.files[0];
        
        
        if (file) {
            console.log("Object.keys(file)",Object.keys(file));
            if (!["csv"].includes(file['name'].split(".").pop())) {
                setIsLoadingBulkUpload(false);
                ErrorNotify("Allow only csv file")
                return;
            }
        
       
}

if (!file) {
    console.error("Invalid file object", file);
    setIsLoadingBulkUpload(false);
    return ErrorNotify("Select a file first")
    
}

        const url = `${base_url}forms/addstructure`;

       


        const formdata = new FormData();
        formdata.append("file", file);

        console.log("File object:", file);
        console.log("FormData after appending file:", formdata);

        const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("BulkUpload", data);

                if (data?.status === 200) {
                    SuccessNotify(data.message, { icon: "success" });
                    setIsLoadingBulkUpload(false);
                    setIsLoading(false)
                    document.getElementById('CSVUpload').value=null
                } else if (data?.status === 401) {
                    setIsLoading(false);
                    setIsLoadingBulkUpload(false);
                    ErrorNotify(data.message, { icon: "error" });
                    localStorage.clear();
                    navigate("/");
                } 
            })
            .catch(err => {
                console.error('Error:', err);
                setIsLoadingBulkUpload(false);
                ErrorNotify("Network Issue. Please Try Again!", {
                    icon: "error",
                    toastId: "bulk-upload"
                });
            });
    };
    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            
            <main>
            <ToastContainer />
                <div className="ip-header sc-head sc-collapse">
                    <div className="container-fluid">
                        <div className="sc-header d-flex justify-content-between align-items-center">
                            <h3 className="sc-title d-flex align-items-center"><span>Add Form</span></h3>
                        </div>
                    </div>
                </div>
                <nav className="ip-breadcrumb" aria-label="breadcrumb">
                    <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><a href="#">Forms</a></li>
                        <li className="breadcrumb-item active"><span>Add Form</span></li>
                    </ul>
                </nav>

                <div className="ip-section">
                    <div className="container-fluid">
                        <div className="ip-form p-0">
                            <div className="form-contents">
                                <form onSubmit={handleCSVUpload} encType='multipart/form-data'>
                                    <input type="hidden" name="csrf_test_name" value="" />
                                    <div className="form-row">
                                        <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                            <label className="form-label">Upload File</label>
                                            <input className="form-control" type="file" required="" id="CSVUpload" onChange={(e) => setFile(e.target.files[0])} />
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 form-group">
                                            <div className="text-right">
                                                <a type="submit"
                                                    id="save_btn"
                                                    className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                    onClick={handleCSVUpload}>Upload
                                                    {isLoadingBulkUpload && (
                                                        <div className="">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )}
                                                </a>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 form-group">
                                                        <h4>Instructions:</h4>
                                                        <ul>
                                                            <li>Compatible with Only csv file</li>
                                                            <li>Maximum size limit is 2 MB</li>
                                                            <li>Maximum 5000 rows accepted</li>
                                                            <li>File should contain 17 column</li>
                                                            <li>Employee ID should be Minimumn 8 digits</li>
                                                            <li>All comma separated value should be equal in a rows(LOB, Supervisor ID)</li>
                                                            <li>LOB should already exist in hierarchy</li>
                                                            <li>Supervisor must be active on current LOB</li>
                                                            <li>Date of joining format (YYYY-MM-DD)</li>
                                                            <li>Date of joining should be before current date</li>
                                                            <li>To view sample click on "Download Sample" on top right of this page</li>
                                                        </ul>
                                                    </div> */}
                                    </div>
                                    <div className="position-relative d-flex justify-content-end align-items-center">
                                        {/* <button
                                                        type="submit"
                                                        id="save_btn"
                                                        className={`btn btn-primary justify-content-end ml-auto mb-2`}
                                                        onClick={handleCSVUpload}
                                                    >
                                                        Submit
                                                    </button> */}
                                        {/* {isLoadingBulkUpload && (
                                                        <div className="position-absolute top-0 end-0 w-100 h-100 d-flex align-items-center justify-content-end mr-5">
                                                            <div className="spinner-border text-black" role="status">

                                                            </div>
                                                        </div>
                                                    )} */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default FormCreation