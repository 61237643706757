import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import BubbleChart from "@testboxlab/react-bubble-chart-d3";
import ReactFC from "react-fusioncharts";
import { Bar } from "react-chartjs-2";
// Include the fusioncharts library
import FusionCharts from "fusioncharts";
// Include the chart type
import Column2D from "fusioncharts/fusioncharts.charts";
// Include the theme as fusion
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Widgets from "fusioncharts/fusioncharts.widgets";
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme, Widgets);

const Dashboard5 = ({ detractorsHotWordsData, excalationsHotWordsData }) => {
  TabTitle("Dashboard :: NEQQO");
  console.log("detractorsHotWordsData", detractorsHotWordsData);
  console.log("excalationsHotWordsData", excalationsHotWordsData);

  const updatedData = excalationsHotWordsData?.filter(i=>i?.value!==0)?.map((item) => {
    console.log("item.value/100*10",item?.label,item.value/100*10);
   
    return {
      // ...item,
      label: `${item.label} (${item.value})`,
      value:item.value/100*10
     
    };
  
  });
  console.log("updatedData2updatedData2",updatedData);

  const updatedData2 = detractorsHotWordsData?.filter(i=>i?.value!==0)?.map((item) => {
    return {
      // ...item,
      label: `${item.label} (${item.value})`,
      value:item.value/100*10
     
    };
  });

  

  const getMaxLabelLength = (data) => {
    return data&&Math.max(...data?.map((item) => item.label.length));
  };

  const maxLabelLength = getMaxLabelLength(detractorsHotWordsData);

  // Add some extra padding based on your preferences
  const chartWidth = maxLabelLength * 10 + 100;
  console.log("updatedData", updatedData);
  const bubbleClick = (label) => {
    console.log("Custom bubble click func");
  };
  const legendClick = (label) => {
    console.log("Customer legend click func");
  };

  return (
    <>
      <div class="ip-section mt-5" style={{marginBottom:"25px"}}>
        <div class="container-fluid">
          <div class="form-row">
            <div
              className="col-md-6 col-lg-6 col-sm-6"
              style={{ backgroundColor: "white" }}
            >
              {updatedData?.length > 0 ? (
                <>
                  <h3
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#524F4F",
                      paddingTop: "20px",
                    }}
                  >
                    Escalations Hot Words
                  </h3>
                  <BubbleChart
                    graph={{
                      zoom: 0.8,
                      offsetX: -0.05,
                      offsetY: -0.01,
                    }}
                    width={550}
                    height={550}
                    padding={0} // optional value, number that set the padding between bubbles
                    showLegend={false} // optional value, pass false to disable the legend.
                    legendPercentage={20} // number that represent the % of with that legend going to use.
                    legendFont={{
                      family: "Arial",
                      size: 8,
                      color: "#000",
                      // weight: "bold",
                    }}
                    valueFont={{
                      family: "Arial",
                      size: 8,
                      color: "#fff",
                      // weight: "bold",
                    }}
                    labelFont={{
                      family: "Arial",
                      size: 11,
                      color: "#fff",
                      // weight: "bold",
                    }}
                    //Custom bubble/legend click functions such as searching using the label, redirecting to other page
                    bubbleClickFunc={bubbleClick}
                    legendClickFun={legendClick}
                    minValue={1}
                    maxValue={100}
                    data={updatedData}
                  />
                </>
              ) : 
              updatedData?.length===0 && excalationsHotWordsData?
              (
                <div className="row">
                  <div style={{ backgroundColor: "white", height: "440px" }}>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div style={{ marginTop: "160px", textAlign: "center" }}>
                        <h4>
                          <b>Escalations Hot Words Data Not Available</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ):
              (
                <div className="chart-loader">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              )
              }
            </div>
            <div
              className="col-md-6 col-lg-6 col-sm-6"
              style={{ backgroundColor: "white" }}
            >
              {updatedData2?.length > 0 ? (
                <>
                  <h3
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#524F4F",
                      paddingTop: "20px",
                    }}
                  >
                    Detractors Hot Words
                  </h3>
                  <BubbleChart
                    graph={{
                      zoom: 0.7,
                      offsetX: -0.05,
                      offsetY: -0.01,
                    }}
                    width={600}
                    height={600}
                    padding={0} // optional value, number that set the padding between bubbles
                    showLegend={false} // optional value, pass false to disable the legend.
                    legendPercentage={20} // number that represent the % of with that legend going to use.
                    valueFont={{
                      size: 8,
                      color: "#fff",
                      weight: "100",
                    }}
                    labelFont={{
                      family: "Arial",
                      size: 11,
                      color: "#fff",
                      weight: "bold",
                    }}
                    //Custom bubble/legend click functions such as searching using the label, redirecting to other page
                    bubbleClickFunc={bubbleClick}
                    legendClickFun={legendClick}
                    data={updatedData2}
                  />
                </>
              ) : 
              
              updatedData2?.length===0 && detractorsHotWordsData?
              (
                <div className="row">
                  <div style={{ backgroundColor: "white", height: "440px" }}>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div style={{ marginTop: "160px", textAlign: "center" }}>
                        <h4>
                          <b>Detractors Hot Words Data Not Available</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              ):
              (
                <div style={{ backgroundColor: "white", height: "440px" }}>
                <div className="chart-loader">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                </div>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard5;
