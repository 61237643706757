import React, { useEffect, useState } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import FusionCharts from "fusioncharts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import TreeMap from "fusioncharts/fusioncharts.treemap";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
import { ErrorNotify } from "../../../shared/Toast";

ReactFC.fcRoot(FusionCharts, PowerCharts, TreeMap, FusionTheme);

const Dashboard2 = ({
  callDriverTrendData,
  chart5status,
  chart6status,
  applicationCategory,
  categorizationData,
  fromDate1,
  toDate1,
  setAppDataForTable,
  setLoaderSubCat,
  filterPayload
}) => {
  console.log("callDriverTrendData122222", applicationCategory, categorizationData);
  TabTitle("Dashboard :: NEQQO");
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  // console.log("categorizationData", Object.keys(categorizationData)?.length);
  var Bar = {
    type: "treemap",
    width: "100%",
    height: "440",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption:
          applicationCategory?.label !== undefined
            ? applicationCategory?.label
            : "No Application Exist",
        // captionOnTop:applicationCategory?.label !== undefined ? 1 : 0,
        captionAlignment:
          applicationCategory?.label !== undefined ? "" : "center",
        animation: "1",
        plotToolText: "<div><b>$label</div>",
        horizontalPadding: "1",
        verticalPadding: "1",
        plotborderthickness: ".5",
        plotbordercolor: "b3b3b3",
        showParent: 0,
        chartBottomMargin: "0",
        labelGlow: "0",
        labelFontColor: "#fffff",
        labelFont: "Source Sans Pro",
        labelFontSize: "12",
        showchildlabels: "1",
        algorithm: "squarified",
        slicingmode: "vertical",
        theme: "fusion",
      },
      data: applicationCategory !== undefined ? [applicationCategory] : [],
      colorrange: {
        code: "0889d6",
        color: [
          {
            code: "0889d6",
            maxvalue:
              applicationCategory?.value !== undefined
                ? applicationCategory?.value
                : "100",
          },
          {
            code: "032c45",
            maxvalue:
              applicationCategory?.value !== undefined
                ? applicationCategory?.value / 2
                : "1",
          },
        ],
      },
    },
    events: {
      dataplotClick: async function (eventObj, dataObj) {
        setAppDataForTable && setAppDataForTable([]);
        setLoaderSubCat(true);
        window.scroll({
          top: 3282,
          behavior: "smooth",
        });
        // Handle the click event here
        console.log("WWWWWWWWWWWWW",window.location.href?.split("/")[3]);
        var clickedData = dataObj.categoryLabel;
        let url =""
        let payloadForTable={}
        if(window.location.href?.split("/")[window.location.href?.split("/")?.length-1]==="smartspeech-dashboard-new"){
           url = `${base_url}smartspeechtags/tag-file-list`;
        
        
         payloadForTable = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            tag: applicationCategory?.data?.filter(
              (i) => i?.label === dataObj?.name
            )[0]?.tag_id,
            category_id: applicationCategory?.data?.filter(
              (i) => i?.label === dataObj?.name
            )[0]?.cat_id,
            folder_id: applicationCategory?.folder_id,
            overall_from_date: fromDate1,
            overall_to_date: toDate1,
          }),
        };
      }
      else{
        setLoaderSubCat(true)
        url = `${base_url}smartspeech/application-category-file-list`;
        payloadForTable = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("loginToken"),
          },
          body: JSON.stringify({
            application: applicationCategory?.application_id,
              category_id: applicationCategory?.data?.filter(
                (i) => i?.label === dataObj?.name
              )[0]?.cat_id,
            folder_id: filterPayload?.folder_id && filterPayload?.folder_id?.length>0?filterPayload?.folder_id:applicationCategory?.folder_id,
              overall_from_date: filterPayload?.fromDateForApp && filterPayload?.fromDateForApp !== ""? filterPayload?.fromDateForApp:fromDate1,
              overall_to_date: filterPayload?.toDateForApp && filterPayload?.toDateForApp !== ""? filterPayload?.toDateForApp:toDate1,
              silent_time: filterPayload?.silent_time,
          start_silent_time:filterPayload?.start_silent_time,
          duration: filterPayload?.duration,
          start_duration: filterPayload?.start_duration,
          campaign: filterPayload?.campaign,
          gender: filterPayload?.gender,
          gender_type: filterPayload?.gender_type,
          emotion: filterPayload?.emotion,
          emotion_type: filterPayload?.emotion_type
          }),
        };
      }

        await fetch(url, payloadForTable)
          .then((res) => res.json())
          .then((data) => {
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                setAppDataForTable(data?.data);
                setLoaderSubCat(false);
                setTimeout(() => {
                  window.scroll({
                    top: 4582,
                    behavior: "smooth",
                  });
                }, 0);
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
            console.log("RSSSSSSSSSSS", data);
          })
          .catch((err) => {
            return ErrorNotify(err);
          });
        console.log(
          "Clicked Data: ",
          applicationCategory?.data?.filter((i) => i?.label === dataObj?.name)
        );
        // Add your logic to do something with the clicked data
      },
    },
  };

  var Bar3 = {
    type: "msline",
    width: "100%",
    height: "440",
    dataFormat: "json",
    dataSource: {
      chart: {
        caption: callDriverTrendData?.application_name
          ? callDriverTrendData?.application_name
          : "No Application Exist",
        labelDisplay: "AUTO",
        slantLabels:
          callDriverTrendData?.categories &&
          callDriverTrendData?.categories[0]?.category?.length > 10
            ? "1"
            : "0",
        rotateLabels:
          callDriverTrendData?.categories &&
          callDriverTrendData?.categories[0]?.category?.length > 10
            ? "1"
            : "0",
        numberSuffix: "%",
        theme: "fusion",
      },
      categories:
        callDriverTrendData?.categories?.length > 0
          ? callDriverTrendData?.categories
          : [{ category: { label: "No data Available" } }],
      dataset:
        callDriverTrendData?.dataset?.length > 0
          ? callDriverTrendData?.dataset
          : [],
      // "trendlines": [
      //     {
      //         "line": [
      //             {
      //                 "startvalue": "17022",
      //                 "color": "#6baa01",
      //                 "valueOnRight": "1",
      //                 "displayvalue": "Average"
      //             }
      //         ]
      //     }
      // ]
    },
  };
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const pathname = url.pathname; // contains "/register"

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // localStorage.clear()
    const loggedInUser = localStorage.getItem("loginToken");
    // console.log('dashboard loggedInUser===========', loggedInUser)
    if (loggedInUser) {
      // navigate("/");
      // console.log('=======', loggedInUser);
      setAuthenticated(loggedInUser);
    }
  }, []);
  if (categorizationData && Object.keys(categorizationData)?.length > 0) {
    var Bar2 = {
      type: "doughnut2d",
      renderAt: "chart-container",
      width: "100%",
      height: "440",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Call Categorization",
          // "subCaption": "Last year",
          // "numberPrefix": "$",
          numberSuffix: "%",
          // "defaultCenterLabel": "Total revenue: $64.08K",
          // "centerLabel": "Revenue from $label: $value",
          // "decimals": "0",
          valuePosition: "inside",
          enableSmartLabels: 0,
          showLabels: 0,
          showValues: 1,
          showPercentValues: 0,
          showZeroPies: 0,
          valueFontColor: "#ffffff",
          // labelFontColor: "#00FF00",

          theme: "fusion",
        },
        data: [
          {
            label: categorizationData?.tag_name || categorizationData?.application_name,
            value: categorizationData?.percentage,
            color: "#c53b38",
            tooltext:categorizationData?.matchedRecords&&`${categorizationData?.tag_name || categorizationData?.application_name},${categorizationData?.percentage} (Count: ${categorizationData?.matchedRecords})`
          },
          {
            label: "",
            value: 100 - categorizationData?.percentage,
            color: "#999999",
            showValue: "0",
            // tooltext:categorizationData?.matchedRecords&&`${100 - categorizationData?.percentage+"%"} (Count: ${categorizationData?.totalCalls})`
          },
        ],
        // data: categorizationData
      },
    };
  } else {
    Bar2 = {
      type: "doughnut2d",
      renderAt: "chart-container",
      width: "100%",
      height: "440",
      dataFormat: "json",
      dataSource: {
        chart: {
          caption: "Call Categorization",
          subCaption: "",
          // "numberPrefix": "$",
          defaultCenterLabel: "No Data Available",
          // "centerLabel": "Revenue from $label: $value",
          decimals: "0",
          valuePosition: "inside",
          enableSmartLabels: 0,
          showLabels: 1,
          showValues: 0,
          // This attribute will display the chart even for zero values
          theme: "fusion",
        },
        data: [
          {
            // label:"No Data Available",
            value: 1, // Change to 0 if you want to display zero value
            color: "#777b7e",
          },
        ],
      },
    };
  }

  return (
    <>
      <div class="ip-section mt-5">
        <div class="container-fluid">
          <div class="form-row">
            <div className="col-md-6 col-sm-6 col-xs-6">
              {console.log("OIIOIOIOIO", categorizationData)}
              {Object.keys(categorizationData)?.length > 0 || categorizationData?.length>0 ? (
                <ReactFC {...Bar2} />
              ) : (
                <div style={{ backgroundColor: "white", height: "440px" }}>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6"></div>

                    <div className="col-md-1 col-lg-1 col-sm-1">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ marginTop: "140px" }}
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>

                    <div className="col-md-5 col-lg-5 col-sm-5"></div>
                  </div>
                </div>
              )}
            </div>

            <div
              className="col-md-6 col-sm-6 col-xs-6"
              style={{ background: "#fff" }}
            >
              <div className="chart-main">
                {chart5status && (
                  <div className="chart-loader">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <ReactFC {...Bar} />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 mt-5">
              <div className="chart-main">
                {chart6status && (
                  <div className="chart-loader">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                <ReactFC {...Bar3} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard2;
