import React, { useState, useEffect } from "react";
import Select from "react-select";
// import { ErrorNotify } from "../../../shared/Toast";
// import MultiSelection from "../../../controls/MultiSelect";
import moment from 'moment';
import MultiSelection from "../controls/MultiSelect";
import { DismissToast, ErrorNotify, SuccessNotify } from "../shared/Toast";
import { useNavigate } from 'react-router-dom';


const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var options = [];
var globalManager2Value = '';
var globalManager1Value = '';
var globalAgentValue = '';
let defaultDataMain;
const KPIDashboardFilter = (props) => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const navigate = useNavigate();
    const [dateRangeValue, setdateRangeValue] = useState({ value: "currentMonth", label: 'Month to Date' });
    const [selectedCampData, setSelectedCampData] = useState();
    const [timeFormat, setTimeFormat] = useState({ value: "monthly", label: 'Month Wise' });
    const [fromList,setFromList] = useState(pathname?.includes('fromList') ? true : false)
    const [dateRangeTypeValue, setdateRangeTypeValue] = useState('Thismonth');
    const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [dateType, setDateType] = useState({ value: 'call_date', label: 'Call Date' });
    const [defaultData,setDefaultData] = useState({})
    const [isFilter, setIsFilter] = useState(false);

    const [c1Data, setC1Data] = useState([]);
    const [isC1DataLoaded, setIsC1DataLoaded] = useState(false);

    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);

    const [defaultValueC1, setdefaultValueC1] = useState([]);
    const [defaultValueC2, setdefaultValueC2] = useState([]);
    const [defaultValueC3, setdefaultValueC3] = useState([]);
    const [defaultValueC4, setdefaultValueC4] = useState([]);

    const [hierarchyData, setHierarchyData] = useState([]);

    const [formData, setFormData] = useState([]);

    const [manager2Val, setManager2Val] = useState([]);
    const [manager1Val, setManager1Val] = useState([]);
    const [supervisorVal, setSupervisorVal] = useState('');
    const [agentVal, setAgentVal] = useState('');
    const [isDataFound, setIsDataFound] = useState(false);
    const [manager2, setManager2] = useState([]);
    const [manager1, setManager1] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [agent, setAgent] = useState([]);
    const [agentId, setAgentId] = useState([]);
    const [supervisorId, setSupervisorId] = useState([]);



    useEffect(() => {
        getCustomHierarchyData();
        if (typeof props?.filterData === 'string' && !fromList) {
            defaultDataMain = JSON.parse(props?.filterData)
            setFromDate(defaultDataMain?.startDate ? defaultDataMain?.startDate : fromDate)
            setToDate(defaultDataMain?.endDate ? defaultDataMain?.endDate : toDate)
            setdateRangeValue(defaultDataMain?.dateRangeValue)
            setTimeFormat(defaultDataMain?.timeFormat)
            setDefaultData(JSON.parse(props?.filterData))
            setDateType(defaultDataMain?.callType ? defaultDataMain?.callType : dateType)
        }
        else{
            let payload = {
                "quick_search": ((props.call_id !== undefined || props.agent_id !== undefined) ? true : false),
                // start_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : fromDate),
                // end_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : toDate),
                "start_date": fromDate,
                "end_date": toDate,
                "date_type": (props.call_id !== undefined || props.agent_id !== undefined ? '' : dateType.value),
                "daterange": dateRangeValue?.value,
                "affiliation": '',
                "custom1": defaultValueC1,
                "custom2": defaultValueC2,
                "custom3": defaultValueC3,
                "custom4": defaultValueC4,
                "manager2": manager2Val,
                "eval_status": '',
                "manager1": manager1Val,
                "supervisor": supervisorId,
                "agent": agentId,
                "call_id": props.call_id !== '' ? props.call_id : '',
                "agent_id": props.agent_id !== '' ? props.agent_id : '',
                "time_format": timeFormat?.value
            }
            if (props?.setFilterPayload) {
                props?.setFilterPayload(payload)
            }
        }
    }, []);

    console.log("defaultDataMain",defaultDataMain)
    

    const getCustomHierarchyData = async () => {
        var url = `${base_url}hierarchy/get-custom-hierarchy`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                // Logout();
                return
            }
            var arr = [];
            data.data.map((i, j) => {
                arr[i.custom1_id] = i.custom1_name;
                arr[i.custom2_id] = i.custom2_name;
                arr[i.custom3_id] = i.custom3_name;
                arr[i.custom4_id] = i.custom4_name;
            })
            // arr['c2'] = 'Vendor';
            // arr['c3'] = 'LOB';
            // arr['c1'] = 'Campaign';
            // arr['c4'] = 'Location';
            console.log('arr -===========', arr)
            setHierarchyData(arr);

            var arrHierarchyData = [];

            data.data.map((i, j) => {
                arrHierarchyData[j + 0] = { name: i.custom1_name, value: "custom1" };
                arrHierarchyData[j + 1] = { name: i.custom2_name, value: "custom2" };
                arrHierarchyData[j + 2] = { name: i.custom3_name, value: "custom3" };
                arrHierarchyData[j + 3] = { name: i.custom4_name, value: "custom4" };
            })
            props?.setHierarchyData(arrHierarchyData)

        }).catch(err => {
            console.log(err);
        });

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        var url = `${base_url}custom/1`;
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            setC1Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleDateRangeTypeChange = (data) => {
        setdateRangeTypeValue(data);
        setFromToDatesFunc(data.value);
    }

    const setFromToDatesFunc = (val) => {

        // week wise date range starts here
        if (val === 'Thisweek') {
            setFromDate(moment().clone().startOf('week').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }
        if (val === '1_Weeks') {
            setFromDate(moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        }

        if (val === '2_Weeks') {
            setFromDate(moment().subtract(2, 'weeks').startOf('week').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        }

        if (val === '3_Weeks') {
            setFromDate(moment().subtract(3, 'weeks').startOf('week').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));
        }

        if (val === 'weekToDate') {
            setFromDate(moment().clone().startOf('week').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }
        // week wise date range ends here

        // month wise date range starts here
        if (val === 'Thismonth') {
            setFromDate(moment().clone().startOf('month').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }
        if (val === '1_Months') {
            setFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }

        if (val === '2_Months') {
            setFromDate(moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }

        if (val === '3_Months') {
            setFromDate(moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        // month wise date range ends here

        // day wise date range starts here
        if (val === 'Today') {
            setFromDate(moment().format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }
        if (val === 'OneDay') {
            setFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }
        if (val === 'TwoDays') {
            setFromDate(moment().subtract(2, 'days').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }
        // day wise date range ends here

        if (val === 'YearDate') {
            setFromDate(moment().clone().startOf('year').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }

        if (val === 'PreviousMonth') {
            setFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
            setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (val === 'CurrentMonth') {
            setFromDate(moment().clone().startOf('month').format('YYYY-MM-DD'));
            setToDate(moment().format('YYYY-MM-DD'));
        }

    }

    const handleDateRangeChange = (e) => {
        setdateRangeValue(e);
        props?.setFilterData((prevArr) => ({ ...prevArr, 'dateRangeValue': e }));
        console.log("valvalvalval", e);
        const value = e.value;
        let startDate, endDate;
        const today = moment().startOf("day").format("YYYY-MM-DD");
        if (e.value === "today") {
            startDate = today;
            endDate = moment(today).endOf("day").format("YYYY-MM-DD");
           

            setFromDate(startDate);
            setToDate(endDate)
        }
        if (e.value === "weekToDate") {
            startDate = moment(today).startOf("week").format("YYYY-MM-DD");
            endDate = today;
            // setTimeFormat('weekly')

            setFromDate(startDate);
            setToDate(endDate)
            props?.setFromDate(startDate);
            props?.setToDate(endDate)
        }

        if (e.value === "currentMonth") {
            startDate = moment(today).startOf("month").format("YYYY-MM-DD");
            endDate = today;
            // setTimeFormat('monthly')

            setFromDate(startDate);
            setToDate(endDate)
            props?.setFromDate(startDate);
            props?.setToDate(endDate)
        }
        if (e.value === "quarterToDate") {
            // const today = new Date();
            // const currentQuarter = Math.ceil(today.month() / 3);
            // const currentYear = today.year();
            startDate = moment().startOf('quarter').format("YYYY-MM-DD")
            // startDate = moment(`${currentYear}-${(currentQuarter - 1) * 3 + 1}-01`).format("YYYY-MM-DD");
            endDate = moment().format('YYYY-MM-DD');
            // setTimeFormat('quarterly')

            setFromDate(startDate);
            setToDate(endDate)
            props?.setFromDate(startDate);
            props?.setToDate(endDate)
        }
        if (e.value === "yearDate") {
            const today = moment();
            const currentYear = today.year();
            startDate = moment(`${currentYear}-01-01`).format("YYYY-MM-DD");
            endDate = today.format("YYYY-MM-DD");
            // setTimeFormat('yearly')

            setFromDate(startDate);
            setToDate(endDate)
            props?.setFromDate(startDate);
            props?.setToDate(endDate)
        }

        if (e.value === "yesterday") {
            startDate = moment(today).subtract(1, "day").format("YYYY-MM-DD");
            endDate = today;
            // setTimeFormat('date_wise')

            setFromDate(startDate);
            setToDate(endDate)
            props?.setFromDate(startDate);
            props?.setToDate(endDate)
        }

        if (e.value === "lastMonth") {
            startDate = moment(today).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
            endDate = moment(startDate).endOf("month").format("YYYY-MM-DD");
            // setTimeFormat('monthly')

            setFromDate(startDate);
            setToDate(endDate)
            props?.setFromDate(startDate);
            props?.setToDate(endDate)
        }
        if (e.value === "custom") {
            // setTimeFormat('monthly')
            setFromDate(fromDate)
            setToDate(toDate)
            props?.setFromDate(fromDate);
            props?.setToDate(toDate)
        }

    }

    const handleChartDataChange = (e) => {
        setTimeFormat(e)
        props?.setFilterData((prevArr) => ({ ...prevArr, 'timeFormat': e }));
    }

    
    // const selectedLobDataFunc = (val) => {
    //     console.log("lobbVALUE", val)
    //     setSelectedLobData(val);
    //     console.log("lobbVALUE2", selectedLobData)
    // }

    const selectedCampDataFunc = (val) => {
        console.log("lobbCamppain", val)
        setSelectedCampData(val);
        console.log("lobbcampai2", selectedCampData)
    }

    // const selectedVendorDataFunc = (val) => {
    //     console.log("lobbVender", val)
    //     setSelectedVendorData(val);
    //     console.log("lobbVendor2", selectedVendorData)
    // }

    // const selectedLocDataFunc = (val) => {
    //     console.log("lobbLOC", val)
    //     setSelectedLocData(val);
    //     console.log("lobbLOC2", selectedLocData)
    // }



    // const setFromToDatesFunc = (val) => {

    //     if (val === 'YearDate') {
    //         setFromDate(moment().clone().startOf('year').format('YYYY-MM-DD'));
    //         setToDate(moment().format('YYYY-MM-DD'));
    //     }
    // if (val === 'weekToDate') {
    //     setFromDate(moment().clone().startOf('week').format('YYYY-MM-DD'));
    //     setToDate(moment().format('YYYY-MM-DD'));
    // }
    //     if (val === 'Today') {
    //         setFromDate(moment().format('YYYY-MM-DD'));
    //         setToDate(moment().format('YYYY-MM-DD'));
    //     }
    //     if (val === 'OneDay') {
    //         setFromDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
    //         setToDate(moment().format('YYYY-MM-DD'));
    //     }
    //     if (val === 'PreviousMonth') {
    //         setFromDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
    //         setToDate(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
    //     }
    //     if (val === 'CurrentMonth') {
    //         setFromDate(moment().clone().startOf('month').format('YYYY-MM-DD'));
    //         setToDate(moment().format('YYYY-MM-DD'));
    //     }
    // }

    // const handleFilter = () => {
    //     setIsFilter(true);
    // }

    const handleDateType = (data) => {
        setDateType(data)
        props?.setDateType(data)
        props?.setFilterData((prevArr) => ({ ...prevArr, 'callType': data }));

    }

    const handleC2Change = (e) => {
        console.log("handleC2Change c2")
        console.log('props are here-----------', props)
        props?.setFilterData((prevArr) => ({ ...prevArr, 'custom1': e }))
        var url = `${base_url}filter/custom2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: fromDate,
                to: toDate,
                datetype: dateType?.value,
                daterange: dateRangeValue?.value,
                custom1: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c2 data======', data);
            if (data.status != 200) {
               
                return;
            }
            setdefaultValueC1(e);
            // props.getC1Value(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {
        console.log("handleC3Change c3")
        props?.setFilterData((prevArr) => ({ ...prevArr, 'custom2': e }))

        var url = `${base_url}filter/custom3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: fromDate,
                to: toDate,
                datetype: dateType?.value,
                daterange: dateRangeValue?.value,
                custom1: defaultDataMain !== undefined ? defaultDataMain?.custom1 : defaultValueC1,
                custom2: e
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
              
                return;
            }
            setdefaultValueC2(e);
            // props.getC2Value(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e) => {
        console.log("handleC4Change c4")
        props?.setFilterData((prevArr) => ({ ...prevArr, 'custom3': e }))

        var url = `${base_url}filter/custom4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: fromDate,
                to: toDate,
                datetype: dateType?.value,
                daterange: dateRangeValue?.value,
                custom1: defaultDataMain !== undefined  ? defaultDataMain?.custom1 :defaultValueC1,
                custom2: defaultDataMain !== undefined  ? defaultDataMain?.custom2 :defaultValueC2,
                custom3: e,
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                
                return;
            }
            setdefaultValueC3(e);
            // props.getC3Value(e);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC5Change = (e) => {

        setdefaultValueC4(e);
        console.log("handleC5Change c5")
        props?.setFilterData((prevArr) => ({ ...prevArr, 'custom4': e }))

        var url = `${base_url}filter/forms`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: fromDate,
                to: toDate,
                datetype: dateType?.value,
                daterange: dateRangeValue?.value,
                custom1: defaultDataMain !== undefined ? defaultDataMain?.custom1 :defaultValueC1,
                custom2: defaultDataMain !== undefined  ? defaultDataMain?.custom2 :defaultValueC2,
                custom3: defaultDataMain !== undefined ? defaultDataMain?.custom3 :defaultValueC3,
                custom4: e,
            })
        }, 'formData').then(res => res.json()).then(data => {
            setFormData(data.data);
        })

        var url = `${base_url}filter/manager2`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                from: fromDate,
                to: toDate,
                datetype: dateType?.value,
                daterange: dateRangeValue?.value,
                custom1: defaultDataMain !== undefined  ? defaultDataMain?.custom1 :defaultValueC1,
                custom2: defaultDataMain !== undefined  ? defaultDataMain?.custom2 :defaultValueC2,
                custom3: defaultDataMain !== undefined  ? defaultDataMain?.custom3 :defaultValueC3,
                custom4: e,
            })
        }, 'manager2').then(res => res.json()).then(data => {
            setManager2(data.data);
        });

        // var url = `${base_url}filter/manager1`;
        // callApi(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         from: props.fromDate,
        //         to: props.toDate,
        //         custom1: JSON.stringify([defaultValueC1]),
        //         custom2: JSON.stringify([defaultValueC2]),
        //         custom3: JSON.stringify([defaultValueC3]),
        //         custom4: JSON.stringify([defaultValueC4]),
        //         manager2: JSON.stringify(props.manager2)
        //     })
        // }, 'manager1');

        // var url = `${base_url}filter/supervisor`;
        // callApi(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         from: props.fromDate,
        //         to: props.toDate,
        //         custom1: JSON.stringify([defaultValueC1]),
        //         custom2: JSON.stringify([defaultValueC2]),
        //         custom3: JSON.stringify([defaultValueC3]),
        //         custom4: JSON.stringify([e]),
        //         manager1: JSON.stringify([props.manager1])
        //     })
        // }, 'supervisor');

        // var url = `${base_url}filter/agent`;
        // callApi(url, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
        //     },
        //     body: JSON.stringify({
        //         from: props.fromDate,
        //         to: props.toDate,
        //         supervisor: JSON.stringify([props.supervisor])
        //     })
        // }, 'agent');

    }

    const handleManager2Changed = (val) => {
        console.log('manager2 val------', val)
        props?.setFilterData((prevArr) => ({ ...prevArr, 'manager2': val }))

        globalManager2Value = val;
        setManager2Val(val);
        // props.getManager2(val);
        getAllValueFunc('manager1');
    }

    const handleManager1Changed = (val) => {
        props?.setFilterData((prevArr) => ({ ...prevArr, 'manager1': val }))
        globalManager1Value = val;
        setManager1Val(val);
        // props.getManager1(val);
        getAllValueFunc('supervisor');
    }

    const handleSupChanged = (val) => {
        props?.setFilterData((prevArr) => ({ ...prevArr, 'supervisor': val }))

        globalAgentValue = val;
        setSupervisorVal(val);
        setSupervisorId(val)
        getAllValueFunc('agent');
    }

    const handleAgentChanged = (val) => {
        // setAgent(val)
        setAgentId(val)
        props?.setFilterData((prevArr) => ({ ...prevArr, 'agent': val }))

    }

    const getAllValueFunc = (val) => {
        switch (val) {
            case 'manager1':
                // console.log('manager2Val---------', manager2Val)
                var url = `${base_url}filter/manager1`;
                callApi(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        from: fromDate,
                        to: toDate,
                        dateType: dateType.value,
                        daterange: dateRangeValue?.value,
                        custom1: defaultValueC1,
                        custom2: defaultValueC2,
                        custom3: defaultValueC3,
                        custom4: defaultValueC4,
                        manager2: globalManager2Value
                    })
                }, 'manager1');
                break;

            case 'supervisor':
                var url = `${base_url}filter/supervisor`;
                console.log("defaultDataMaindefaultDataMain",defaultDataMain);
                callApi(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        from: fromDate,
                        to: toDate,
                        datetype: dateType.value,
                        daterange: dateRangeValue?.value,
                        custom1: defaultDataMain&&defaultDataMain !== null ? defaultDataMain?.custom1 : defaultValueC1,
                        custom2: defaultDataMain&&defaultDataMain !== null ? defaultDataMain?.custom2 :defaultValueC2,
                        custom3: defaultDataMain&&defaultDataMain !== null ? defaultDataMain?.custom3 :defaultValueC3,
                        custom4: defaultDataMain&&defaultDataMain !== null ? defaultDataMain?.custom4 :defaultValueC4,
                        manager1: globalManager1Value
                    })
                }, 'supervisor');

                break;

            case 'agent':
                var url = `${base_url}filter/agent`;
                callApi(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                    },
                    body: JSON.stringify({
                        from: fromDate,
                        to: toDate,
                        datetype: dateType.value,
                        daterange: dateRangeValue?.value,
                        supervisor: globalAgentValue
                    })
                }, 'agent');

                break;

            default:
                break;
        }


    }

    const callApi = (url, requestOptions, purpose) => {

        fetch(url, requestOptions).then(res => res.json()).then(data => {

            if (data.status != 200) {
               
                return;
            }

            if (purpose === 'manager2') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('manager2 value----------------', data)

                    // props.getC1Value(defaultValueC1);
                    // props.getC2Value(defaultValueC2);
                    // props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    // props.getManager2(data.data);
                    setManager2(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'manager1') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {

                    // props.getC1Value(defaultValueC1);
                    // props.getC2Value(defaultValueC2);
                    // props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    // props.getManager1(data.data);
                    setManager1(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'supervisor') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('supervisor--------', data)

                    // props.getC1Value(defaultValueC1);
                    // props.getC2Value(defaultValueC2);
                    // props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    // props.getSupervisor(data.data);
                    setSupervisor(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
            if (purpose === 'agent') {
                fetch(url, requestOptions).then(res => res.json()).then(data => {
                    console.log('agent value-------', data)
                    // props.getC1Value(defaultValueC1);
                    // props.getC2Value(defaultValueC2);
                    // props.getC3Value(defaultValueC3);
                    // props.getC4Value(defaultValueC4);
                    // props.getAgent(data.data);
                    setAgent(data.data);

                }).catch(err => {
                    console.log(err);
                });
            }
        }).catch(err => {
            console.log(err);
        });

    }

    // const handleFilterFunc = () => {
    //     props.handleFilter(fromDate, toDate, dateRangeValue?, dateRangeTypeValue);
    // }

    const handleFilterFunc = (e) => {
        console.log("handleFilterFunc", props?.uri )

        props?.setIsDataFound && props?.setIsDataFound(true)
        let payload = {
            quick_search: ((props.call_id !== undefined || props.agent_id !== undefined) ? true : false),
            // start_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : fromDate),
            // end_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : toDate),
            start_date: fromDate,
            end_date: toDate,
            date_type: (props.call_id !== undefined || props.agent_id !== undefined ? '' : dateType.value),
            daterange: dateRangeValue?.value,
            affiliation: '',
            custom1: defaultValueC1,
            custom2: defaultValueC2,
            custom3: defaultValueC3,
            custom4: defaultValueC4,
            manager2: manager2Val,
            manager1: manager1Val,
            supervisor: supervisorId,
            agent: agentId,
            eval_status: props?.uri == "kpi-dashboard/my-eval-summary" ? "new" :'',
            call_id: props.call_id !== '' ? props.call_id : '',
            agent_id: props.agent_id !== '' ? props.agent_id : '',
            time_format: timeFormat?.value,
            proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
            proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
        }
        var url = `${base_url}${props?.uri}`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify(
                payload
            )
        }
        if (props?.setFilterPayload) {
            props?.setFilterPayload(payload)
        }
        fetch(url, requestOptions).then(res => res.json()).then(data => {

            console.log('filter data======', data);


            if (data?.status == 200 || data?.status == 201) {
                props?.setListingDataFunc && props?.setListingDataFunc(data);
                props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                props?.setIsDataFound && props?.setIsDataFound(false)
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                props?.setIsDataFound && props?.setIsDataFound(false)
                return;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            setIsDataFound(false);
            props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
            props?.setIsDataFound && props?.setIsDataFound(false)
            console.log(err);
        });

        let cartDataUrl = `${base_url}kpi-dashboard/card-data`
        KPIdashBoardFilterdData(cartDataUrl, requestOptions, 'card-data');
        getCustomHierarchyData();
    }
    const handleKPIFilterFunc = (e) => {
        console.log("Dashborad filter", fromDate, toDate);

        let payload = {
            "quick_search": ((props.call_id !== undefined || props.agent_id !== undefined) ? true : false),
            // start_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : fromDate),
            // end_date: (props.call_id !== undefined || props.agent_id !== undefined ? '' : toDate),
            "start_date": fromDate,
            "end_date": toDate,
            "date_type": (props.call_id !== undefined || props.agent_id !== undefined ? '' : dateType.value),
            "daterange": dateRangeValue?.value,
            "affiliation": '',
            "custom1": defaultValueC1,
            "custom2": defaultValueC2,
            "custom3": defaultValueC3,
            "custom4": defaultValueC4,
            "manager2": manager2Val,
            "eval_status": '',
            "manager1": manager1Val,
            "supervisor": supervisorId,
            "agent": agentId,
            "call_id": props.call_id !== '' ? props.call_id : '',
            "agent_id": props.agent_id !== '' ? props.agent_id : '',
            "time_format": timeFormat?.value,
            proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
            proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
            
        }
        props?.setIsDataFound && props?.setIsDataFound(true)
        let cartDataUrl = `${base_url}kpi-dashboard/card-data`
        let evStatusUrl = `${base_url}kpi-dashboard/evaluation-status`
        let evPerformanceUrl = `${base_url}kpi-dashboard/evaluation-performance`
        let campaignWiseUrl = `${base_url}kpi-dashboard/custom1-wise-data`
        let vendorWiseUrl = `${base_url}kpi-dashboard/custom3-wise-data`
        let locationWiseUrl = `${base_url}kpi-dashboard/custom4-wise-data`
        let lobWiseUrl = `${base_url}kpi-dashboard/custom2-wise-data`
        let auditWiseUrl = `${base_url}kpi-dashboard/audit-performance`
        let disputePerformanceUrl = `${base_url}kpi-dashboard/dispute-performance`
        console.log("formData111", formData);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify(
                payload
            )
        };
        console.log("requestOptions dash", requestOptions);
        if (props?.setFilterPayload) {
            props?.setFilterPayload(payload)
            getCustomHierarchyData();
        }

        KPIdashBoardFilterdData(cartDataUrl, requestOptions, 'card-data');
        KPIdashBoardFilterdData(evStatusUrl, requestOptions, 'evaluation-status');
        KPIdashBoardFilterdData(evPerformanceUrl, requestOptions, 'evaluation-performance');
        KPIdashBoardFilterdData(campaignWiseUrl, requestOptions, 'campaign-wise-data');
        KPIdashBoardFilterdData(vendorWiseUrl, requestOptions, 'vendor-wise-data');
        KPIdashBoardFilterdData(locationWiseUrl, requestOptions, 'location-wise-data');
        KPIdashBoardFilterdData(lobWiseUrl, requestOptions, 'lob-wise-data');
        KPIdashBoardFilterdData(auditWiseUrl, requestOptions, 'audit-performace');
        KPIdashBoardFilterdData(disputePerformanceUrl, requestOptions, 'dispute-performance');
    }

    const KPIdashBoardFilterdData = (url, requestOptions, purpose) => {
        fetch(url, requestOptions).then(res => res?.json())?.then(data => {
            if (purpose === "card-data") {

                if (data?.status == 200 || data?.status == 201) {
                    props?.setKpiDashboardData && props?.setKpiDashboardData(data?.card_data)
                    props?.getCardData && props?.getCardData(data?.card_data);
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)
                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    // setIsLoading(false)
                    return;
                }
            }
            if (purpose === "evaluation-status") {

                if (data?.status == 200 || data?.status == 201) {
                    props?.setEvaluationStatus && props?.setEvaluationStatus(data?.evaluation_status)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)
                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)

                    return;
                }
            }
            if (purpose === "evaluation-performance") {
                if (data?.status == 200 || data?.status == 201) {
                    props?.setEvaluationPerformance && props?.setEvaluationPerformance(data?.evaluation_performance)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)
                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    // setIsLoading(false)
                    return;
                }
            }

            if (purpose === "campaign-wise-data") {
                if (data?.status == 200 || data?.status == 201) {
                    props?.setCampaignScore && props?.setCampaignScore(data?.custom1_score_data)
                    props?.setCampaignCount && props?.setCampaignCount(data?.custom1_count_data)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)

                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)

                    // setIsLoading(false)
                    return;
                }
            }
            if (purpose === "vendor-wise-data") {

                if (data?.status == 200 || data?.status == 201) {
                    props?.setVendorCount && props?.setVendorCount(data?.custom3_count_data)
                    props?.setVendorScore && props?.setVendorScore(data?.custom3_score_data)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    props?.setIsDataFound && props?.setIsDataFound(false)

                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    // setIsLoading(false)
                    return;
                }

            }
            if (purpose === "location-wise-data") {
                if (data?.status == 200 || data?.status == 201) {
                    props?.setLocationCount && props?.setLocationCount(data?.custom4_count_data)
                    props?.setLocationScore && props?.setLocationScore(data?.custom4_score_data)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    // setIsLoading(false)
                    return;
                }
            }
            if (purpose === "lob-wise-data") {

                if (data?.status == 200 || data?.status == 201) {
                    props?.setLOBCount && props?.setLOBCount(data?.custom2_count_data)
                    props?.setLOBScore && props?.setLOBScore(data?.custom2_score_data)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)
                        // setIsLoading(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    // setIsLoading(false)
                    return;
                }
            }
            if (purpose === "audit-performace") {
                if (data?.status == 200 || data?.status == 201) {
                    props?.setAuditPerformance && props?.setAuditPerformance(data?.audit_performance)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    // setIsLoading(false)
                    return;
                }
            }
            if (purpose === "dispute-performance") {

                if (data?.status == 200 || data?.status == 201) {
                    props?.setDisputePerformance && props?.setDisputePerformance(data?.dispute_performance)
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
                    return;
                }
                else if (data?.status >= 400 || data?.status <= 422) {
                    if (data?.status == 401) {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        localStorage.clear()
                        navigate("/");
                        // setIsLoading(false)
                        return;
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        props?.setIsDataFound && props?.setIsDataFound(false)
                        return;
                    }
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    props?.setIsDataFound && props?.setIsDataFound(false)
                    return;
                }
            }
            props?.setIsDataFound(false)
            props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            props?.setIsDataFound(false)
            props?.setIsDrawerOpen && props?.setIsDrawerOpen(false)
            return;
            // console.log('========================', data.data);
        });
    }


    return (
        // <>

        // </>



        <div id="filter_drawer" className={`san-drawer right ${props?.isDrawerOpen ? 'open' : ''}`} data-reference="filter_drawer">
            <form action="#" className="form formview filter_form" method="post" accept-charset="utf-8">
                <input type="hidden" name="csrf_test_name" value="" />
                <div className="drawer-card">
                    <div className="drawer-header d-flex align-items-center justify-content-between">
                        <h4>Filter Settings</h4>
                        <button type="button" className="drawer-close"></button>
                    </div>

                    <div className="drawer-body">

                        <div className="form-group">
                            <label><span>Date Range</span><span className="mandatory">*</span></label>
                            <Select
                                options={[
                                    { value: "today", label: 'Today' },
                                    { value: "weekToDate", label: 'Week To Date' },
                                    { value: "currentMonth", label: 'Month to Date' },
                                    { value: "quarterToDate", label: 'Quarter To Date' },
                                    { value: "yearDate", label: 'Year To Date' },
                                    { value: "yesterday", label: 'Yesterday' },
                                    { value: "lastMonth", label: 'Last Month' },
                                    { value: "custom", label: 'Custom Date Range' },
                                ]}
                                placeholder="Select Date Range"
                                isSearchable={true}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px',
                                        boxShadow: 'none', // Remove the blue box shadow
                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                        '&:hover': {
                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                        },
                                        '&:focus': {
                                            outline: 'none', // Remove the blue outline on focus
                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                        },
                                        '&:active': {
                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                        },
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                    }),

                                }}
                                value={dateRangeValue}
                                onChange={handleDateRangeChange}
                            />

                        </div>

                        <div className="form-group">
                            <label><span>Chart Data</span><span className="mandatory">*</span></label>
                            <Select
                                options={[
                                    { value: "monthly", label: 'Month Wise' },
                                    { value: "weekly", label: 'Week Wise' },
                                    { value: "dayWise", label: 'Day Wise' },
                                ]}
                                placeholder="Select Chart Data"
                                isSearchable={true}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px',
                                        boxShadow: 'none', // Remove the blue box shadow
                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                        '&:hover': {
                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                        },
                                        '&:focus': {
                                            outline: 'none', // Remove the blue outline on focus
                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                        },
                                        '&:active': {
                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                        },
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                    }),

                                }}
                                value={timeFormat}
                                onChange={handleChartDataChange}
                            />

                        </div>
                        <div className="form-group filter-item">
                            <label><span>Date Type</span><span className="mandatory">*</span></label>
                            <Select
                                options={[

                                    { value: 'call_date', label: 'Call Date' },
                                    { value: 'evaluation_date', label: 'Evaluation Time' },
                                ]}
                                placeholder="Select Date Type"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px',
                                        boxShadow: 'none', // Remove the blue box shadow
                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                        '&:hover': {
                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                        },
                                        '&:focus': {
                                            outline: 'none', // Remove the blue outline on focus
                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                        },
                                        '&:active': {
                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                        },
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                    }),

                                }}
                                isSearchable={true}
                                value={dateType}
                                onChange={handleDateType}
                            />

                        </div>


                        {/* <div className="form-group">
                            <label><span>Date Range</span><span className="mandatory">*</span></label>
                            <Select
                                options={[
                                    { value: "Daily", label: 'Custom' },
                                    { value: "DayWise", label: 'Day Wise' },
                                    { value: "Weekly", label: 'Weekly' },
                                    { value: "Monthly", label: 'Monthly' },
                                ]}
                                placeholder="Select Date Range"
                                isSearchable={true}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px', // Adjust the border-radius value as per your needs
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                    
                                }}
                                value={dateRangeValue}
                                onChange={handleDateRangeChange}
                            />

                        </div> */}
                        {/* 
                        <div className="form-group filter_other d-none">
                            <label><span>Select Week</span></label>
                            <select className="form-control">

                            </select>
                        </div> */}
                        <div className={`form-group filter_dates ${dateRangeValue?.value === 'custom' ? '' : 'd-none'}`}>
                            <label><span>Start Date</span></label>
                            <input type="date" className="form-control" placeholder="From Date" required onChange={(e) => [setFromDate(e.target.value), props?.setFromDate(e.target.value), props?.setFilterData((prevArr) => ({ ...prevArr, 'startDate': e.target.value }))]} value={fromDate} />
                        </div>

                        <div className={`form-group filter_dates ${dateRangeValue?.value === 'custom' ? '' : 'd-none'}`}>
                            <label><span>End Date</span></label>
                            <input type="date" className="form-control" placeholder="To Date" required onChange={(e) => [setToDate(e.target.value), props?.setToDate(e.target.value), props?.setFilterData((prevArr) => ({ ...prevArr, 'endDate': e.target.value }))]} value={toDate} />
                        </div>

                        {/* <div className={`form-group filter_dates ${(dateRangeValue.value === 'DayWise' || dateRangeValue.value === 'Weekly' || dateRangeValue.value === 'Monthly') ? '' : 'd-none'}`}>
                            <label><span>{dateRangeValue.value === 'DayWise' ? 'Day' : dateRangeValue.value}</span><span className="mandatory">*</span></label>
                            <Select
                                options={options}
                                placeholder={`Select ${dateRangeValue.value === 'DayWise' ? 'Day' : dateRangeValue.value}`}
                                isSearchable={true}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderRadius: '100px',
                                        boxShadow: 'none', // Remove the blue box shadow
                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                        '&:hover': {
                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                        },
                                        '&:focus': {
                                            outline: 'none', // Remove the blue outline on focus
                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                        },
                                        '&:active': {
                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                        },
                                    }),
                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                    }),

                                }}
                                value={dateRangeTypeValue}
                                onChange={handleDateRangeTypeChange}
                            />
                        </div> */}

                        {/* <div className="form-group">
                            <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                            <select className="form-control custom-selectfilter" required>
                                <option value="All" selected>All</option>
                                <option value="MattsenKumar">MattsenKumar</option>
                                <option value="centurylink">Centurylink</option>
                            </select>
                        </div> */}

                        <div className="form-group" >
                            <label><span>{hierarchyData.c1}</span></label>
                            {console.log("c1Datac1Data", c1Data)}
                            <MultiSelection options={c1Data} customSelectionName="lob" isFilter={true} isFormFilter={false} handleC2Change={handleC2Change} filterData={props?.filterData} selectedCampDataFunc={selectedCampDataFunc} selectedOpt={defaultDataMain?.custom1}/>
                            {/* <select id="custom1_type" className="form-control custom-multiselectfilter">
                                <option value='ACQ'>ACQ</option>
                                <option value='ACT'>ACT</option>
                                <option value='CFS'>CFS</option>
                                <option value='CHAT'>CHAT</option>
                                <option value='CHAT ELQ'>CHAT ELQ</option>
                                <option value='COR'>COR</option>
                                <option value='ERT'>ERT</option>
                                <option value='LFM'>LFM</option>
                                <option value='MyCTL/Prepaid'>MyCTL/Prepaid</option>
                                <option value='Outbound'>Outbound</option>
                                <option value='PPO National SBG'>PPO National SBG</option>
                                <option value='PX Partner'>PX Partner</option>
                                <option value='Quantum Fiber'>Quantum Fiber</option>
                                <option value='Saves'>Saves</option>
                                <option value='SBG-ACQ'>SBG-ACQ</option>
                                <option value='SBG-Alternative'>SBG-Alternative</option>
                                <option value='SBG-AST'>SBG-AST</option>
                                <option value='SBG-C2C'>SBG-C2C</option>
                                <option value='SBG-CFS'>SBG-CFS</option>
                                <option value='SBG-Control Center'>SBG-Control Center</option>
                                <option value='SBG-COR'>SBG-COR</option>
                                <option value='SBG-Directory'>SBG-Directory</option>
                                <option value='SBG-Inbound'>SBG-Inbound</option>
                                <option value='SBG-MTU'>SBG-MTU</option>
                                <option value='Service'>Service</option>
                                <option value='Solutions'>Solutions</option>
                            </select> */}
                        </div>

                        <div className="form-group" >
                            <label><span>{hierarchyData.c2}</span></label>
                            <MultiSelection options={c2Data} customSelectionName="Campaign" isFilter={true} isFormFilter={false} filterData={props?.filterData}  handleC3Change={handleC3Change} selectedOpt={defaultDataMain?.custom2}/>
                            {/* <select className="form-control custom-multiselectfilter search_type">
                            </select> */}
                        </div>

                        <div className="form-group" >
                            <label><span>{hierarchyData.c3}</span></label>
                            <MultiSelection options={c3Data} customSelectionName="vendor" isFilter={true} isFormFilter={false} filterData={props?.filterData}  handleC4Change={handleC4Change} selectedOpt={defaultDataMain?.custom3} />
                            {/* <select className="form-control custom-multiselectfilter search_type" multiple>
                            </select> */}
                        </div>

                        <div className="form-group" >
                            <label><span>{hierarchyData.c4}</span></label>
                            <MultiSelection options={c4Data} customSelectionName="Location" isFilter={true} isFormFilter={false} filterData={props?.filterData}  handleC5Change={handleC5Change} selectedOpt={defaultDataMain?.custom4} />
                            {/* <select className="form-control custom-multiselectfilter search_type" multiple>
                            </select> */}
                        </div>

                        <div className="form-group">
                            <label className="form-label">Manager 2</label>
                            <MultiSelection options={manager2} customSelectionName={'manager2'} isFilter={true} isFormFilter={false} isManager2Filter={true} handleManager2Changed={handleManager2Changed} />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Manager 1</label>
                            <MultiSelection options={manager1} customSelectionName={'manager1'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={true} handleManager1Changed={handleManager1Changed} />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Supervisor</label>
                            <MultiSelection options={supervisor} customSelectionName={'supervisor'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={false} isSupervisorFilter={true} handleSupChanged={handleSupChanged} />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Agent</label>
                            <MultiSelection options={agent} customSelectionName={'agent'} isFilter={true} isFormFilter={false} isManager2Filter={false} isManager1Filter={false} isSupervisorFilter={false} isAgentFilter={true} handleAgentChanged={handleAgentChanged} />
                        </div>

                    </div>

                    <div className="drawer-footer">
                        {props.dashboardFilter ? (
                            <button onClick={handleKPIFilterFunc} type="button" className="btn btn-primary beta add_attributes">
                                <span>Submit</span>
                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>)
                            : (
                                <button onClick={handleFilterFunc} type="button" className="btn btn-primary beta add_attributes">
                                    <span>Submit</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>)
                        }
                    </div>
                </div>
            </form>
        </div>






    )
}

export default KPIDashboardFilter;