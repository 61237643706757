import React, { useState } from "react";
import {
  Route,
  useNavigate,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import Login from "./loginComponent";
import { ErrorNotify, SuccessNotify, DismissToast } from "../shared/Toast";
import { ToastContainer } from "react-toastify";
import { TabTitle } from "../shared/GenralFunctioin/TableTitle";
import OverlayPopover from "../controls/OverlayPopover";
import Popover from "react-bootstrap/Popover";
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const base_name = process.env.PUBLIC_URL;

const ResetPassword = (props) => {
  const [loginData, setLoginData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  TabTitle("Reset-Password :: NEQQO");

  const redirectToLogin = () => {
    localStorage.clear();
  };
  const onResetPwd = () => {
    var url = `${base_url}reset-password`;

    const regRestPwd =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{12,20}$/;
    if (newPassword === "") {
      ErrorNotify("Please Enter New Password");
      return;
    }
    if (!regRestPwd.test(newPassword)) {
      ErrorNotify("Please Enter Valid Password");
      return;
    } else {
      if (confirmPassword === "") {
        ErrorNotify("Please Enter confirm Password");
        return;
      }

      if (newPassword !== confirmPassword) {
        ErrorNotify("New and Confirm Password Should Match");
        return;
      }
    }

    // else{
    //     if((newPassword === "Welcome@123" || newPassword === "welcome@123")){
    //         ErrorNotify("Default Password should not use")
    //     }
    // }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: localStorage.getItem("loginUserUniqueid"),
        new_password: newPassword,
      }),
    };
    console.log("Reset pwd", url, requestOptions);
    setIsLoading(true);
    callApi(url, requestOptions, "reset-password");
  };
  const callApi = async (url, requestOptions, formFlag) => {
    await fetch(url, requestOptions)
      .then((res) => res?.json())
      .then((data) => {
        switch (formFlag) {
          case "reset-password":
            console.log("reset-password----------", data);

            if (data?.status == 200 || data?.status == 201) {
              setIsLoading(false);
              SuccessNotify(data.message, {
                icon: "success",
              });
              //   localStorage.setItem(
              //     "defaultPassword",
              //     data.data.default_password
              //   );
              //   localStorage.setItem("loginToken", data.token);
              //   localStorage.setItem("expiryTime", data.token_expire_time);
              //   setLoginData(data);
              //   props.isLoggedIn(data.token);
              setTimeout(() => {
                navigate("/");
              }, 2000);
              localStorage.clear();
              setLoading(false);
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                setIsLoading(false);
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                setIsLoading(false);
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              setIsLoading(false);
              return;
            }
            break;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Something went wrong, Please contact to Administrator !", {
          icon: "error",
        });
        setIsLoading(false);
        return;
        // console.log('========================', data.data);
      });
  };

  return (
    <div>
      <ToastContainer />
      <div
        style={{ display: "flex" }}
        class="page-wrapper d-flex align-items-center justify-content-center"
      >
        <div class="card login-card">
          <div class="card-header">
            <img
              class="logo--image"
              src={`${base_name}/assets/bootstrap/images/login-logo.png`}
              alt="logo"
            />
            <p>Reset password</p>
          </div>
          <div class="card-body">
            <div class="">
              <div class="form-group" style={{ display: "grid" }}>
                <label for="group_name">
                  <span>New Password</span>
                  <span class="mandatory">*</span>
                </label>
                <div style={{ position: "relative" }} className="resetpwd">
                  <input
                    class="form-control"
                    type="password"
                    maxlength={20}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <OverlayPopover
                    trigger="hover"
                    key="bottom"
                    placement="right"
                    rootClose={true}
                    overlay={
                      <Popover>
                        <Popover.Header as="h6">
                          Password Requirements
                        </Popover.Header>
                        <Popover.Body bsPrefix="pop-body" as="div">
                          <ul
                            style={{ textAlign: "left" }}
                            className="resetPwdUL"
                          >
                            <li>
                              Passwords should contain minimum 12 and maximum 20
                              characters.
                            </li>
                            <li>
                              Passwords should contain one special
                              characters,one uppercase,one lowercase character
                              and one numeric value.
                            </li>
                          </ul>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i style={{ float: "right" }} className="las la-info-circle"></i>
                  </OverlayPopover>
                </div>
              </div>
              <div class="form-group" style={{ display: "grid" }}>
                <label for="group_name">
                  <span>Confirm New Password</span>
                  <span class="mandatory">*</span>
                </label>
                <input
                  class="form-control"
                  type="password"
                  maxlength={20}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="submit"
              class={`btn btn-primary ${isLoading ? "loading" : ""}`}
              onClick={onResetPwd}
            >
              <span className="loader">
                <span
                  className="spinner-border"
                  role="status"
                  aria-hidden="true"
                ></span>
              </span>
              <span>Submit</span>
            </button>
            <div class="d-flex flex-wrap align-items-center mt-4 justify-content-center">
              <Link
                class="small_text unsetSession"
                onClick={() => localStorage.clear()}
              >
                Go To Sign In
              </Link>
              {/* <a class="small_text unsetSession" href="#" >Login</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
