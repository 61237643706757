import React, { useEffect, useState, useCallback, useRef } from "react";
import Footer from "../../../shared/Footer";
import Header from "../../../shared/Header";
import Sidebar from "../../../shared/Sidebar";
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";
import swal from "sweetalert";
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import fileDownload from "js-file-download";
import StickyBox from "react-sticky-box";
import $ from "jquery";
import ButtonComp from "../../../controls/Button";
import moment from "moment";
import Switch from "react-switch";
import { ChatBarColors } from "../../../shared/Constants";
import MediaPlayer from "../../../shared/MediaPlayer";
import MultiSelection from "../../../controls/MultiSelect";
import MultiSelectionCommon from "../../../controls/MultiSelectCommon";
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import Drawer from "../../../controls/Drawer";
import { useParams } from "react-router-dom";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var last_id = "";
var htmlText = "";
// var termsDDArr = '';
const termsArr = [
  { value: "speaker", label: "Speaker" },
  { value: "agent", label: "Agent" },
  { value: "client", label: "Client" },
];
const CallDetails = () => {
  TabTitle("SmartSpeech-Details :: NEQQO");
  const search = useLocation().search;
  const location = useLocation();
  console.log("useLocation()", location);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState();
  const [callDetails, setCallDetails] = useState([]);
  const pageURL = window.location.href;
  const [switchBtn, setSwitchBtn] = useState(false);
  const [skipSilenceSwitchBtn, setSkipSilenceSwitchBtn] = useState(false);
  const [wavFile, setWavFile] = useState();
  const [callTags, setCallTags] = useState("");
  const [currentTime, setCurrentTime] = useState(0); // audio player current playing time
  const [callIdBarTop, setCallIdBarTop] = useState(undefined); // Set Call Id top bar section

  const [sticky, setSticky] = useState("");
  const [clickedApp, setClickedApp] = useState();

  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [seekTime, setSeekTime] = useState(0); // forces player to update its time
  const [appTime, setAppTime] = useState(0); // dictated by player, broadcasted to other components
  const [isClicked, setIsClicked] = useState(false);

  const [bookMarkValue, setBookMarkValue] = useState("");

  const [chatBoxArr, setChatBoxArr] = useState([]);
  const [selectedTagData, setSelectedTagData] = useState();
  const [selectedTermData, setSelectedTermData] = useState([]);
  const [termsDDArr, setTermsDDArr] = useState("");
  const [termPhrase, setTermPhrase] = useState("");

  const [allTagsData, setAllTagsData] = useState([]);
  const [allCallTags, setAllCallTags] = useState([]);
  const [appScoreData, setAppScoreData] = useState([]);
  const [termData, setTermData] = useState(
    new URLSearchParams(search).get("data") !== ""
      ? new URLSearchParams(search).get("data")?.split(",")
      : []
  );
  const audioRef = useRef(null);
  const [selectedPhraseApp, setSelectedPhraseApp] = useState([]);
  const [phraseHLAppData, setPhraseHLAppData] = useState({});

  const [formValues, setFormValues] = useState([{ term: "", term_phrase: "" }]);

  const callId = new URLSearchParams(search).get("callId")?.replace(" ", "+");
  const wavId = new URLSearchParams(search).get("wavId")?.replace(" ", "+");
  const appName = new URLSearchParams(search).get("appName");

  console.log("wavFile", wavFile);
  useEffect(() => {
    if (
      localStorage.getItem("loginToken") === "" ||
      localStorage.getItem("loginToken") === null
    ) {
      localStorage.clear();
      navigate("/");
    }
    setSelectedPhraseApp(JSON.parse(localStorage.getItem("PhraseData")))
  }, []);

  // on render, set listener
  useEffect(() => {
    setUserRole(localStorage.getItem("userRole"));
    // const call_id = '63971a8c21bcb8e73c06f79c';
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    var url = `${base_url}callspeech/${
      pageURL.indexOf("recruitment") > -1 ? "calldetails-rec" : "calldetails"
    }/${callId}`;
    callApi(url, requestOptions, "call-details");

    var url = `${base_url}callspeech/getwavfile/${callId}`;
    callApi(url, requestOptions, "wav-file");

    var url = `${base_url}callspeech/getalltags/${localStorage.getItem(
      "loginUserUniqueid"
    )}`;
    callApi(url, requestOptions, "all-tags");
  }, []);

  const handleAddTags = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        tags: selectedTermData,
      }),
    };
    var url = `${base_url}callspeech/addtag`;
    callApi(url, requestOptions, "add-tag");
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    // setSelectedTermData(newFormValues)
  };

  const handleAddBookmark = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        bookmark_name: bookMarkValue,
        created_by: localStorage.getItem("loginUserUniqueid"),
        tags: selectedTagData,
      }),
    };
    var url = `${base_url}callspeech/addbookmark`;
    callApi(url, requestOptions, "add-bookmark");
  };

  const selectedTagDataFunc = (val) => {
    setSelectedTagData(val);
  };

  const updateTermPhrase = (val) => {
    setSelectedTermData((prevState) => [
      ...prevState,
      {
        userid: localStorage.getItem("loginUserUniqueid"),
        terms: termsDDArr,
        phrases: val,
      },
    ]);
  };

  const selectedTermDataFunc = (val) => {
    termsDDArr = val;
  };

  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "json-res":
            console.log("json res======", data);
            downloadTxtFile("json", JSON.stringify(data.data, null, 2));
            break;

          case "text-res":
            console.log("text res======", data);
            downloadTxtFile("text", data.data);
            break;

          case "call-details":
            // console.log('call details======', JSON.parse(data.data[0].json_data))
            console.log("call details======", data.data[0]);
            if (data.status !== 200) {
              swal(data.message);
              return;
            }
            setCallDetails(data.data[0]);
            setWavFile(data.data[0]?.s3bucketpath);
            if (appName !== "" && appName !== null && appName !== undefined) {
              let valuesArray = data?.data[0]?.applications?.find(
                (x) => x.application_id === appName
              )?.match_appl_phrase;
              console.log("valuesArray", valuesArray);
              setTermData([...termData, ...valuesArray]);
            }
            break;

          case "app-score-data":
            // console.log('call details======', JSON.parse(data.data[0].json_data))
            // console.log('call details======', data.data[0])

            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                let response = data?.data;
                response?.map((app) => {
                  app?.category?.map((cat) => {
                    cat?.exact_match_phrase_with_count_category?.map(
                      (phrase, Ind, arr) => {
                        arr[Ind] = phrase
                          ?.replace("#", " ")
                          .replace(/[0-9]/g, "");
                      }
                    );
                  });
                });
                setAppScoreData(response);
                return;
              } else {
                ErrorNotify("Applications Not Exists");
                return;
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

          case "get-phrase-list":
            if (data?.status == 200 || data?.status == 201) {
              if (data?.subcat_list?.length > 0) {
                let phrasesAppList = [
                  {
                    type: "agent",
                    phrases: [],
                  },
                  {
                    type: "client",
                    phrases: [],
                  },
                  {
                    type: "speaker",
                    phrases: [],
                  },
                ];
                data?.subcat_list?.map((item) => {
                  item?.map((phrase) => {
                    phrasesAppList?.map((app) => {
                      if (phrase?.terms?.toLowerCase() == app?.type) {
                        app?.phrases?.push(phrase?.text);
                      }
                    });
                  });
                });
                setSelectedPhraseApp(phrasesAppList);
              } else {
                ErrorNotify("Phrases Not Exists");
                return;
              }
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }

          case "get-phrase-tag-list":
            if (data?.status == 200 || data?.status == 201) {
              if (
                data?.data?.length > 0 &&
                data?.data[0]?.include_phrases?.length > 0
              ) {
                let phrasesTagList = [
                  {
                    type: "agent",
                    phrases: [],
                  },
                  {
                    type: "client",
                    phrases: [],
                  },
                  {
                    type: "speaker",
                    phrases: [],
                  },
                ];
                data?.data[0]?.include_phrases?.map((item) => {
                  phrasesTagList?.map((tag) => {
                    if (item?.terms?.toLowerCase() == tag?.type) {
                      tag?.phrases?.push(item?.text);
                    }
                  });
                });
                setSelectedPhraseApp(phrasesTagList);
              } else {
                ErrorNotify("Phrases Not Exists");
                return;
              }
              return;
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
          case "wav-file":
            console.log("wav file=====", data);

            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                setWavFile(data.data[0].wav_file_url);
                return;
              }
            } else if (data?.status >= 400 || data?.status <= 422) {
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
            } else {
              ErrorNotify(data.message, {
                icon: "error",
              });
              return;
            }
            break;
          case "add-bookmark":
            console.log("add bookmark ------", data);
            if (data.status === 200) {
              swal(data.message).then((val) => {
                if (val) {
                  window.location.reload();
                }
              });
              return;
            }
            break;

          case "add-tag":
            console.log("add tag ------", data);
            if (data.status === 200) {
              swal(data.message).then((val) => {
                if (val) {
                  window.location.reload();
                }
              });
              return;
            }
            break;

          case "add-calltag":
            console.log("add tag ------", data);
            if (data.status === 200) {
              // SuccessNotify(data.message);
              var url = `${base_url}folder/tag-list/${callId}`;
              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };
              callApi(url, requestOptions, "tag-list");
            } else {
              ErrorNotify(data.message);
              return;
            }
          case "tag-list":
            if (data.status === 200) {
              if (data?.data) {
                setAllCallTags(data?.data);
              }
              // SuccessNotify(data.message);
            } else {
              ErrorNotify(data.message);
            }
            break;

          case "delete-calltag":
            // console.log('add tag ------', data);
            if (data.status === 200) {
              var url = `${base_url}folder/tag-list/${callId}`;

              var requestOptions = {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("loginToken"),
                },
              };
              callApi(url, requestOptions, "tag-list");
            } else {
              ErrorNotify(data.message);
            }
            break;
          case "all-tags":
            console.log("all-tags-------", data.data[0]);
            setAllTagsData(JSON.parse(data.data[0]["tags"]));
            break;

          default:
            break;
        }
      });
  };

  const highLightChatBox = (startTime, id) => {
    if (last_id !== 0 || last_id == "") {
      $("#chat_box_" + last_id).css("border", "");
    }

    setCurrentTime(startTime);
    last_id = id;
    // changeHighLight();
  };

  const changeHighLight = (end_time, len, index) => {};

  const setPhrasesHighlight = (catData, appId) => {
    setSelectedPhraseApp([]);
    setTermData([]);
    setPhraseHLAppData(catData);
    // appScoreData?.map((app) => {
    //   app?.category?.map((cat) => {
    //     if (
    //       cat.cat_id === catData?.cat_id &&
    //       cat.exact_match_phrase_with_count_category?.length > 0
    //     ) {
    //       setSelectedPhraseApp([catData]);
    //     }
    //   });

    //   console.log(
    //     "phrase data",
    //     app?.category?.filter(
    //       (cat) =>
    //         cat.cat_id === catData?.cat_id &&
    //         cat.exact_match_phrase_with_count_category?.length > 0
    //     )
    //   );
    // });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    var url = `${base_url}callspeech/phrases-list-subcategory-wise/${appId}/${catData?.cat_id}`;
    callApi(url, requestOptions, "get-phrase-list");
  };

  const setPhrasesTagHighlight = (tagData) => {
    setSelectedPhraseApp([]);
    setTermData([]);
    setPhraseHLAppData(tagData);
    // setSelectedPhraseApp(
    //   callDetails?.tag?.filter(
    //     (m) => m.tag_id === tagData?.tag_id && m.match_tag?.length > 0
    //   )
    // );

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    var url = `${base_url}tags/tag-details/${tagData?.tag_id}`;
    callApi(url, requestOptions, "get-phrase-tag-list");
  };

  const timeUpdate = (start_time, end_time, len, index) => {
    console.log("UUUUUUUUUU", start_time);
    $("#sf3")[0].currentTime = start_time;
    $("#sf3")[0].play();
    setIsClicked(true);

    // changeHighLight(end_time, len, index);
    // $('#sf2')[0].buffered.start(start_time)
    // $('#sf2')[0].buffered.end(end_time)
    // const minutes = Math.floor(event.toString().split(/[.\-_]/)[0] / 60);
    // const seconds = Math.floor(event.toString().split(/[.\-_]/)[1] - minutes * 60);
    // const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
    // setCurrentTime(currentTime);
  };

  // for downloading call details in json format
  const getJSONRes = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    var url = `${base_url}callspeech/jsonresponse/${callId}`;
    callApi(url, requestOptions, "json-res");
  };

  // for downloading call details in Txt format
  const getTextRes = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    var url = `${base_url}callspeech/textresponse/${callId}`;
    callApi(url, requestOptions, "text-res");
  };

  const deleteCallTag = async (tag) => {
    console.log("Tag", tag);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        call_id: `${callId}`,
        tag: tag,
      }),
    };
    var url = `${base_url}folder/delete-tag`;
    await callApi(url, requestOptions, "delete-calltag");
    setAllCallTags(allCallTags.filter((call) => call != call));
  };

  // for downloading call datails in text format
  const downloadTxtFile = (type = "", data) => {
    if (type === "text") {
      const textContent = data[0]
        .map((line) => line.type + ":" + line.text + "\n" + "\n")
        .join("");
      fileDownload(textContent, `${callId}.txt`);
    }
    if (type === "json") {
      const element = document.createElement("a");
      const file = new Blob([data], { type: "application/json" });
      element.href = URL.createObjectURL(file);
      element.download = `${callId}.json`;
      document.body.appendChild(element);
      element.click();
    }
  };

  const onTimeUpdate = (event) => {
    setCurrentTime(event.target.currentTime);
  };

  const getAllTags = () => {
    var url = `${base_url}folder/tag-list/${callId}`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "tag-list");
  };

  function Highlight({ children: text = "", tags = [], type }) {
    if (!tags?.length) return text;
    let phraseOtherTags = tags?.filter((item) => item.type == type)[0]?.phrases;
    let phraseSpeakerTags = tags?.filter((item) => item.type == "speaker")[0]?.phrases;
    let phraseTags = phraseOtherTags.concat(phraseSpeakerTags)

    const matches = [...text.matchAll(new RegExp(phraseTags?.join("|"), "ig"))];
    const startText = text.slice(0, matches[0]?.index);
    return (
      <span>
        {startText}
        {matches.map((match, i) => {
          const startIndex = match.index;
          const currentText = match[0];
          const endIndex = startIndex + currentText.length;
          const nextIndex = matches[i + 1]?.index;
          const untilNextText = text.slice(endIndex, nextIndex);
          return (
            <span key={i}>
              <span
                style={{
                  background: "#ffff00",
                  color: "#000",
                  fontWeight: "600",
                }}
              >
                {currentText}
              </span>
              {untilNextText}
            </span>
          );
        })}
      </span>
    );
  }

  const getAppScoreData = useCallback(() => {
    console.log("callDetails123", callDetails);
    var url = `${base_url}callspeech/application-score`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        id: `${callId}`,
      }),
    };
    callApi(url, requestOptions, "app-score-data");
  }, [appScoreData]);

  const addCallTags = () => {
    const Tags = callTags.split(",").map(function (item) {
      return item.trim();
    });
    let alltags = [...Tags, ...allCallTags];

    var url = `${base_url}folder/add-tag`;

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        call_id: `${callDetails?.id}`,
        tag: alltags,
      }),
    };

    callApi(url, requestOptions, "add-calltag");
    setCallTags("");
  };

  // const deleteCallTags = () => {

  //     var url = `${base_url}folder/delete-tag`;

  //     var requestOptions = {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
  //         },
  //         body: JSON.stringify({
  //             "call_id": `${callId}`,
  //             "tag":Tags
  //             })
  //     };

  //     callApi(url, requestOptions, 'add-calltag');
  //     setCallTags('')
  // }

  const jumpForward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime += 10;
    }
  };

  const jumpBackward = () => {
    if (audioRef.current) {
      audioRef.current.currentTime -= 10;
    }
  };
  console.log("selectedPhraseApp", selectedPhraseApp);

  return (
    <div className="page-wrapper" style={{ backgroundImage: "none" }}>
      {/* <Header />
            <Sidebar /> */}
      <main>
        <div className="main-contents">
          <div className="ip-header sc-head sc-collapse">
            <div className="container-fluid">
              <div className="sc-header">
                <h3 className="sc-title mb-0">Call Details</h3>
              </div>
            </div>
          </div>
          <div className="d-none" id="success_section">
            <div className="page_error success mb-12">
              <div
                className="alert alert-info text-center"
                id="success_msg"
              ></div>
            </div>
          </div>
          <div className="d-none" id="failure_section">
            <div className="page_error failure mb-12">
              <div
                className="alert alert-info text-center"
                id="failure_msg"
              ></div>
              <button className="remove_error_msg"></button>
            </div>
          </div>
          <div className="ip-section" id="">
            <div className="container-fluid">
              <StickyBox
                offsetTop={52}
                offsetBottom={100}
                style={{
                  zIndex: 1,
                  marginTop: 5,
                  marginBottom: 30,
                }}
              >
                {/* <div className="player-outer mb-4">
                                    <div className="call-detail-main" style={{ position: 'relative' }}>
                                        <audio id="sf2" className="call-details-audio" src={wavFile !== undefined ? wavFile : ''} preload="auto" type="audio/mp3" onTimeUpdate={onTimeUpdate} controls controlsList={userRole == "Admin" ? "" : "nodownload"}>
                                            <source src="horse.ogg" type="audio/ogg" />
                                            <source src="horse.mp3" type="audio/mpeg" />
                                            Your browser does not support the audio element.
                                        </audio>
                                        <>
                                            <button onClick={jumpBackward} style={{ position: 'absolute', marginLeft: "-12px", marginTop:"12px",left: '0', top: '35%', transform: 'translateY(-50%)' }}>
                                                <i className="las fa-backward" style={{ fontSize: "15px" }}></i>
                                            </button>
                                            <button onClick={jumpForward} style={{ position: 'absolute', marginLeft: "40px", marginTop:"12px",left: '0', top: '35%', transform: 'translateY(-50%)' }}>
                                                <i className="las fa-forward" style={{ fontSize: "15px", fontWeight: 'bold' }}></i>
                                            </button>
                                        </>
                                        <div className="callId-title">Call ID : <div className="callId-txt">{wavId}</div></div>
                                    </div> */}

                {/* {currentTime} */}
                {/* <MediaPlayer/> */}
                {/* </div> */}
              </StickyBox>
              <div className="ip-box p-0 call-info-box">
                <div className="box-header">
                  <div className="row">
                    <div className="col-md-2 col-lg-2 col-sm-2">
                      <h4 className="box-title">Additional details</h4>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                      <div
                        className="callId-title"
                        style={{
                          marginLeft: "-50px",
                          marginTop: "2px",
                          fontSize: "12px",
                        }}
                      >
                        <b>Call ID : </b>
                        <div
                          className="callId-txt"
                          style={{ fontSize: "12px" }}
                        >
                          {wavId}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <audio
                        id="sf3"
                        style={{ marginTop: "-5px" }}
                        className=""
                        src={wavFile !== undefined ? wavFile : ""}
                        preload="auto"
                        type="audio/mp3"
                        onTimeUpdate={onTimeUpdate}
                        controls
                        controlsList="nodownload"
                      >
                        Your browser does not support the audio element.
                      </audio>
                      <>
                        <button
                          onClick={jumpBackward}
                          style={{
                            position: "absolute",
                            marginLeft: "25px",
                            marginTop: "3px",
                            left: "0",
                            top: "35%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <i
                            className="las fa-backward"
                            style={{ fontSize: "15px" }}
                          ></i>
                        </button>
                        <button
                          onClick={jumpForward}
                          style={{
                            position: "absolute",
                            marginLeft: "66px",
                            marginTop: "3px",
                            left: "0",
                            top: "35%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <i
                            className="las fa-forward"
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          ></i>
                        </button>
                      </>
                    </div>
                  </div>
                </div>
                <div className="box-contents ip-tabs">
                  <ul className="nav nav-tabs" id="callTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="file-tab"
                        data-toggle="tab"
                        data-target="#file"
                        type="button"
                        role="tab"
                        aria-controls="file"
                        aria-selected="true"
                      >
                        Basic Details
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="drawer-open nav-link"
                        id="app-tab"
                        onClick={() => [getAppScoreData(), setClickedApp(true)]}
                        data-target="appscore"
                        type="button"
                        role="tab"
                        data-toggle="tab"
                        aria-controls="appscore"
                        aria-selected="true"
                      >
                        Application Score
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="drawer-open nav-link"
                        id="tags-tab"
                        onClick={() => setClickedApp(false)}
                        data-toggle="tab"
                        data-target="calltags"
                        type="button"
                        role="tab"
                        aria-controls="calltags"
                        aria-selected="true"
                      >
                        Tag Score
                      </button>
                    </li>
                    <li>
                      <input id="input" type="hidden" value={htmlText} />
                    </li>
                  </ul>
                  <div className="tab-content p-4" id="callTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="file"
                      role="tabpanel"
                      aria-labelledby="file-tab"
                    >
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-4">
                          <label>Recording Date</label>
                          <p className="mb-0">{callDetails?.datetime}</p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-4">
                          <label>Transcript ID</label>
                          <p className="mb-0">{callDetails?.tran_id}</p>
                        </div>
                        {/* <div className="col-md-6 col-lg-4 col-xl-3 mb-4">
                                                    <label>Request ID</label>
                                                    <p className="mb-0">Tue, Mar 22,2022 7:49:31 am ist</p>
                                                </div> */}
                        <div className="ip-switch text-left col-md-6 col-lg-3 col-xl-2 mb-4">
                          <div className="d-inline-block text-center">
                            <label className="d-block mb-2">Show Emotion</label>
                            <div className="ip-switch-control">
                              <label className="switch-text mr-2">Off</label>
                              <Switch
                                width={35}
                                handleDiameter={15}
                                onChange={(e) => setSwitchBtn(!switchBtn)}
                                checkedIcon=""
                                uncheckedIcon=""
                                height={20}
                                checked={switchBtn}
                              />
                              {/* <button type="button" className="btn-switch attribute-switch" data-ref="escalation"></button> */}
                              <label className="switch-text ml-2">On</label>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginLeft: "0px" }}
                          className="ip-switch text-left col-md-6 col-lg-3 col-xl-2 mb-4"
                        >
                          <div className="d-inline-block text-center">
                            <label className="d-block mb-2">Skip Silence</label>
                            <div className="ip-switch-control">
                              <label className="switch-text mr-2">Off</label>
                              <Switch
                                width={35}
                                onChange={(e) =>
                                  setSkipSilenceSwitchBtn(!skipSilenceSwitchBtn)
                                }
                                checkedIcon=""
                                uncheckedIcon=""
                                handleDiameter={15}
                                height={20}
                                checked={skipSilenceSwitchBtn}
                              />
                              {/* <button type="button" className="btn-switch attribute-switch" data-ref="opportunity"></button> */}
                              <label className="switch-text ml-2">On</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Duration</label>
                          <p className="mb-0">{callDetails?.duration}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Silence</label>
                          <p className="mb-0">{`${callDetails?.silent_time}`}</p>
                        </div>
                        {/* <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Diarization</label>
                          <p className="mb-0">{`${
                            callDetails?.diarization
                              ? callDetails?.diarization
                              : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Agent Clarity</label>
                          <p className="mb-0">{`${
                            callDetails?.agent_clarity
                              ? callDetails?.agent_clarity
                              : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Client Clarity</label>
                          <p className="mb-0">{`${
                            callDetails?.client_clarity
                              ? callDetails?.client_clarity
                              : "-"
                          }`}</p>
                        </div> */}
                        {/* <div className="col-md-6 col-lg-4 col-xl-3 mb-4">
                                                    <label>Client Clarity</label>
                                                    <p className="mb-0">87.40%</p>
                                                </div> */}
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Agent Gender</label>
                          <p className="mb-0">
                            {callDetails?.agent_gender
                              ? callDetails?.agent_gender
                              : "-"}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Client Gender</label>
                          <p className="mb-0">{callDetails?.client_gender}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Overall Emotion</label>
                          <p
                            className={`mb-0 ${
                              callDetails?.overall_emotion === "Positive" ||
                              callDetails?.overall_emotion === "Improving"
                                ? "positive"
                                : "negative"
                            }`}
                          >
                            {callDetails?.overall_emotion
                              ? callDetails?.overall_emotion
                              : "-"}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Agent Emotion</label>
                          <p
                            className={`mb-0 ${
                              callDetails?.agent_emotion === "Positive" ||
                              callDetails?.agent_emotion === "Improving"
                                ? "positive"
                                : "negative"
                            }`}
                          >
                            {callDetails?.agent_emotion
                              ? callDetails?.agent_emotion
                              : "-"}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Client Emotion</label>
                          <p
                            className={`mb-0 ${
                              callDetails?.client_emotion === "Positive"
                                ? "positive"
                                : "negative"
                            }`}
                          >
                            {callDetails?.client_emotion
                              ? callDetails?.client_emotion
                              : "-"}
                          </p>
                        </div>
                        
                        
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Original Account Number </label>
                          <p className="mb-0">{`${
                            callDetails?.original_account_number  ? callDetails?.original_account_number  : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Phone Number </label>
                          <p className="mb-0">{`${
                            callDetails?.phone_number  ? callDetails?.phone_number  : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Call Direction </label>
                          <p className="mb-0">{`${
                            callDetails?.Direction  ? callDetails?.Direction  : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Call Date </label>
                          <p className="mb-0">{`${
                            callDetails?.CallDate  ? callDetails?.CallDate  : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Agent Name</label>
                          <p className="mb-0">{`${
                            callDetails?.agent_name  ? callDetails?.agent_name  : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Campaign</label>
                          <p className="mb-0">{`${
                            callDetails?.campaign ? callDetails?.campaign : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Outcome</label>
                          <p className="mb-0">{`${
                            callDetails?.Outcome ? callDetails?.Outcome : "-"
                          }`}</p>
                        </div>
                        {/* <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Client-Phone</label>
                          <p className="mb-0">{`${
                            callDetails?.client_phone
                              ? callDetails?.client_phone
                              : "-"
                          }`}</p>
                        </div> */}
                       
                        {/* <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Disposition-Cat</label>
                          <p className="mb-0">{`${
                            callDetails?.disposation_cat
                              ? callDetails?.disposation_cat
                              : "-"
                          }`}</p>
                        </div> */}
                        {/* <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>Language</label>
                          <p className="mb-0">{`${
                            callDetails?.language ? callDetails?.language : "-"
                          }`}</p>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 mb-4">
                          <label>LOB</label>
                          <p className="mb-0">{`${
                            callDetails?.lob ? callDetails?.lob : "-"
                          }`}</p>
                        </div> */}
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="bookmark"
                      role="tabpanel"
                      aria-labelledby="bookmark-tab"
                    >
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              <span>Bookmark Name</span>
                              <span className="mandatory">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Bookmark Name"
                              required
                              onInput={(e) => setBookMarkValue(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="form-label">
                              <span>Tags</span>
                              <span className="mandatory">*</span>
                            </label>
                            <MultiSelectionCommon
                              options={allTagsData}
                              customSelectionName="tags"
                              selectedTagDataFunc={selectedTagDataFunc}
                            />
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            id="bookmark_sbmtBtn"
                            className="btn btn-primary alpha filter_submit"
                            onClick={handleAddBookmark}
                          >
                            <span>Add Bookmark</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <Drawer
                      id="appscore"
                      classes=""
                      headerTitle="Application Score"
                      footerBtnTitle=""
                      footerBtnId=""
                    >
                      <div
                        class="accordion app-score-accordion"
                        id="parent_accordion"
                      >
                        {appScoreData?.map((app) => {
                          return (
                            <div class="card">
                              <div
                                class="card-header"
                                id={`head_${app?.app_id}`}
                              >
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link btn-block text-left collapsed"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target={`#app_${app?.app_id}`}
                                    aria-expanded="true"
                                    aria-controls={`app_${app?.app_id}`}
                                  >
                                    {app?.app_name}
                                  </button>
                                </h2>
                              </div>

                              <div
                                id={`app_${app?.app_id}`}
                                class="collapse"
                                aria-labelledby={`head_${app?.app_id}`}
                                data-parent="#parent_accordion"
                              >
                                <div class="card-body">
                                  <div class="accordion" id="cat_accordion">
                                    {app?.category?.map((cat) => {
                                      return (
                                        <div class="card">
                                          <div
                                            class="card-header"
                                            id={`head_${cat?.cat_id}`}
                                          >
                                            <h2 class="mb-0">
                                              <button
                                                class="btn btn-link btn-block text-left collapsed"
                                                type="button"
                                                onClick={() =>
                                                  setPhrasesHighlight(
                                                    cat,
                                                    app?.app_id
                                                  )
                                                }
                                                data-toggle="collapse"
                                                data-target={`#cat_${cat?.cat_id}`}
                                                aria-expanded="true"
                                                aria-controls={`cat_${cat?.cat_id}`}
                                              >
                                                {cat?.cat_name}{" "}
                                                <span class="badge badge-pill badge-primary">
                                                  {cat?.phrase_count_percentage}
                                                </span>
                                              </button>
                                            </h2>
                                          </div>

                                          <div
                                            id={`cat_${cat?.cat_id}`}
                                            class="collapse"
                                            aria-labelledby={`head_${cat?.cat_id}`}
                                            data-parent="#cat_accordion"
                                          >
                                            <div class="card-body">
                                              {cat?.sub_category?.map(
                                                (subcat) => {
                                                  return (
                                                    <div
                                                      style={{ marginTop: 5 }}
                                                    >
                                                      <li>{`${subcat?.sub_cat_name} : ${subcat?.subcatwise_in_phrase_count}`}</li>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Drawer>

                    <Drawer
                      id="calltags"
                      classes=""
                      headerTitle="Tag Score"
                      footerBtnTitle=""
                      footerBtnId=""
                    >
                      <div class="accordion app-score-accordion" id="tag">
                        {callDetails &&
                          callDetails?.tag?.map((tag) => {
                            return (
                              <div class="card">
                                <div
                                  class="card-header"
                                  id={`head_${tag?.tag_id}`}
                                >
                                  <h2 class="mb-0">
                                    <button
                                      class="btn btn-link btn-block text-left collapsed"
                                      onClick={() =>
                                        setPhrasesTagHighlight(tag)
                                      }
                                      type="button"
                                      data-toggle="collapse"
                                      data-target={`#tag_${tag?.tag_id}`}
                                      aria-expanded="true"
                                      aria-controls={`tag_${tag?.tag_id}`}
                                    >
                                      {tag?.tag_name}
                                    </button>
                                  </h2>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Drawer>

                    {/* <div className="tab-pane fade" role="tabpanel" aria-labelledby="calltags-tab" id="calltags">
                                            <div className="row">
                                                <div className="col-md-4 col-lg-2 col-xl-3 mb-4">
                                                    <input type="text" className="form-control" value={callTags} onInput={(e) => setCallTags(e.target.value)} placeholder="Enter Tags" />
                                                </div>
                                                <div className="col-md-4 col-lg-3 col-xl-2 mb-4">
                                                    <ButtonComp className="add-tag-btn" onClick={addCallTags} rounded outline disabled={callTags ? false : true}><span><i className="las la-plus la-2x"></i></span></ButtonComp>
                                                </div>

                                            </div>
                                            <div className="row" style={{ marginLeft: 15, marginRight: 15 }}>
                                                {allCallTags.map(tag => {
                                                    return (
                                                        <li className="call-tag-list">{tag.tag_name}
                                                            <span onClick={() => deleteCallTag(tag.tag_id)} ><i className="las la-trash la-2x"></i></span></li>
                                                    )
                                                })}
                                            </div>
                                        </div> */}
                    <div
                      className="tab-pane fade"
                      id="tags"
                      role="tabpanel"
                      aria-labelledby="tags-tab"
                    >
                      <>
                        <div className="mb-part-body text-right d-flex justify-content-between pb-4">
                          <h4 class="form-title"></h4>
                          <div className="d-flex align-items-center">
                            <button
                              onClick={() =>
                                setFormValues([
                                  ...formValues,
                                  { term: "", term_phrase: "" },
                                ])
                              }
                              type="button"
                              className="btn-add btn-include ml-auto"
                            >
                              <i className="las la-plus"></i>
                            </button>
                          </div>
                        </div>

                        {formValues.map((element, index) => {
                          return (
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="form-label">
                                    <span>Term</span>
                                    <span className="mandatory">*</span>
                                  </label>
                                  {/* <MultiSelectionCommon option={termsArr} customSelectionName="terms" selectedTermDataFunc={selectedTermDataFunc} /> */}
                                  <select
                                    className="form-control "
                                    onChange={(e) =>
                                      setTermsDDArr(e.target.value)
                                    }
                                  >
                                    <option>--Select--</option>
                                    <option value="Speaker">Speaker</option>
                                    <option value="Agent">Agent</option>
                                    <option value="Client">Client</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label">
                                  <span></span>
                                </label>
                                <div className="mb-part-body">
                                  <div className="mb-3 chips-header d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control flex-grow-1 input-include"
                                      name=""
                                      placeholder="Include Phrase"
                                      onBlur={(e) => [
                                        setTermPhrase(e.target.value),
                                        updateTermPhrase(e.target.value),
                                      ]}
                                    />
                                  </div>
                                </div>
                              </div>
                              {index ? (
                                <button
                                  type="button"
                                  className="btn-add btn-include remove-tag"
                                  onClick={() => removeFormFields(index)}
                                >
                                  <i className="las la-minus"></i>
                                </button>
                              ) : null}
                            </div>
                          );
                        })}
                      </>
                      <div>
                        <button
                          type="button"
                          id="tag_sbmtBtn"
                          className="btn btn-primary alpha filter_submit"
                          onClick={handleAddTags}
                        >
                          <span>Add Tags</span>
                        </button>
                      </div>

                      <div
                        className="call-tags chips-body"
                        data-ref="include-collection"
                      ></div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="application"
                      role="tabpanel"
                      aria-labelledby="application-tab"
                    ></div>
                    <div
                      className="tab-pane fade"
                      id="tags"
                      role="tabpanel"
                      aria-labelledby="tags-tab"
                    ></div>
                  </div>
                </div>
              </div>

              {/* <div className="row d-none">
                                <div className="col-md-5"></div>
                                <div className="col-md-7">
                                    <div className="ip-box p-0">
                                        <div className="box-header">
                                            <h4 className="box-title">Transcript</h4>
                                        </div>
                                        <div className="box-contents p-0">
                                            <div className="transcript-area">
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item negative">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item positive">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                                <div className="transcript-item">
                                                    <strong className="transcript-user">Client : </strong>
                                                    <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div className="ip-box p-0 mt-4">
                <div className="box-header">
                  <h4 className="box-title">Transcript</h4>
                </div>
                <div className="box-contents p-0">
                  <div className="transcript-area">
                    {callDetails?.diarize_transcript?.length > 0 ? (
                      callDetails?.diarize_transcript?.map((i, kIndex) => {
                        const searchText = i.text;
                        let highlightedText = i?.text; // Initialize with the original text

                        // Iterate through applications and match_appl_phrase
                        // selectedPhraseApp?.length > 0 ?
                        // selectedPhraseApp.forEach((m) => {
                        //     m?.match_appl_phrase?.forEach((n, index) => {
                        //             const searchTerm = n.toLowerCase();
                        //             if (searchText.includes(searchTerm)) {
                        //                 const parts = searchText.split(searchTerm);
                        //                 highlightedText = (
                        //                     <span key={index}>
                        //                         {parts[0]}
                        //                         <span style={{ background: "#ffff00", color: "#000", fontWeight: "600" }}>{n}</span>
                        //                         {parts[1]}
                        //                     </span>
                        //                 );
                        //             }

                        //         });
                        //     })
                        selectedPhraseApp?.length > 0
                          ? // ? selectedPhraseApp.map((m) => {
                            clickedApp
                            ? (highlightedText = (
                                <Highlight
                                  tags={
                                    // Object.keys(m).some(
                                    //   (key) => key == "cat_id"
                                    // )
                                    //   ? m.exact_match_phrase_with_count_category
                                    //   : m?.match_tag
                                    // m?.terms?.toLowerCase() == i?.type
                                    //   ? [m?.text]
                                    //   : []
                                    selectedPhraseApp
                                  }
                                  type={i?.type}
                                >
                                  {searchText}
                                </Highlight>
                              ))
                            : (highlightedText = (
                                <Highlight
                                  tags={
                                    // Object.keys(m).some(
                                    //   (key) => key == "cat_id"
                                    // )
                                    //   ? m.exact_match_phrase_with_count_category
                                    //   : m?.match_tag
                                    // m?.terms?.toLowerCase() == i?.type
                                    //   ? [m?.text]
                                    //   : []
                                    selectedPhraseApp
                                  }
                                  type={i?.type}
                                >
                                  {searchText}
                                </Highlight>
                              ))
                          : // })

                          termData?.length > 0
                          ? (highlightedText = (
                              <Highlight tags={termData}>
                                {searchText}
                              </Highlight>
                            ))
                          : (highlightedText = <span>{highlightedText}</span>);

                        return (
                          <div
                            class="transcript-item d-flex align-item-start"
                            key={kIndex}
                            onClick={() =>
                              timeUpdate(
                                i.sentence_start,
                                i.sentence_end,
                                callDetails?.diarize_transcript?.length,
                                kIndex
                              )
                            }
                          >
                            <div className="transcript-user">
                              <img
                                src="assets/bootstrap/images/user_pic.png"
                                alt="user icon"
                              />
                              <strong style={{ textTransform: "capitalize" }}>
                                {i.type}
                              </strong>
                            </div>

                            <h5
                              id={`chat_box_${kIndex}`}
                              onClick={() =>
                                highLightChatBox(i.sentence_start, kIndex)
                              }
                              className={
                                currentTime >= i.sentence_start &&
                                currentTime <= i.sentence_end
                                  ? "transcript-text active"
                                  : "transcript-text"
                              }
                            >
                              <span
                                style={{
                                  fontWeight: 700,
                                  textTransform: "capitalize",
                                }}
                              >
                                {i?.type} :{" "}
                              </span>
                              <span
                                style={{ textAlign: "justify" }}
                                id={`chat_span_${kIndex}`}
                              >
                                {highlightedText}
                              </span>
                              {i.event_silence_time > 0 ? (
                                <span>
                                  [ SILENCE : {Math.round(i.event_silence_time)}{" "}
                                  ]
                                </span>
                              ) : (
                                ""
                              )}
                            </h5>
                            <hr />
                          </div>
                        );
                      })
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontSize: "12px",
                        }}
                      >
                        No Transcript Found
                      </p>
                    )}
                    {/* <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start negative">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start positive">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div>
                                        <div className="transcript-item d-flex align-item-start">
                                            <div className="transcript-user">
                                                <img src="assets/bootstrap/images/user_pic.png" alt="user icon" />
                                                <strong>Client</strong>
                                            </div>
                                            <div className="transcript-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default CallDetails;
