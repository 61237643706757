import React, { useCallback, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";

const MultiRangeSlider = ({
  min,
  max,
  onChange,
  setMaxFlag,
  maxFlag,
  rangeLabel,
  type,
  maxButton,
}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [minDuration, setMinDuration] = useState("");
  const [maxDuration, setMaxDuration] = useState("");
  const [minMinute, setMinMinute] = useState(0);

  const [maxMinute, setMaxMinute] = useState(0);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  console.log("maxMinute",maxMinute,minMinute)
  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    console.log("maxFlag", maxFlag);
    if (maxFlag) {
      onChange({ min: minMinute, max: -1 });
      setMaxDuration("Max")
      setMaxVal(max)
    }
    else{
      if (type == "time") {
        onChange({ min: minMinute, max: maxMinute });
        const maxDuration = getMaxTimeDuration(max);
        if (maxFlag) {
          setMaxDuration('Max');
        } else {
          setMaxDuration(maxDuration);
        }
        console.log("maxDuration", maxDuration);
      }
    }
  }, [maxFlag]);
console.log("Min Minute",minMinute)
  // Convert milliseconds to HH:MM:SS
  const getMinTimeDuration = (value) => {
    // 1- Convert to seconds:
    let seconds = value / 1000;
    // 2- Extract hours:
    let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;

    setMinMinute(`${minutes}.${seconds == 0 ? "00":seconds?.toFixed()}`)
    // setMinMinute(
    //   minutes < 10
    //     ? minutes
    //     : seconds?.toFixed() == 60
    //     ? (minutes + 1).toFixed()
    //     : minutes?.toFixed()
    // );
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10
        ? "0" + minutes
        : seconds?.toFixed() == 60
        ? (minutes + 1).toFixed()
        : minutes?.toFixed()
    }:${
      seconds?.toFixed() < 10
        ? "0" + seconds?.toFixed()
        : seconds?.toFixed() == 60
        ? "00"
        : seconds?.toFixed()
    }`;
  };

  // Convert milliseconds to HH:MM:SS
  const getMaxTimeDuration = (value) => {
    console.log("getMaxTimeDuration",value)
    // 1- Convert to seconds:
    let seconds = value / 1000;
    // 2- Extract hours:
    let hours = parseInt(seconds.toFixed() / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    let minutes = parseInt(seconds.toFixed() / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    setMaxMinute(`${minutes}.${seconds == 0 ? "00":seconds?.toFixed()}`)

    // setMaxMinute(
    //   minutes < 10
    //     ? minutes
    //     : seconds?.toFixed() == 60
    //     ? minutes.toFixed()
    //     : minutes?.toFixed()
    // );
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10
        ? "0" + minutes
        : seconds?.toFixed() == 60
        ? minutes.toFixed()
        : minutes?.toFixed()
    }:${
      seconds?.toFixed() < 10
        ? "0" + seconds?.toFixed()
        : seconds?.toFixed() == 60
        ? "00"
        : seconds?.toFixed()
    }`;
  };

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }

      if (type == "time") {
        const minDuration = getMinTimeDuration(minVal);
        setMinDuration(minDuration);
        console.log("minDuration", minDuration);
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
      if (type == "time") {
        const maxDuration = getMaxTimeDuration(maxVal);
        if(maxVal < 1800000){
          setMaxFlag(false)
          setMaxDuration(maxDuration);
        }
        else{
          setMaxFlag(true)
          setMaxDuration("Max");
        }
        console.log("maxDuration", maxDuration);
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    if (type == "time") {
      if (maxFlag) {
        onChange({ min: minMinute, max: -1 });
      } else {
        onChange({ min: minMinute, max: maxMinute });
      }
    }
    else{
      onChange({ min: minVal, max: maxVal });
    }
  }, [minVal, maxVal, onChange]);

  // Get min and max values when their state changes
  useEffect(() => {
    if (type == "time") {
      if (maxFlag) {
        onChange({ min: minMinute, max: -1 });
      } else {
        onChange({ min: minMinute, max: maxMinute });
      }
    }
    else{
      onChange({ min: minVal, max: maxVal });
    }
  }, [minMinute, maxMinute, onChange]);

  return (
    <div className="container my-0" style={{ height: "5vh" }}>
      {maxButton ? (
        <div className="max-checkbox">
          <input type="checkbox" checked={maxFlag} onChange={() => setMaxFlag(!maxFlag)} />
          <label>Max</label>
        </div>
      ) : (
        ""
      )}
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1);
          setMinVal(value);
          event.target.value = value.toString();
        }}
        className={classnames("thumb thumb--zindex-3", {
          "thumb--zindex-5": minVal > max - 100,
        })}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal + 1);
          setMaxVal(value);
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__left-value">
          {type == "time" ? minDuration : minVal}
          {rangeLabel}
        </div>
        <div className="slider__right-value">
          {type == "time" ? maxDuration : maxVal}
          {rangeLabel}
        </div>
        <div className="slider__track" />

        <div ref={range} className="slider__range" />
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
