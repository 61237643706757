import React, { useEffect, useState } from 'react'
import { TabTitle } from '../../../shared/GenralFunctioin/TableTitle';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { components } from "react-select";
import { DismissToast, ErrorNotify, SuccessNotify } from '../../../shared/Toast';
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from 'moment';
import XLSX from "xlsx"


var csvReport = {
  data: [],
};
let headers = [
  { label: "call_id", key: "call_id" },
  { label: "Filename", key: "Filename" },
  { label: "CallDate", key: "CallDate" },
  { label: "Original Account Number", key: "Original_Account_Number" },
  { label: "Phone Number", key: "Phone_Number" },
  { label: "Call Direction", key: "Call_Direction" },
  { label: "Agent Name", key: "Agent_Name" },
  { label: "Campaign", key: "Campaign" },
  { label: "Outcome", key: "Outcome" },
  { label: "utterance", key: "utterance" },
  { label: "tag_name", key: "tag_name" },
  { label: "match_tag", key: "match_tag" },
 
];

const SSTagReport = () => {
  TabTitle("SmartSpeech-Tags-Report :: NEQQO");
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_AUTH_BASE_URL;
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [tagListData, setTagListData] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const [filteredFolders, setFilteredTags] = useState([]);
  const [tagID, setTagId] = useState("");
  const [downloading, setDownloading] = useState(false);
  const [toDateDisabled, setToDateDisabled] = useState(true);
  const [tags, setTags] = useState("");
  const [activeTab, setActiveTab] = useState("Manual");
  const [name, setName] = useState("");
  const [frequency, setFrequency] = useState([]);
  const [deliveryProfileValue, setDeliveryProfileValue] = useState([]);
  const [format, setFormat] = useState("XLSX");
  const [startAt, setStartAt] = useState("");
  const [scheduleGroupList, setScheduleGroupList] = useState([]);
  const [scheduleLoader, setScheduleLoader] = useState(false);

  const optionsFrequency = [
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
    { value: "weekly", label: "Weekly" },
  ];

  const getDeliveryGroupList = () => {
    var url = `${base_url}delivery-group-list`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };

    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        console.log("group-list--", data);
        if (data?.status == 200 || data?.status == 201) {
          setScheduleGroupList(data?.data);
          return;
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });

            localStorage.clear();
            navigate("/");
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          return;
        }
      })
      .catch((err) => {
        DismissToast();
        ErrorNotify("Network Issue. Please Try Again !", {
          icon: "error",
        });
        return;
        // console.log('========================', data.data);
      });
  };

  useEffect(() => {
    getDeliveryGroupList();
  }, []);

  const ValueContainer2 = ({ children, ...props }) => {
    const { getValue } = props;
    let selectedOption = getValue().length;
    console.log("tagValuetagValue234445", tagValue);
    if (tagValue && tagValue?.length > 0) {
      return (
        <components.ValueContainer {...props}>
          {`${tagValue?.length} items selected`}
        </components.ValueContainer>
      );
    } else {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
  };

  const Option = (props) => {

    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleTagReportChange = (e, event) => {
    csvReport = {
      data: [],
    };
    if (event?.option?.value == "All" && event?.action == "select-option") {
      setTagValue(tagListData);
      console.log("11111111111", tagListData);
      setFilteredTags && setFilteredTags(tagListData);
      let allTagValue = [];
      tagListData &&
        tagListData?.map((item) => {
          if (item?.value !== "All") {
            allTagValue.push(item?.value);
          }
        });
      setTagId(allTagValue);
      console.log("allTagValue12222", allTagValue);
      downloadTagReport(allTagValue, to_date)
    }
    else if (event?.option?.value == "All" && event?.action == "deselect-option") {
      console.log("2222222222");
      setFilteredTags && setFilteredTags([]);
      setTagValue([]);
      setTagId([]);
      // downloadTagReport([], to_date)
    } else {
      if (e.some((i) => i.value == "All")) {
        console.log("3333333333");
        let filterData = e.filter((item) => item?.value !== "All");

        setTagValue(filterData);
        let filterDataValue = [];
        filterData?.map((item) => {
          filterDataValue.push(item.value);
        });
        setTagId(filterDataValue);
        //downloadTagReport(filterDataValue, to_date)
      }
      else {
        // if (tagListData?.length - 1 == e?.length) {
        //   console.log("444444444");
        //     setTagValue(tagListData);

        //     setTagId(["All"]);
        // }
        // else {
          console.log("55555555555");
          if (e?.length > 10) {
            return ErrorNotify("Can not select tags more than 10 at a time")
          }
          else {
            let tagData = [];

            e?.map((item) => {
              tagData.push(item);
            });
            setTagValue(tagData);
            setFilteredTags && setFilteredTags(tagData);
            let tagValue = [];
            e?.map((item) => {
              tagValue.push(item.value);
            });
            setTagId(tagValue);
            // downloadTagReport(tagValue,to_date)

          }
        // }
      }
    }
    console.log("eeeeeeeeeeeeee", tagValue);
    // downloadTagReport(e?.map(i=>i?.value),to_date)

  };





  useEffect(() => {
    var url = `${base_url}tags/all-tags`;

    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
    };
    callApi(url, requestOptions, "all-tags-list");

  }, [])



  // const convertToCSV = (objArray) => {
  //   const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  //   let csv = "";
  //   csv += headers.join(",") + "\n";
  //   array.forEach((item) => {
  //     let row = "";
  //     headers.forEach((header) => {
  //       if (row !== "") row += ",";
  //       row += item[header];
  //     });
  //     csv += row + "\n";
  //   });
  //   return csv;
  // };


  const headerLabels = headers.map(header => header.label);

  const convertToCSV = (objArray, headerLabels) => {
    let csv = "";
    csv += headerLabels.join(",") + "\n";
    objArray.forEach((item) => {
      let row = "";
      headerLabels.forEach((header, index) => {
        if (index > 0) row += ",";
        let fieldValue = item[header.replace(/ /g,"_")]; // Replace spaces with underscores in keys
        // Check if the fieldValue contains commas, if so, enclose it in double quotes
        if (fieldValue && fieldValue.includes(",")) {
          fieldValue = `"${fieldValue}"`;
        }
        row += fieldValue;
      });
      csv += row + "\n";
    });
    return csv;
  };



  const callApi = async (url, requestOptions, flag = "") => {
    await fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        switch (flag) {
          case "all-tags-list":
            console.log("all-tags-list------", data);
            if (data?.status == 200 || data?.status == 201) {
              if (data?.data?.length > 0) {
                let tagListArr = [];
                // tagListArr.push({ label: "All", value: "All" });
                data?.data?.map((item) => {
                  const values = {
                    label: item.tag_name,
                    value: item.tag_id,
                  };
                  tagListArr.push(values);
                  console.log("HGFDKGFDF", tagListArr);
                });
                setTagListData(tagListArr);

              }
            }
            else if (data?.status >= 400 || data?.status <= 422) {

              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            }
            else {

              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
            break;
          case "tag-report":
            if (data?.status == 200 || data?.status == 201) {
              console.log("data?.data", data?.data);
              setDownloading(false)
              refreshValues()
              if (data?.data?.length === 0) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
              }
              else {
                let modifiedData = data?.data?.map(item => {
                  let modifiedItem = {};
                  for (let key in item) {
                    modifiedItem[key.replace(/ /g, "_")] = item[key];
                  }
                  // Add hyperlink to call_id field
                  modifiedItem["call_id"] = { t: 's', v: item["call_id"], l: { Target: `${item["call_id"]}`, Tooltip: `Link to call ${item["call_id"]}` }};
                  return modifiedItem;
                });
                console.log("modifiedData",modifiedData);
                
                // Create a new workbook and worksheet
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(modifiedData);
                
                // Add the worksheet to the workbook
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                
                // Write the workbook to a file
                XLSX.writeFile(wb, "tag-report.xlsx");

                // csvReport = {
                //   data: modifiedData || [],
                //   headers: headers, // Extract label from objects or use strings directly
                //   filename: "tag-report.csv",
                // };
                

                  
    //               const csvData = convertToCSV(csvReport?.data, headerLabels);
    // const blob = new Blob([csvData], { type: "text/csv" });
    // const url = window.URL.createObjectURL(blob);
    // const anchor = document.createElement("a");
    // anchor.href = url;
    // anchor.download = "tag-report.csv"; // Rename file here if needed
    // anchor.click();
    // window.URL.revokeObjectURL(url);
    
                
              }
  console.log("HHHHHHHHHHHH", csvReport);

            }
            else if (data?.status >= 400 || data?.status <= 422) {
              setDownloading(false)
              if (data?.status == 401) {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                localStorage.clear();
                navigate("/");
                return;
              } else {
                ErrorNotify(data.error, {
                  icon: "error",
                });
                return;
              }
            }
            else {
              
              ErrorNotify(data.error, {
                icon: "error",
              });
              return;
            }
        }
      })
  }


  const downloadTagReport = () => {

    if (from_date === '') {
      ErrorNotify('Please select from date');
      return
    }
    if (to_date === '') {
      ErrorNotify('Please select to date');
      return
    }
    if (tagID?.length === 0) {
      ErrorNotify('Please select atleast 1 tag');
      return
    }



    setDownloading(true)
    var url = `${base_url}smartspeech-report/tag-report`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
      },
      body: JSON.stringify({
        from_date: from_date,
        to_date: to_date,
        tag: tagID?.length > 0 ? tagID : [],
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
      })
    };
    callApi(url, requestOptions, "tag-report");

  }

  const handleFromDateChange = (e) => {
    csvReport = {
      data: [],
    };
    const selectedDate = new Date(e.target.value);
    const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
    // setToDate('');
    document.getElementById('toDateInput').setAttribute('min', e.target.value);
    document.getElementById('toDateInput').setAttribute('max', endOfMonth.toISOString().split('T')[0]);

    setFromDate(e.target.value);
    setToDateDisabled(false)
    setTags("")
    // setTagValue([])


  };

  const handleToDateChange = (e) => {
    csvReport = {
      data: [],
    };
    // setTagId([])
    setToDate(e.target.value);
    setTags("")
    // setTagValue([])

    console.log("toTTYTUTUTT", e.target.value);
    // downloadTagReport([],e.target.value)




  };

  

  const optionsDeliveryList =
    scheduleGroupList?.length > 0 ?
    scheduleGroupList?.map((item) => ({
      value: item._id,
      label: item.group_name,
    })):[];

  const abc = () => {
    console.log("oeiruetugirvyhrbtyui");
  }

  const handleFrequencyChange = (e) => {
    console.log("frequency", e);
    setFrequency(e);
  };

  const handleDeliveryChange = (val) => {
    console.log("DeliveryChange", val);
    setDeliveryProfileValue(val);
  };

  const scheduleReport = () => {
    if (name === "" || name.length === 0 || name === "Select") {
      ErrorNotify(`Please Select Schedule Name`);
      return;
    }
    if (frequency === "" || frequency.length === 0 || frequency === "Select") {
      ErrorNotify(`Please Select Report Frequency`);
      return;
    }
    if (
      deliveryProfileValue === "" ||
      deliveryProfileValue.length === 0 ||
      deliveryProfileValue === "Select"
    ) {
      ErrorNotify(`Please Select Delivery profile`);
      return;
    }
    if (format === "" || format.length === 0 || format === "Select") {
      ErrorNotify(`Please Select Report Format`);
      return;
    }
    if (startAt === "" || startAt.length === 0 || startAt === "Select") {
      ErrorNotify(`Please Select Start At`);
      return;
    }
    setScheduleLoader(true);
    var url = `${base_url}smartspeech-scheduler-report-add`;
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("loginToken"),
      },
      body: JSON.stringify({
        
          filter_schedule_name:name,
          filter_report_frequency:frequency?.label,  //====Weekly,Monthly..send value like this
          filter_delivery_profile_group_id:deliveryProfileValue?.label,
          filter_scheduler_start_date_time:startAt,
          user_id:localStorage.getItem("loginUserUniqueid"),
         tag: tagID?.length > 0 ? tagID : [],
        proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
        proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
      }),
    };
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data?.status == 200 || data?.status == 201) {
          SuccessNotify(data.message);
          setScheduleLoader(false);
          setName("");
          setFrequency([]);
          setDeliveryProfileValue([]);
          setFormat("XLSX");
          setStartAt("");
          refreshValues()
        
        } else if (data?.status >= 400 || data?.status <= 422) {
          if (data?.status == 401) {
            ErrorNotify(data.message, {
              icon: "error",
            });
            localStorage.clear();
            navigate("/");
            setScheduleLoader(false);
            return;
          } else {
            ErrorNotify(data.message, {
              icon: "error",
            });
            setScheduleLoader(false);
            return;
          }
        } else {
          ErrorNotify(data.message, {
            icon: "error",
          });
          setScheduleLoader(false);
          return;
        }
      })
      .catch((err) => {
        setScheduleLoader(false);
        ErrorNotify("Network Issue. Please Try Again !", {
          icon: "error",
          toastId: "login",
        });
        return;
      });
  }

  const refreshValues = () => {
    setName("")
    setFrequency([])
    setDeliveryProfileValue([])
    setTagValue("")
    setFromDate("")
    setToDate("")
    setToDateDisabled(true)
    setStartAt("")
    
  }

  return (
    <>
      <div>
        <main>
          <ToastContainer />
          <div className="main-contents">
            <div className="d-none" id="success_section">
              <div className="page_error success mb-12">
                <div className="alert alert-info text-center" id="success_msg"></div>
              </div>
            </div>
            <div className="d-none" id="failure_section">
              <div className="page_error failure mb-12">
                <div className="alert alert-info text-center" id="failure_msg">
                </div>
                <button className="remove_error_msg"></button>
              </div>
            </div>

            <div className="ip-header sc-head sc-collapse">
              <div className="container-fluid">
                <div className="sc-header d-flex justify-content-between align-items-center">
                  <h3 className="sc-title d-flex align-items-center"><span>Tag Report</span></h3>
                </div>
              </div>
            </div>

            <nav className="ip-breadcrumb" aria-label="breadcrumb">
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item active"><span>Tag Report</span></li>
              </ul>
            </nav>
            
            <div className="report-filter" data-reference="reports_common_filter">
            <div className="container-fluid" style={{ paddingBottom: "2rem" }}>
              <div className="report-filter-inner">
                <ul className="nav nav-tabs" id="reportTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      style={{backgroundColor:activeTab==="Manual"?"#0D9DD9":"#ffffff",color:activeTab==="Manual"?"#ffffff":"#000000"}}
                      onClick={()=>[setActiveTab("Manual"),refreshValues()]}
                      id="report01-tab"
                      data-toggle="tab"
                      href="#report01"
                      role="tab"
                      aria-controls="report01"
                      aria-selected="true"
                    >
                      Manual Report
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link"
                      style={{backgroundColor:activeTab==="Schedule"?"#0D9DD9":"#ffffff",color:activeTab==="Schedule"?"#ffffff":"#000000"}}
                      onClick={()=>[setActiveTab("Schedule"),refreshValues()]}
                      id="report03-tab"
                      data-toggle="tab"
                      href="#report03"
                      role="tab"
                      aria-controls="report03"
                      aria-selected="false"
                    >
                      Schedule Report
                    </a>
                  </li>
                  </ul>
                  <div className="tab-content" id="reportContent">
                <div
                    className="tab-pane fade show active"
                    id="report01"
                    role="tabpanel"
                    aria-labelledby="report01-tab"
                  >
                  <div className="form-contents">
                    <form action="" method="post" className="filter_page_form" id="filter_page_form">
                      <input type="hidden" className="txt_csrfname" name="csrf_test_name" value="2f5396b97c047600347cc8c34e88d544" />
                      <div className="form-row">
                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                          <label><span>From Date</span><span className="mandatory">*</span></label>
                          <input type="date" className="form-control" value={from_date} placeholder="From Date" required="" onChange={(e) => handleFromDateChange(e)} />
                        </div>
                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                          <label><span>To Date</span><span className="mandatory">*</span></label>
                          <input className="form-control" type="date" disabled={toDateDisabled} required="" value={to_date} id="toDateInput" onChange={(e) => handleToDateChange(e)} />
                        </div>
                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                          <label><span>Tags</span><span className="mandatory">*</span></label>
                          {console.log("tagListDatatagListData", tags)}
                          <Select
                            options={tagListData}
                            placeholder="Select Tag"
                            isSearchable={true}
                            isMulti
                            className=""
                            //value={ tags !== '' ? tagListData?.find((option, index) => option.value === tags) : ''}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options

                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainer2,
                            }}
                            // value={tagValue}
                            value={tagValue !== '' ? tagListData?.find((option, index) => option.value === tagValue) : ''}
                            onChange={(e, event) => [console.log("tagValueytrtyrtiritr",tagValue)
                             ,handleTagReportChange(e, event)]}
                          />
                        </div>
                        {console.log("csvReportcsvReport", csvReport)}
                        <div className='col-sm-3 col-md-3 col-lg-3 form-group'>

                          {/* {csvReport.data && csvReport.data.length > 0 && !downloading ? 
                                            <CSVLink {...csvReport}>
                                                <button type='button'  style={{width:"50%",marginTop:"20px"}}  id="downloadBtn" className="btn btn-primary ml-auto">
                                                    Download
                                                </button>
                                            </CSVLink>
                                         :
                                        downloading ?
                                                <button type="button" style={{width:"50%",marginTop:"20px"}} disabled className="btn btn-primary ml-auto" onClick={downloadTagReport}>
                                                    Download   <div class="spinner-border" role="status" style={{ height: "15px", width: "15px",marginLeft:"30px" }}>
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </button> :
                                                
                                                    <button type="button" style={{width:"50%",marginTop:"20px"}}  disabled className="btn btn-primary ml-auto" onClick={downloadTagReport}>
                                                        Download
                                                    </button>
                                                } */}
                          {csvReport.data && csvReport.data.length > 0 ?
                            <CSVLink {...csvReport}>
                              <button type='button' style={{ width: "50%", marginTop: "20px" }} onClick={()=>abc()} id="downloadBtn" className="btn btn-primary ml-auto">
                                Download
                              </button>
                            </CSVLink>
                            :
                            <button type="button" style={{ width: "50%", marginTop: "20px" }} className="btn btn-primary ml-auto" onClick={downloadTagReport}>
                              Download {downloading &&
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>}
                            </button>
                          }
                        </div>
                      </div>

                    </form>
                  </div>
                  </div>
                  <div
                  
                    className="tab-pane fade"
                    id="report03"
                    role="tabpanel"
                    aria-labelledby="report03-tab"
                  >
                    
<div className="form-row">
                      <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                        <label>
                          <span>Name</span>
                          <span className="mandatory">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Add Scheduler Name"
                          type="text"
                          value={name}
                          required=""
                          onInput={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                        <label>
                          Frequency <span className="mandatory">*</span>
                        </label>
                        <Select
                          options={optionsFrequency}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          placeholder="Select"
                          isSearchable={true}
                          // value={frequency}
                          value={frequency !== '' ? optionsFrequency?.filter((option, index) => option.value === frequency?.value) : ''}
                          onChange={(e) => handleFrequencyChange(e)}
                        />
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                        <label>Delivery Profile </label>
                        <Select
                          options={optionsDeliveryList}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "100px",
                              boxShadow: "none", // Remove the blue box shadow
                              borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                              "&:hover": {
                                borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                              },
                              "&:focus": {
                                outline: "none", // Remove the blue outline on focus
                                borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                boxShadow: "none", // Remove the blue box shadow on focus
                              },
                              "&:active": {
                                borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                              },
                            }),
                            indicatorSeparator: (provided) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              background: state.isSelected
                                ? "#fff"
                                : "transparent", // Remove background highlight from non-selected options
                              color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options
                            }),
                          }}
                          placeholder="Select"
                          isSearchable={true}
                          // value={deliveryProfileValue}
                          value={deliveryProfileValue !== '' ? optionsDeliveryList?.filter((option, index) => option.value === deliveryProfileValue?.value) : ''}
                          onChange={handleDeliveryChange}
                        />
                      </div>
                      <div className='col-sm-3 col-md-3 col-lg-3 form-group'>
                      <label><span>Tags</span><span className="mandatory">*</span></label>
                      <Select
                            options={tagListData}
                            placeholder="Select Tag"
                            isSearchable={true}
                            isMulti
                            className=""
                            //value={ tags !== '' ? tagListData?.find((option, index) => option.value === tags) : ''}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "100px",
                                boxShadow: "none", // Remove the blue box shadow
                                borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                "&:hover": {
                                  borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                },
                                "&:focus": {
                                  outline: "none", // Remove the blue outline on focus
                                  borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                  boxShadow: "none", // Remove the blue box shadow on focus
                                },
                                "&:active": {
                                  borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                  boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                },
                              }),
                              indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                                color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options

                              }),
                            }}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            allowSelectAll={true}
                            components={{
                              Option,
                              ValueContainer: ValueContainer2,
                            }}
                            // value={tagValue}
                            value={tagValue !== '' ? tagListData?.find((option, index) => option.value === tagValue) : ''}
                            onChange={(e, event) => [handleTagReportChange(e, event)]}
                          />
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                        <label>
                          Format <span className="mandatory">*</span>
                        </label>
                        <select
                          name="filter_scheduler_format"
                          id="filter_scheduler_format"
                          className="form-control custom-select"
                          placeholder=""
                          onChange={(e) => setFormat(e.target.value)}
                        >
                          <option value="xlsx">XLSX</option>
                          {/* <option value="xls">XLS</option>
                                                    <option value="pdf">PDF</option> */}
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                        <label>
                          Start At <span className="mandatory">*</span> :{" "}
                          <span id="startAt" className="d-none" />
                        </label>
                        <input
                          step={1}
                          type="datetime-local"
                          min={`${moment().format("YYYY-MM-DD")}T00:00:00`}
                          className="form-control "
                          autoComplete="off"
                          id="callDateTimeInput"
                          placeholder="Add Scheduler Start At Date Time"
                          required
                          onChange={(e) => {
                            setStartAt(e.target.value);
                          }}
                          value={startAt}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                      
                        <button
                          type="button"
                          id="filter_sbmtBtn_schedule"
                          className="btn btn-primary ml-3 w-100"
                          style={{marginTop:"20px"}}
                          onClick={scheduleReport}
                        >
                          Schedule Report
                          {scheduleLoader && (
                            <div className="">
                              <div
                                className="spinner-border text-white"
                                role="status"
                              ></div>
                            </div>
                          )}
                        </button>
                      
                    </div>
                    </div>
                    
                  </div>
                  </div>
                </div>
              </div>
            </div>
                 


            
          </div>
        </main>
      </div>
    </>
  )
}

export default SSTagReport