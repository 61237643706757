import React, { useCallback, useEffect, useState } from 'react'
import { ErrorNotify, SuccessNotify } from '../../shared/Toast';
import { ToastContainer } from 'react-toastify';
import Select from 'react-select';

const UsersPermissions = () => {
   const [users, setUsers] = useState([])
   const [checkedModules, setCheckedModules] = useState({});
   const [allRoles, setAllRoles] = useState([]);
   const [updateAllRoles, setUpdateAllRoles] = useState([]);
   const [usersByRole, setUsersByRole] = useState([]);
   const [usersDropDownShow, setUsersDropDownShow] = useState(false);
   const [moduleSubmenu, setModuleSubmenu] = useState([]);
   const [checkedItems, setCheckedItems] = useState({});
   const [createSubmoduleData, setCreateSubmoduleData] = useState([]);
   const [moduleItem, setModuleItem] = useState([]);
   const [addRemoveSubmodule, setAddRemoveSubmodule] = useState([]);
   const [allModulesForRoles, setAllModulesForRoles] = useState([]);
   const [moduleUser, setModuleUser] = useState([]);
   const [checkedSubmodules, setCheckedSubmodules] = useState({});
   const [rolesPermData, setRolesPermData] = useState([]);
   const [rolesDataUpdation, setRolesDataUpdation] = useState([]);
   const [rolesDataUpdation2, setRolesDataUpdation2] = useState([]);
   const [matchModules, setMatchModules] = useState([]);
   const [finalRolesData, setFinalRolesData] = useState([]);
   const [roleWiseModule, setRoleWiseModule] = useState([]);
   const [userWiseModule, setUserWiseModule] = useState([]);
   const [userModuleAccess, setUserModuleAccess] = useState("");
   const [savePermBtn, setSavePermBtn] = useState(true);
   const [allModulesSerialWise, setAllModulesSerialWise] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [onlyForModuleCheckCond, setOnlyForModuleCheckCond] = useState([]);
   const [userValue, setUserValue] = useState("");
   const [roleId, setRoleId] = useState("");







   let withSubmenu = []
   let withoutSubmenu = []







   console.log("roleWiseModule", roleWiseModule);




   const base_url = process.env.REACT_APP_AUTH_BASE_URL;

   useEffect(() => {
      getUsers()
      getRoles()
   }, [])

   const getUsers = () => {
      var url = `${base_url}users/modules-listing`;

      var requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },

      };

      callApi(url, requestOptions, 'get-users');
   }
   const getRoles = () => {
      var url = `${base_url}roles/show`;

      var requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },
         body: JSON.stringify({
            token: localStorage.getItem('loginToken'),
            proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
            proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
         })

      };

      callApi(url, requestOptions, 'get-roles');
   }

   const updateUserModule = () => {
      var url = `${base_url}users/update-user-module-access`;

      console.log("onlyForModuleCheckCondDDDDDDDD", onlyForModuleCheckCond);






      var requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },
         body: JSON.stringify({
            "module_name": onlyForModuleCheckCond?.ModuleAccess ? onlyForModuleCheckCond?.ModuleAccess : onlyForModuleCheckCond?.module_name, users: [moduleUser]
         })

      };

      callApi(url, requestOptions, 'user-module-access');
   }


   useEffect(() => {
      // Updating checkedModules state when users change
      const initialCheckedModules = users?.reduce((acc, curr) => {
         if (curr.visibility === 'yes') {
            return { ...acc, [curr.id]: curr.module };
         }
         return acc;
      }, {});
      setCheckedModules(initialCheckedModules);
   }, [users]);

   const handleCheckboxChange = (moduleId, moduleName, isChecked) => {
      setCheckedModules((prevCheckedModules) => {
         if (isChecked) {
            const updatedModules = { ...prevCheckedModules, [moduleId]: moduleName };
            return updatedModules;
         } else {
            const { [moduleId]: removedModule, ...rest } = prevCheckedModules;
            return rest;
         }
      });
   };

   const saveUserPermissions = () => {
      var url = `${base_url}users/update-modules-visibility`;
      var requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },
         body: JSON.stringify({ modules: checkedModules }, null, 2)
      };
      callApi(url, requestOptions, 'save-user-permissions');
   }

   const getUsersOnRoles = (role_id) => {
      setRoleId(role_id)
      setUserWiseModule([])
      setSavePermBtn(true)
      setIsLoading(true)
      setRoleWiseModule([])
      if (role_id !== "") {
         var url = `${base_url}users/get-users-by-role`;
         var url2 = `${base_url}users/get-role-module-access`;

         var requestOptions = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({ role_id: role_id })

         };
         var requestOptions2 = {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({ role_id: role_id })

         };

         callApi(url, requestOptions, 'get-users-by-role');
         callApi(url2, requestOptions2, 'get-module-by-role');
      }
      else {
         setUsersDropDownShow(false)
         // setUsersByRole([])
      }

   }
   useEffect(() => {
      moduleListingWithSubmenu()
   }, [])



   const handleUserWiseData = (user_id) => {
      setIsLoading(true)
      var url = `${base_url}users/get-user-module-access`;
      var requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },
         body: JSON.stringify({ user_id: user_id })

      };
      callApi(url, requestOptions, 'user-wise-module');
   }

   const moduleListingWithSubmenu = () => {
      var url = `${base_url}users/module-listing-with-submenu`;
      var requestOptions = {
         method: 'GET',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },
      };
      callApi(url, requestOptions, 'module-listing-submenu');
   }

   const callApi = async (url, requestOptions, flag = '', type = '') => {

      await fetch(url, requestOptions).then(res => res.json()).then(data => {
         switch (flag) {

            case 'get-users':
               setUsers(data)
               if (data?.status === 200) {
                  console.log("YTTTTTTTT", data?.data);
               }
               break;
            case 'save-user-permissions':
               if (data?.status === 200) {
                  SuccessNotify("Module Permissions Updated Successfully")
               }
               break;
            case 'get-roles':
               if (data?.status === 200) {
                  setAllRoles(data?.data)
                  setMatchModules(data?.data)

               }
               break;

            case 'user-wise-module':

               if (data?.status === 200) {
                  console.log("setUserWiseModule", data?.data);
                  setUserWiseModule(data?.data)

                  if (data?.data?.ModuleAccess !== "" && data?.data?.ModuleAccess !== undefined && data?.data?.ModuleAccess?.length > 0) {
                     setOnlyForModuleCheckCond(data?.data)
                     setIsLoading(false)
                  }
                  else {
                     //setOnlyForModuleCheckCond(roleWiseModule)
                     var url2 = `${base_url}users/get-role-module-access`;

                     var requestOptions = {
                        method: 'POST',
                        headers: {
                           'Content-Type': 'application/json',
                           'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                        },
                        body: JSON.stringify({ role_id: roleId })

                     };

                     callApi(url2, requestOptions, 'get-module-by-role');
                     
                  }
                  
               }
               setIsLoading(false)
               break;


            case 'get-users-by-role':
               if (data?.status === 200) {
                  setUsersDropDownShow(true)
                  setUsersByRole(data?.data)

               }
               break;
            case 'get-module-by-role':

               if (data?.status === 200) {
                  setIsLoading(false)
                  setRoleWiseModule(data?.data)
                  setOnlyForModuleCheckCond(data?.data)
                  setUserModuleAccess("")
                  console.log("DFFDFDFDD", data?.data);
                  console.log("createSubmoduleData", createSubmoduleData);

                  // const updatedData = createSubmoduleData.map(item => {
                  //    const foundModule = data?.data?.module_name?.find(module => module.ModuleName === item.ModuleName && module?.visibility === "yes");

                  //    console.log('Found module:456', foundModule);
                  //    const visibility = foundModule?.visibility === "yes" ? 'yes' : 'no';
                  //    return { ...item, visibility };
                  // });
                  // console.log("updatedDataVVVVVVVVV", updatedData);

                  // setCreateSubmoduleData(updatedData)
               }
               break;


            case 'module-listing-submenu':
               setModuleSubmenu(data)

               const uniqueWithSubmenu = new Set();
               const uniqueWithoutSubmenu = new Set();

               data?.forEach(i => {
                  if (i?.subMenu === "True" && i?.subMenuName?.length > 0) {
                     if(i?.subMenuName?.includes("DFR")){
                        i?.subMenuName?.splice(i?.subMenuName?.indexOf("DFR"),1)
                     }
                     uniqueWithSubmenu.add(JSON.stringify(i)); // Using JSON.stringify to compare objects
                  } else {
                     uniqueWithoutSubmenu.add(JSON.stringify(i)); // Using JSON.stringify to compare objects
                  }
               });

               // Convert sets back to arrays of unique objects
               const withSubmenu = [...uniqueWithSubmenu].map(obj => JSON.parse(obj));
               const withoutSubmenu = [...uniqueWithoutSubmenu].map(obj => JSON.parse(obj));

               // Concatenate arrays and set state
               const uniqueModules = [...withSubmenu, ...withoutSubmenu];
               console.log("withSubmenuwithSubmenu", uniqueModules);
               setAllModulesSerialWise(uniqueModules);
               setCreateSubmoduleData(data)
               setAddRemoveSubmodule(data)
               setAllModulesForRoles(data)
               setUpdateAllRoles(data)

               if (data?.status === 200) {

               }
               break;
            case 'user-module-access':
               if (data?.status === 200) {
                  SuccessNotify("User Module Access Updated Successfully")
               }
               break;
            case 'role-wise-module-access':
               if (data?.status === 200) {
                  SuccessNotify("User Module Access Updated Successfully")
               }
               break;


         }
      }).catch((err) => {
         ErrorNotify("Something went wrong, Please contact to Administrator !", {
            icon: "error",
         });
      })
   }




   //    const handleModule = (moduleItem, isChecked) => {
   //       setCreateSubmoduleData(prevModules => {
   //           return prevModules.map(module => {
   //               if (module.ModuleName === moduleItem) {
   //                   // Update visibility based on the isChecked value
   //                   module.visibility = isChecked ? 'yes' : 'no';
   //               }
   //               return module;
   //           });
   //       });
   //   };




   const handleModule = (moduleItem, isChecked) => {
      const updatedData = createSubmoduleData.map(item => {
         if (item.ModuleName === moduleItem) {
            return { ...item, visibility: isChecked ? 'yes' : 'no' };
         }
         return item;
      });

      setCreateSubmoduleData(updatedData); // Assuming setCreateSubmoduleData is a function to update createSubmoduleData state

      // After updating, you can do something with the modified data if needed
      console.log('Updated createSubmoduleData:', updatedData);
      // Or trigger any other action needed with the updated data
   };







   const defaultCheckedSubmodules = moduleSubmenu?.reduce((checkedSubmodules, moduleItem) => {
      if (moduleItem.subMenuName.length > 0) {
         checkedSubmodules[moduleItem.ModuleName] = moduleItem.subMenuName;
      }
      return checkedSubmodules;
   }, {});
   useEffect(() => {
      setCheckedSubmodules(defaultCheckedSubmodules)
   }, [moduleSubmenu])
   console.log('defaultCheckedSubmodules:', defaultCheckedSubmodules);
   // setCheckedSubmodules(defaultCheckedSubmodules)
   const handleSubModule = (moduleName, submenu, isChecked) => {
      console.log("moduleName, submenu, isChecked", moduleName, submenu, isChecked);


      setCheckedSubmodules(prevState => {
         const updatedState = { ...prevState };

         if (isChecked) {
            updatedState[moduleName] = [...(updatedState[moduleName] || []), submenu];
         } else {
            updatedState[moduleName] = (updatedState[moduleName] || []).filter(item => item !== submenu);
         }

         const updatedCreateSubmoduleData = createSubmoduleData?.map(module => {
            console.log("UIUIUIIIUUI", module.ModuleName === moduleName);
            if (module.ModuleName === moduleName) {
               const updatedSubMenu = updatedState[moduleName];
               console.log("updatedSubMenu", updatedSubMenu);
               return {
                  ...module,
                  subMenuName: updatedSubMenu,
               };
            }
            return module;
         });

         console.log("Updated createSubmoduleData", updatedCreateSubmoduleData);

         // Update states with the modified data
         setCreateSubmoduleData(updatedCreateSubmoduleData);


         return updatedState;
      });

      if (roleWiseModule?.module_name?.length > 0) {
         console.log("RRRRRRRRRRRRRRR");
         setRoleWiseModule(prevState => {
            const updatedModuleAccess = [prevState]?.module_name?.map(module => {
               if (module.ModuleName === moduleName) {
                  let updatedSubMenuName;
                  if (isChecked) {
                     // Add the submenu item if isChecked is true
                     updatedSubMenuName = [...module.subMenuName, submenu];
                  } else {
                     // Remove the submenu item if isChecked is false
                     updatedSubMenuName = module.subMenuName.filter(item => item !== submenu);
                  }
                  return {
                     ...module,
                     subMenuName: updatedSubMenuName
                  };
               }
               return module;
            });
            return { ...prevState, module_name: updatedModuleAccess };
         });

      }
      // else {
      //    console.log("MMMMMMMMMMMMMMMMM");
      //    setUserWiseModule(prevState => {
      //       const updatedModuleAccess = prevState?.ModuleAccess?.map(module => {
      //          if (module.ModuleName === moduleName) {
      //             let updatedSubMenuName;
      //             if (isChecked) {
      //                // Add the submenu item if isChecked is true
      //                updatedSubMenuName = [...module.subMenuName, submenu];
      //             } else {
      //                // Remove the submenu item if isChecked is false
      //                updatedSubMenuName = module.subMenuName.filter(item => item !== submenu);
      //             }
      //             return {
      //                ...module,
      //                subMenuName: updatedSubMenuName
      //             };
      //          }
      //          return module;
      //       });
      //       return { ...prevState, ModuleAccess: updatedModuleAccess };
      //    });
      // }

   };










   useEffect(() => {
      console.log("checkedItems", createSubmoduleData);
   }, [createSubmoduleData])

   let rolesData = [];

   let datas = []


   const dataForRolesPerm = (role_id, role_name, module_name, submenu_name, type, isChecked) => {


      const roleIndex = rolesData.findIndex(obj => obj.hasOwnProperty(role_name));
      if (!isChecked && type === "moduleType") {
         console.log("roleIndexuyuuy", rolesData);
         if (roleIndex !== -1) {
            rolesData[roleIndex][role_name] = rolesData[roleIndex][role_name].filter(module => module.ModuleName !== module_name);
         }
      }

      if (roleIndex !== -1) {
         const role = rolesData[roleIndex][role_name];
         const moduleIndex = role.findIndex(item => item.ModuleName === module_name);

         if (moduleIndex !== -1) {
            const module = role[moduleIndex];

            if (isChecked && module.subMenuName.length === 0) {
               console.log("22222222222222");
               if (Array.isArray(submenu_name)) {
                  console.log("3333333333333333");
                  module.subMenuName = [...submenu_name];
               } else if (typeof submenu_name === 'string') {
                  console.log("44444444444444");
                  const submenuIndex = module.subMenuName.indexOf(submenu_name);
                  if (submenuIndex !== -1) {
                     console.log("555555555555555");
                     module.subMenuName.splice(submenuIndex, 1);
                  }
                  console.log("66666666666666");
                  module.subMenuName.push(submenu_name);
               }
            } else if (isChecked) {
               console.log("77777777777777777");
               if (!module.subMenuName.includes(submenu_name)) {
                  console.log("8888888888888888");
                  module.subMenuName.push(submenu_name);
               }
            }

            else {
               console.log("999999999999999");
               const submenuIndex = module.subMenuName.indexOf(submenu_name);
               if (submenuIndex !== -1) {
                  console.log("101010101010");
                  module.subMenuName.splice(submenuIndex, 1);
               }

               if (!module.subMenuName.length) {
                  console.log("1212121212121212");
                  role.splice(moduleIndex, 1); // Remove the entire module from the role
               }
            }
         } else if (!isChecked) {
            console.log("1313113131313");
            // Direct module unchecking - locate and remove the module
            const moduleToRemoveIndex = role.findIndex(item => item.ModuleName === module_name);
            if (moduleToRemoveIndex !== -1) {
               console.log("14141414141414");
               role.splice(moduleToRemoveIndex, 1);
            }
         } else {
            console.log("1515151515115");
            role.push({
               "ModuleName": module_name,
               "subMenuName": submenu_name ? [submenu_name] : []
            });
         }

         // Check if the entire module is unchecked
         //  if (!role.some(item => item.subMenuName.length !== 0)) {
         //      rolesData[roleIndex][role_name] = [];
         //  }
      } else if (isChecked) {

         console.log("1616161161616161", submenu_name);
         const newRole = {
            [role_name]: [
               {
                  "ModuleName": module_name,
                  "subMenuName": submenu_name ? [submenu_name] : []
               }
            ]
         };

         rolesData.push(newRole);

      }
      console.log("Updated Data:", rolesData);
   };

   const updateRolesPermissions = () => {
      var url = `${base_url}users/update-role-wise-module-access`;

      var requestOptions = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
         },
         body: JSON.stringify({ data: finalRolesData })

      };

      callApi(url, requestOptions, 'role-wise-module-access');
   }



   useEffect(() => {
      // Transforming the original data
      const transformed = allRoles?.map(item => {
         const newObj = {};

         // Creating the object structure as per the desired format
         newObj[item.user_role + '_' + item.role_id] = item.module_name.map(module => ({
            ModuleName: module.ModuleName,
            "AccessType": "Read|Write",
            "AccessLevel": "All",
            subMenu: module.subMenu === 'True' ? "True" : "False",
            subMenuName: module.subMenuName && module?.subMenuName?.length > 0 ? module?.subMenuName : [],
         }));

         return newObj;

      });

      const transformed2 = allRoles?.map(item => {
         const newObj = {};

         // Creating the object structure as per the desired format
         newObj[item.user_role + '_' + item.role_id] = item.module_name.map(module => ({
            ModuleName: module.ModuleName,
            userRole: item.user_role + '_' + item.role_id,
            isChecked: true,
            "AccessType": "Read|Write",
            "AccessLevel": "All",
            subMenu: module.subMenu === 'True' ? "True" : "False",
            subMenuName: module.subMenuName && module?.subMenuName?.length > 0 ? module?.subMenuName : [],
         }));

         return newObj;

      });


      // Updating the state with the transformed data
      setRolesDataUpdation(transformed);
      setRolesDataUpdation2(transformed2)
   }, [allRoles]);

   let updatedData = []
   console.log("SSSSSSSSSSSS", rolesDataUpdation);
   const moduleAddRemove = (userRole, role_id, moduleName, subModule, isChecked, type) => {

      console.log("GYGYGYGYYGGYGYYGYG", userRole, role_id, moduleName, subModule, isChecked, type);
      if (!isChecked && type === "module") {
         setMatchModules(prevModules => {
            return prevModules.map(module => {
               if (module.user_role === userRole) {
                  const updatedModule = {
                     ...module,
                     module_name: module.module_name.filter(
                        mod => mod.ModuleName !== moduleName
                     ),
                  };
                  return updatedModule;
               }
               return module;
            });
         });
         updatedData = rolesDataUpdation.map(roleData => {
            const roleName = Object.keys(roleData)[0]; // Extract the role name from the object
            console.log("roleName === userRole", roleName, userRole?.split(" "));
            if (roleName === `${userRole}_${role_id}`) {
               const updatedModules = roleData[roleName].filter(module => module.ModuleName !== moduleName);
               return { [roleName]: updatedModules };
            }
            return roleData;
         });
         console.log("UIUIUUIUIIUUIUI", updatedData);
         //setRolesDataUpdation([...updatedData])
      }
      else if (isChecked && type === "module") {
         // Add the module when isChecked is true
         setMatchModules(prevModules => {
            return prevModules.map(module => {
               if (module.user_role === userRole) {
                  const updatedModule = {
                     ...module,
                     module_name: [
                        ...module.module_name,
                        { AccessLevel: "All", AccessType: "Read|Write", ModuleName: moduleName, subMenu: subModule?.length > 0 ? "True" : "False", subMenuName: subModule, }
                     ],
                  };
                  return updatedModule;
               }
               return module;
            });
         });

         // Update rolesDataUpdation to add the module
         updatedData = rolesDataUpdation.map(roleData => {
            const roleName = Object.keys(roleData)[0];
            if (roleName === `${userRole}_${role_id}`) {
               const updatedModules = [
                  ...roleData[roleName],
                  { AccessLevel: "All", AccessType: "Read|Write", ModuleName: moduleName, subMenu: subModule?.length > 0 ? "True" : "False", subMenuName: subModule }
               ];
               return { [roleName]: updatedModules };
            }
            return roleData;
         });
         //setRolesDataUpdation([...updatedData]);
      }


      if (!isChecked && type === "subModule") {
         setMatchModules(prevModules => {
            return prevModules.map(module => {
               if (module.user_role === userRole) {
                  const updatedModule = {
                     ...module,
                     module_name: module.module_name.map(mod => {
                        if (mod.ModuleName === moduleName) {
                           return {
                              ...mod,
                              subMenuName: mod.subMenuName.filter(sub => sub !== subModule)
                           };
                        }
                        return mod;
                     })
                  };
                  console.log("aqqqqqqqqq", updatedModule);
                  return updatedModule;
               }
               return module;
            });
         });

         updatedData = rolesDataUpdation.map(roleData => {
            const roleName = Object.keys(roleData)[0]; // Extract the role name from the object
            console.log("roleName === userRole", roleName, userRole?.split(" "));
            if (roleName === `${userRole}_${role_id}`) {
               const updatedModules = roleData[roleName].map(module => {
                  // Find the module that matches the moduleName
                  if (module.ModuleName === moduleName && Array.isArray(module.subMenuName)) {
                     // Filter out the subModule from the subMenuName array
                     const updatedSubMenu = module.subMenuName.filter(sub => !subModule.includes(sub));
                     console.log("XXXXXXXXXXXXXX", updatedSubMenu);
                     return { ...module, subMenuName: updatedSubMenu };
                  }
                  return module;
               });



               return { [roleName]: updatedModules };
            }
            return roleData;
         });



         // setRolesDataUpdation([...updatedData]);
      }

      else if (isChecked && type === "subModule") {




         const updatedModules = matchModules.map(module => {
            if (module.user_role === userRole) {
               const updatedModule = {
                  ...module,
                  module_name: module.module_name.map(mod => {
                     if (mod.ModuleName === moduleName && mod.subMenu) {
                        if (!mod.subMenuName.includes(subModule)) {
                           return {
                              ...mod,
                              subMenuName: [...mod.subMenuName, subModule]
                           };
                        }
                     }
                     return mod;
                  })
               };
               return updatedModule;
            }
            return module;
         });

         setMatchModules(updatedModules);

         updatedData = rolesDataUpdation.map(roleData => {
            const roleName = Object.keys(roleData)[0];
            if (roleName === `${userRole}_${role_id}`) {
               const updatedRoleData = roleData[roleName].map(module => {
                  if (module.ModuleName === moduleName && module.subMenu) {
                     if (!module.subMenuName.includes(subModule)) {
                        return {
                           ...module,
                           subMenuName: [...module.subMenuName, subModule]
                        };
                     }
                  }
                  return module;
               });
               return { [roleName]: updatedRoleData };
            }
            return roleData;
         });


         // setRolesDataUpdation([...updatedData]);
      }













      console.log("updatedData", updatedData);
      setRolesDataUpdation(updatedData);
      console.log("matchModules123", rolesDataUpdation);
   }

   useEffect(() => {
      const transformed = matchModules?.map(item => {
         const newObj = {};

         // Creating the object structure as per the desired format
         newObj[item.user_role?.replace(" ", "_") + '_' + item.role_id] = item.module_name.map(module => ({
            ModuleName: module.ModuleName,
            "AccessType": "Read|Write",
            "AccessLevel": "All",
            subMenu: module.subMenu === 'True' ? "True" : "False",
            subMenuName: module.subMenuName && module?.subMenuName?.length > 0 ? module?.subMenuName : [],
            visibility: "yes"
         }));

         return newObj;

      });
      setFinalRolesData(transformed)
      console.log("finalRolesData", finalRolesData);
   }, [matchModules]);


   const handleModulesForUserPermissions = (module, subMenuName, subMenu, subModule, type, isChecked, whatWise, id) => {
      if (userValue === "") {
         return ErrorNotify("Please select a user first")
      }
      console.log("module,type,isChecked", module, subMenuName, subMenu, subModule, type, isChecked);
      if (type === "module" && isChecked && subModule === "" && whatWise === "roleWise") {
         // to add module
         console.log("to add module");
         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            module_name: [
               ...prevState.module_name,
               { id: id, ModuleName: module, AccessType: "Read|Write", AccessLevel: "All", subMenu: subMenu, subMenuName: subMenuName, visibility: "yes" } // Assuming module is the module name
            ]
         }));

         setRoleWiseModule(prevState => ({
            ...prevState,
            module_name: [
               ...prevState.module_name,
               { id: id, ModuleName: module, AccessType: "Read|Write", AccessLevel: "All", subMenu: subMenu, subMenuName: subMenuName, visibility: "yes" } // Assuming module is the module name
            ]
         }));




      }
      if (type === "module" && !isChecked && subModule === "" && whatWise === "roleWise") {
         // to remove module
         console.log("to remove module", onlyForModuleCheckCond?.module_name?.filter(i => i?.ModuleName !== module));
         //setOnlyForModuleCheckCond(onlyForModuleCheckCond?.module_name?.filter(i=>i?.ModuleName!==module))
         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            module_name: prevState.module_name.filter(i => i.ModuleName !== module)
         }));
      }
      if (type === "sub-module" && isChecked && subModule !== "" && whatWise === "roleWise") {
         // to add sub-module
         console.log("to add sub-module");

         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            module_name: prevState.module_name.map(item => {
               if (item.ModuleName === module) {
                  // If the module matches the specified module, update its submodule list
                  let updatedSubmenu;
                  if (item.subMenuName && isChecked) {
                     // Add the submodule to the submodule list
                     updatedSubmenu = [...item.subMenuName, subModule];
                  } else {
                     // No changes needed if there are no submodules and the checkbox is unchecked
                     updatedSubmenu = [];
                  }
                  return {
                     ...item,
                     subMenuName: updatedSubmenu
                  };
               }
               return item; // For other modules, return them unchanged
            })
         }));


         setRoleWiseModule(prevState => ({
            ...prevState,
            module_name: prevState.module_name.map(item => {
               if (item.ModuleName === module) {
                  // If the module matches the specified module, update its submodule list
                  let updatedSubmenu;
                  if (item.subMenuName && isChecked) {
                     // Add the submodule to the submodule list
                     updatedSubmenu = [...item.subMenuName, subModule];
                  } else {
                     // No changes needed if there are no submodules and the checkbox is unchecked
                     updatedSubmenu = [];
                  }
                  return {
                     ...item,
                     subMenuName: updatedSubmenu
                  };
               }
               return item; // For other modules, return them unchanged
            })
         }));

      }
      if (type === "sub-module" && !isChecked && subModule !== "" && whatWise === "roleWise") {
         // to remove sub-module
         console.log("to remove sub-module");
         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            module_name: prevState.module_name.map(item => {
               if (item.ModuleName === module && item.subMenuName && item.subMenuName.includes(subModule)) {
                  // Remove the specified submodule from the submodule list
                  return {
                     ...item,
                     subMenuName: item.subMenuName.filter(name => name !== subModule)
                  };
               }
               return item; // For other modules, return them unchanged
            })
         }));


      }




      if (type === "module" && isChecked && subModule === "" && whatWise === "userWise") {
         // to add module
         console.log("to add module12");
         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            ModuleAccess: [
               ...prevState.ModuleAccess,
               { id: id, ModuleName: module, AccessType: "Read|Write", AccessLevel: "All", subMenu: subMenu, subMenuName: subMenuName, visibility: "yes" } // Assuming module is the module name
            ]
         }));

         setUserWiseModule(prevState => ({
            ...prevState,
            ModuleAccess: [
               ...prevState.ModuleAccess,
               { id: id, ModuleName: module, AccessType: "Read|Write", AccessLevel: "All", subMenu: subMenu, subMenuName: subMenuName, visibility: "yes" } // Assuming module is the module name
            ]
         }));


      }
      if (type === "module" && !isChecked && subModule === "" && whatWise === "userWise") {
         // to remove module
         // console.log("to remove module",onlyForModuleCheckCond?.ModuleAccess?.filter(i=>i?.ModuleName!==module));
         //setOnlyForModuleCheckCond(onlyForModuleCheckCond?.module_name?.filter(i=>i?.ModuleName!==module))
         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            ModuleAccess: prevState.ModuleAccess.filter(i => i.ModuleName !== module)
         }));
      }
      if (type === "sub-module" && isChecked && subModule !== "" && whatWise === "userWise") {
         // to add sub-module
         console.log("to add sub-module");

         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            ModuleAccess: prevState.ModuleAccess.map(item => {
               if (item.ModuleName === module) {
                  // If the module matches the specified module, update its submodule list
                  let updatedSubmenu;
                  if (item.subMenuName && isChecked) {
                     // Add the submodule to the submodule list
                     updatedSubmenu = [...item.subMenuName, subModule];
                  } else {
                     // No changes needed if there are no submodules and the checkbox is unchecked
                     updatedSubmenu = [];
                  }
                  return {
                     ...item,
                     subMenuName: updatedSubmenu
                  };
               }
               return item; // For other modules, return them unchanged
            })
         }));


         setUserWiseModule(prevState => ({
            ...prevState,
            ModuleAccess: prevState.ModuleAccess.map(item => {
               if (item.ModuleName === module) {
                  // If the module matches the specified module, update its submodule list
                  let updatedSubmenu;
                  if (item.subMenuName && isChecked) {
                     // Add the submodule to the submodule list
                     updatedSubmenu = [...item.subMenuName, subModule];
                  } else {
                     // No changes needed if there are no submodules and the checkbox is unchecked
                     updatedSubmenu = [];
                  }
                  return {
                     ...item,
                     subMenuName: updatedSubmenu
                  };
               }
               return item; // For other modules, return them unchanged
            })
         }));

      }
      if (type === "sub-module" && !isChecked && subModule !== "" && whatWise === "userWise") {
         // to remove sub-module
         console.log("to remove sub-module");
         setOnlyForModuleCheckCond(prevState => ({
            ...prevState,
            ModuleAccess: prevState.ModuleAccess.map(item => {
               if (item.ModuleName === module && item.subMenuName && item.subMenuName.includes(subModule)) {
                  // Remove the specified submodule from the submodule list
                  return {
                     ...item,
                     subMenuName: item.subMenuName.filter(name => name !== subModule)
                  };
               }
               return item; // For other modules, return them unchanged
            })
         }));


      }
      // console.log("onlyForModuleCheckCond",onlyForModuleCheckCond);
      console.log("SSSSSSSSSSFFFFF", roleWiseModule, onlyForModuleCheckCond);
      // console.log("handleModulesForUserPermissions", createSubmoduleData);

   }



   return (

      <main>
         <ToastContainer />
         <div class="main-contents">
            <div class="d-none" id="success_section">
               <div class="page_error success mb-12">
                  <div class="alert alert-info text-center" id="success_msg"></div>
               </div>
            </div>
            <div class="d-none" id="failure_section">
               <div class="page_error failure mb-12">
                  <div class="alert alert-info text-center" id="failure_msg">
                  </div>
                  <button class="remove_error_msg"></button>
               </div>
            </div>
            <div class="ip-header sc-head sc-collapse">
               <div class="container-fluid">
                  <div class="sc-header d-flex justify-content-between align-items-center">
                     <h3 class="sc-title d-flex align-items-center"><span>Role & Module Permission</span></h3>
                  </div>
               </div>
            </div>
            <nav class="ip-breadcrumb" aria-label="breadcrumb">
               <ul class="breadcrumb mb-0">
                  <li class="breadcrumb-item active"><span>Role & Module Permission</span></li>
               </ul>
            </nav>
            <div class="ip-section">
               <div class="container-fluid">
                  <div class="ip-box">
                     <div class="box-contents mb-0">
                        <div class="ip-ats accordions mrp mb-0">
                           <div class="ats-body">
                              <div class="ats-contents mb-3" id="cat1">
                                 <button type="button" class="ats-link" data-target="#ats_accordion_01"><span class="ats-link-text">Application Permission</span></button>
                                 <div id="ats_accordion_01" class="ats-accordion-contents px-0">
                                    <div class="ftab-section-body">
                                       <div className='row'>


                                          {
                                             users?.length > 0 ?
                                                users?.map((i) => (
                                                   <div className='col-md-2 col-lg-2 col-sm-2' key={i?.id}>
                                                      <input
                                                         type='checkbox'
                                                         checked={checkedModules[i?.id] !== undefined}
                                                         onChange={(e) =>
                                                            handleCheckboxChange(i?.id, i?.module, e?.target?.checked)
                                                         }
                                                      />{' '}
                                                      {i?.module}
                                                   </div>
                                                ))
                                                :
                                                <div class="spinner-border" role="status" style={{ marginTop: "120px", marginLeft: "500px" }}>
                                                   <span class="sr-only">Loading...</span>
                                                </div>
                                          }


                                       </div>
                                       <div class="mt-4 text-right my-4">
                                          <button type="button" onClick={() => saveUserPermissions()} class="btn btn-md btn-primary px-4">Save Permissions

                                             {/* {loading ? <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                             </div> :
                                             ""} */}
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="ats-contents accordian-switch-content" id="cat2">
                                 <button type="button" class="ats-link" data-target="#ats_accordion_02">
                                    <span class="ats-link-text">Role Permission</span>
                                 </button>
                                 <div id="ats_accordion_02" class="ats-accordion-contents px-0">
                                    <div class="ftab-section-body">
                                       <div className='row'>
                                          <div className='col-md-12 col-lg-12 col-sm-12'>
                                             <div style={{ display: 'flex' }}>
                                                {console.log("HUHUHUHHUUH", updateAllRoles)}
                                                {
                                                   updateAllRoles?.length > 0 ?
                                                      allRoles?.map((role, index) =>
                                                      (
                                                         <div key={index} style={{ marginRight: '20px' }}>
                                                            <div style={{ backgroundColor: "#047CCC", padding: "6px", paddingLeft: "9px", paddingBottom: "2px", color: "white", borderRadius: '5px' }}>
                                                               <h5 style={{ fontWeight: '600' }}>{role?.user_role}</h5>
                                                            </div>
                                                            {
                                                               updateAllRoles?.map((moduleData, indx) => {
                                                                  return <ul style={{ lineHeight: "2" }} className='mx-2 my-4'>

                                                                     <li key={indx}>
                                                                        <span style={{ fontWeight: 'bold', fontSize: "13px" }}>
                                                                           <input type='checkbox'
                                                                              checked={matchModules?.filter(j => j?.user_role === role?.user_role)[0]?.module_name?.map(k => k?.ModuleName)?.includes(moduleData?.ModuleName)}
                                                                              onChange={(e) => moduleAddRemove(role?.user_role, role?.role_id, moduleData?.ModuleName, moduleData?.subMenuName, e.target.checked, "module")}
                                                                           />
                                                                           {moduleData?.ModuleName}</span>
                                                                        {moduleData?.subMenuName && (
                                                                           <ul>
                                                                              {moduleData?.subMenuName?.map((subMenu, subIdx) => {
                                                                                 const isChecked = matchModules?.filter(j => j?.user_role === role?.user_role)[0]?.module_name
                                                                                    ?.some(k => k?.subMenuName?.includes(subMenu) && k?.ModuleName === moduleData?.ModuleName);

                                                                                 return (
                                                                                    <li key={subIdx}>
                                                                                       <input
                                                                                          type='checkbox'
                                                                                          disabled={
                                                                                             matchModules?.filter(j => j?.user_role === role?.user_role)[0]?.module_name
                                                                                                ?.map(k => k?.ModuleName)
                                                                                                ?.includes(moduleData?.ModuleName)
                                                                                                ? false
                                                                                                : true
                                                                                          }
                                                                                          checked={isChecked}
                                                                                          onChange={(e) =>
                                                                                             moduleAddRemove(
                                                                                                role?.user_role,
                                                                                                role?.role_id,
                                                                                                moduleData?.ModuleName,
                                                                                                subMenu,
                                                                                                e.target.checked,
                                                                                                "subModule"
                                                                                             )
                                                                                          }
                                                                                       />
                                                                                       {subMenu}
                                                                                    </li>
                                                                                 );
                                                                              })}
                                                                           </ul>
                                                                        )}

                                                                     </li>

                                                                  </ul>
                                                               })
                                                            }


                                                         </div>
                                                      ))
                                                      :
                                                      <div class="spinner-border" role="status" style={{ marginTop: "120px", marginLeft: "500px" }}>
                                                         <span class="sr-only">Loading...</span>
                                                      </div>
                                                }

                                             </div>
                                          </div>



                                       </div>

                                       <div className='row my-4'>
                                          <div className='col-md-12 col-lg-12 col-sm-12'>
                                             <div>

                                             </div>
                                             <div class="mt-4 text-right">
                                                <button type="button" onClick={() => updateRolesPermissions()} class="btn btn-md btn-primary px-4">Save Permissions</button>
                                             </div>
                                          </div>
                                          {/* <div className='col-md-9 col-lg-9 col-sm-9'>
                                                            
                                                   {allRoles?.map((i, index) => (
                                                      <span key={index} style={{ marginRight: '14px', color: "white", fontSize: "13px" }}>
                                                       <input type='checkbox'/> 
                                                      </span>
                                                   ))}
                                                
                                                            
                                             </div> */}


                                       </div>



                                    </div>
                                 </div>
                              </div>
                              <div class="ats-contents accordian-switch-content mb-0" id="cat3" >
                                 <button type="button" class="ats-link" data-target="#ats_accordion_03" >
                                    <span class="ats-link-text">User Permission</span>
                                 </button>
                                 <div id="ats_accordion_03" class="ats-accordion-contents px-0" style={{ height: "115vh" }}>

                                    <div class="ftab-section-body">
                                       <div class="permissionBox">
                                          <div class="inputGroup px-0">
                                             <div class="row mx-0">
                                                <div class="col-sm-6 col-md-4 col-xl-3 form-group px-0 mb-0">
                                                   <Select
                                                      options={[
                                                         { value: '', label: 'Select...' }, // Blank option added directly within the array
                                                         ...(allRoles?.map((item) => ({
                                                            value: item?.role_id,
                                                            label: item?.user_role
                                                         })) || []) // Use empty array if allRoles is falsy to prevent errors
                                                      ]}
                                                      placeholder="Select Role"
                                                      styles={{
                                                         control: (provided) => ({
                                                            ...provided,
                                                            borderRadius: '100px',
                                                            boxShadow: 'none', // Remove the blue box shadow
                                                            borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                            '&:hover': {
                                                               borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                            },
                                                            '&:focus': {
                                                               outline: 'none', // Remove the blue outline on focus
                                                               borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                               boxShadow: 'none', // Remove the blue box shadow on focus
                                                            },
                                                            '&:active': {
                                                               borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                               boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                            },
                                                         }),
                                                         indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                         option: (provided, state) => ({
                                                            ...provided,
                                                            background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                            color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                         }),

                                                      }}
                                                      onChange={(e) => [getUsersOnRoles(e?.value), setUserValue("")]}
                                                   />
                                                </div>
                                                {
                                                   usersDropDownShow &&

                                                   <div class="col-sm-6 col-md-4 col-xl-3 form-group px-0 mb-0 mx-4">
                                                      <Select

                                                         options={[
                                                            { value: '', label: 'Select...' }, // Blank option added directly within the array
                                                            ...(usersByRole?.map((item) => ({
                                                               value: item?.user_id,
                                                               label: item?.display_name
                                                            })) || []) // Use empty array if allRoles is falsy to prevent errors
                                                         ]}
                                                         value={userValue !== '' ? usersByRole?.find((option, index) => option.value === userValue) : ''}
                                                         placeholder="Select User"
                                                         styles={{
                                                            control: (provided) => ({
                                                               ...provided,
                                                               borderRadius: '100px',
                                                               boxShadow: 'none', // Remove the blue box shadow
                                                               borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                               '&:hover': {
                                                                  borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                               },
                                                               '&:focus': {
                                                                  outline: 'none', // Remove the blue outline on focus
                                                                  borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                                  boxShadow: 'none', // Remove the blue box shadow on focus
                                                               },
                                                               '&:active': {
                                                                  borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                                  boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                               },
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                            option: (provided, state) => ({
                                                               ...provided,
                                                               background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                               color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                            }),

                                                         }}
                                                         onChange={(e) => [handleUserWiseData(e?.value), setUserValue(e?.value), setModuleUser(e?.value), setSavePermBtn(false)]}
                                                      />

                                                   </div>
                                                }

                                             </div>
                                          </div>
                                       </div>
                                       <div class="permissionBox my-4">








                                          {/* {
                                             usersByRole?.length>0 && userModuleAccess==="" &&moduleSubmenu?.length > 0 ?
                                             <div className='row'>
                                                <div className='col-md-2 col-lg-2 col-sm-2'>

                                                   <p style={{ fontSize: "17px", fontWeight: "bold", marginLeft: "7px" }} class="sc-title d-flex align-items-center"><span>Modules</span></p>
                                                   

                                                </div>

                                             </div>
                                             :
                                             <p style={{textAlign:'center'}}>No Module Found</p>
                                          }

                                          {
                                          
                                          usersByRole?.length>0 && userModuleAccess==="" && moduleSubmenu?.map((moduleItem, index) => (
                                             <>


                                                <button class="btn btn-light btn-sm" style={{ borderRadius: "5px", backgroundColor: "#E2E6EA", fontSize: "13px" }} type="button" data-toggle="collapse" data-target={`#collapseExample${index}`} aria-expanded="false" aria-controls={`collapseExample${index}`}>
                                                   {console.log("TTTTTTTTTTTTT",  roleWiseModule)}
                                                   <input type='checkbox' checked={createSubmoduleData[index]?.visibility === "yes"} onChange={(e) => handleModule(moduleItem?.ModuleName, e.target.checked)} /> {moduleItem?.ModuleName} {' '}{moduleItem?.subMenuName?.length > 0 && <i style={{ marginLeft: "20px" }} class="las la-chevron-down"></i>}
                                                </button>

                                                {moduleItem?.subMenuName?.length > 0 && ( // Check if subMenuName exists and has elements
                                                   <div class="collapse" id={`collapseExample${index}`}>
                                                      <div class="card card-body" style={{ marginBottom: "10px" }}>
                                                         <div className='row'>
                                                            {moduleItem?.subMenuName?.map((submenu, subIndex) => {
                                                               const moduleExists = roleWiseModule?.module_name?.length>0 ?
                                                               roleWiseModule?.module_name?.find(module => module.ModuleName === moduleItem?.ModuleName)
                                                               :userWiseData?.ModuleAccess!==""&&userWiseData?.ModuleAccess?.find(module => module.ModuleName === moduleItem?.ModuleName)

                                                               const submenuExists = moduleExists?.subMenuName?.includes(submenu);
                                                               const submoduleExists = createSubmoduleData.find(submoduleData => submoduleData.ModuleName === moduleItem?.ModuleName && submoduleData.subMenuName.includes(submenu));

                                                               console.log("RFRRRRRRRRRRRF",roleWiseModule?.length);
                                                               
                                                               return <div className='col-md-2 col-lg-2 col-sm-2' key={subIndex}>
                                                               
                                                                  {console.log("LLLLLLLLLLLLLLL", submenu,moduleExists,submenuExists)}
                                                                  <span style={{ marginLeft: "20px", fontWeight: "550px" }}>
                                                                     <input type='checkbox'
                                                                        checked={submenuExists}
                                                                        disabled={createSubmoduleData[index]?.visibility === "yes" ? false : true}
                                                                        onChange={(e) => handleSubModule(moduleItem?.ModuleName, submenu, e.target.checked)}
                                                                     /> {submenu}
                                                                  </span>
                                                               </div>
                                                            })}
                                                         </div>
                                                      </div>
                                                   </div>
                                                )}
                                             </>
                                          ))} */}

                                          {
                                             !userWiseModule?.ModuleAccess || userWiseModule?.ModuleAccess === "" || userWiseModule?.ModuleAccess?.length === 0 ?
                                                Object.keys(roleWiseModule)?.length > 0 && roleWiseModule?.module_name?.length > 0 && !isLoading ?
                                                   <>
                                                      <hr />
                                                      <h3 className='my-4' style={{ textAlign: 'center' }}>Modules</h3>
                                                      <div className='row mx-4 my-4'>
                                                         {
                                                            allModulesSerialWise?.map((module, moduleIndex) => {

                                                               return <div className='col-md-3 col-lg-3 col-sm-3'>
                                                                  <span className='module-name'><input type='checkbox'
                                                                     checked={
                                                                        onlyForModuleCheckCond?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length > 0 &&
                                                                        roleWiseModule?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length > 0
                                                                     }
                                                                     onChange={(e) => handleModulesForUserPermissions(module?.ModuleName, module?.subMenuName, module?.subMenu, "", "module", e.target.checked, "roleWise", module?.id)}

                                                                  />
                                                                     {module?.ModuleName}</span>
                                                                  {
                                                                     module?.subMenuName?.length > 0 && module?.subMenuName !== undefined && module?.subMenuName?.map((subModule, subModuleIndex) => {
                                                                        return <ul className='sub-module-name'>
                                                                           <li key={subModuleIndex}><input type='checkbox'
                                                                              checked={
                                                                                 onlyForModuleCheckCond?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName && findModuleName?.subMenuName?.includes(subModule))?.length > 0 &&
                                                                                 roleWiseModule?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName && findModuleName?.subMenuName?.includes(subModule))?.length > 0
                                                                              }
                                                                              disabled={onlyForModuleCheckCond?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length === 0 ? true : false}
                                                                              onChange={(e) => handleModulesForUserPermissions(module?.ModuleName, module?.subMenuName, module?.subMenu, subModule, "sub-module", e.target.checked, "roleWise", module?.id)}
                                                                           />
                                                                              {subModule}</li>
                                                                        </ul>
                                                                     })
                                                                  }
                                                                  <hr />

                                                               </div>
                                                            })
                                                         }

                                                      </div>

                                                   </>
                                                   :

                                                   isLoading ?
                                                      <div class="spinner-border" role="status" style={{ marginTop: "120px", marginLeft: "500px" }}>
                                                         <span class="sr-only">Loading...</span>
                                                      </div>
                                                      : <span style={{ textAlign: 'center' }}>No Module Found</span>

                                                :
                                                <>
                                                   {
                                                      isLoading ?
                                                         <div class="spinner-border" role="status" style={{ marginTop: "120px", marginLeft: "500px" }}>
                                                            <span class="sr-only">Loading...</span>
                                                         </div>
                                                         :
                                                         <>
                                                            <hr />
                                                            <h3 className='my-4' style={{ textAlign: 'center' }}>Modules</h3>
                                                            <div className='row mx-4 my-4'>
                                                               {
                                                                  allModulesSerialWise?.map((module, moduleIndex) => {

                                                                     return <div className='col-md-3 col-lg-3 col-sm-3'>
                                                                        <span className='module-name'><input type='checkbox'
                                                                           checked={
                                                                              onlyForModuleCheckCond?.module_name ?
                                                                                 onlyForModuleCheckCond?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length > 0
                                                                                 : onlyForModuleCheckCond?.ModuleAccess?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length > 0
                                                                                 &&

                                                                                 userWiseModule?.ModuleAccess?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length > 0
                                                                           }


                                                                           onChange={(e) => handleModulesForUserPermissions(module?.ModuleName, module?.subMenuName, module?.subMenu, "", "module", e.target.checked, "userWise", module?.id)}
                                                                        />
                                                                           {module?.ModuleName}</span>
                                                                        {
                                                                           module?.subMenuName?.length > 0 && module?.subMenuName !== undefined && module?.subMenuName?.map((subModule, subModuleIndex) => {
                                                                              return <ul className='sub-module-name'>
                                                                                 <li key={subModuleIndex}><input type='checkbox'
                                                                                    checked={
                                                                                       onlyForModuleCheckCond?.module_name ?
                                                                                          onlyForModuleCheckCond?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName && findModuleName?.subMenuName?.includes(subModule))?.length > 0
                                                                                          : onlyForModuleCheckCond?.ModuleAccess?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName && findModuleName?.subMenuName?.includes(subModule))?.length > 0
                                                                                          &&

                                                                                          userWiseModule?.ModuleAccess?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName && findModuleName?.subMenuName?.includes(subModule))?.length > 0
                                                                                    }
                                                                                    disabled={
                                                                                       onlyForModuleCheckCond?.module_name ?
                                                                                          onlyForModuleCheckCond?.module_name?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length === 0 ? true : false
                                                                                          :
                                                                                          onlyForModuleCheckCond?.ModuleAccess?.filter(findModuleName => findModuleName?.ModuleName === module?.ModuleName)?.length === 0 ? true : false

                                                                                    }

                                                                                    onChange={(e) => handleModulesForUserPermissions(module?.ModuleName, module?.subMenuName, module?.subMenu, subModule, "sub-module", e.target.checked, "userWise", module?.id)}
                                                                                 />
                                                                                    {subModule}</li>
                                                                              </ul>
                                                                           })
                                                                        }
                                                                        <hr />

                                                                     </div>
                                                                  })
                                                               }

                                                            </div>
                                                         </>
                                                   }
                                                </>

                                          }






                                          {/* <div>
                                             {moduleSubmenu?.map((moduleItem, index) => (
                                                <div class="accordion" id={`module_accordion_${index}`} key={moduleItem?.id}>
                                                   <button
                                                      type="button"
                                                      class="ats-link"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#module_accordion_content_${index}`}
                                                   >
                                                      <span class="ats-link-text">{moduleItem?.module}</span>
                                                   </button>
                                                   <div id={`module_accordion_content_${index}`} class="ats-accordion-contents collapse">
                                                      <div class="ftab-section-body">
                                                         <div class="permissionBox">
                                                            <div class="inputGroup px-0">
                                                               <div class="row mx-0">
                                                                  <div class="col-sm-12 form-group px-0 mb-0">
                                                                     <ul id={`subMenuList_${index}`} class="subMenuList">
                                                                        {moduleItem?.subMenu?.map((submenu, subIndex) => (
                                                                           <li key={`${moduleItem?.id}-${subIndex}`}>
                                                                              {submenu}
                                                                           </li>
                                                                        ))}
                                                                     </ul>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             ))}
                                          </div> */}

                                          {/* 



                                          <ul>
                                             {moduleSubmenu?.map((moduleItem) => (
                                                <li key={moduleItem?.id}>
                                                   <input type="checkbox" id={moduleItem?.id} /><span style={{ fontSize: "14px", fontWeight: "550", marginLeft: "5px" }}>{moduleItem?.module}</span>

                                                   {moduleItem?.subMenu?.length > 0 && (
                                                      <ul>
                                                         {moduleItem?.subMenu?.map((submenu, index) => (
                                                            <li key={index}>
                                                               <input type="checkbox" id={`${moduleItem.id}-${index}`} />
                                                               <h6 style={{ marginLeft: '20px' }}>{submenu}</h6>
                                                            </li>
                                                         ))}
                                                      </ul>
                                                   )}
                                                </li>
                                             ))}
                                          </ul> */}
                                       </div>

                                       <div class="mt-4 text-right">
                                          <button type="button" disabled={savePermBtn} onClick={() => updateUserModule()} class="btn btn-md btn-primary px-4">Save Permissions</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </main>



   )
}

export default UsersPermissions