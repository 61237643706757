import React, { useEffect, useState } from "react";
import CustomHierarchy from "../../shared/CustomHierarchy";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import Sidebar from "../../shared/Sidebar";
import swal from 'sweetalert';
import $ from 'jquery';
import XLSX from "xlsx";

import { CSVLink, CSVDownload } from "react-csv";
import DownloadDFR from "./DownloadDFR";
import { SuccessNotify, ErrorNotify } from "../../shared/Toast";
import { TabTitle } from "../../shared/GenralFunctioin/TableTitle";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const base_url = process.env.REACT_APP_AUTH_BASE_URL;
var csvReport = {
    data: [],
};
// const heraderData = [];
let headers = [];

const DFR = () => {
    TabTitle('DFR :: NEQQO');


    const [custom_1, setCustom1] = useState([]);
    const [custom_2, setCustom2] = useState([]);
    const [custom_3, setCustom3] = useState([]);
    const [custom_4, setCustom4] = useState([]);

    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');
    const [date_type, setDateType] = useState('call_date');

    const [affiliation, setAffiliation] = useState('');
    const [form_name, setFormName] = useState('');
    const [formDataDfr, setFormDataDfr] = useState([]);
    const [downloading, setDownloading] = useState(false);



    const [category, setCategory] = useState('');
    const [attribute, setAttribute] = useState('');

    const [formData, setFormData] = useState([]);
    const [formVersion, setFormVersion] = useState('');
    const [hierarchyForDFR, setHierarchyForDFR] = useState([]);
    const [csvReportData, setCsvReport] = useState({ data: [] });
    const [isSet, setDataIsSet] = useState(false);
   
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");

        }

    }, []);
    useEffect(() => {
        
        var url = `${base_url}raw-data`;
        //console.log("form_name",form_name + '||' + formDataDfr?.filter(i=>i?.form_name==="form_name"));
        let abc = formDataDfr?.filter(i => i?.form_name === form_name)
        console.log("abcabc", formData?.filter(i => i?.form_name === form_name));
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "from_date": from_date && from_date,
                "to_date": to_date && to_date,
                "date_type": date_type && date_type,
                "custom1": custom_1 && custom_1[custom_1?.length - 1],
                "custom2": custom_2 && custom_2[custom_2?.length - 1],
                "custom3": custom_3 && custom_3[custom_3?.length - 1],
                "custom4": custom_4 && custom_4[custom_4?.length - 1],
                // "form_name": "clink_updated_form||6",
                "form_name": `${form_name}||${formData?.filter(i => i?.form_name === form_name)[0]?.form_version}`,
                // "affiliation": affiliation
            })
        };
        // if(form_name!==""){
        //     csvReport.filename = form_name + '||' + formData[0].form_version;
        //     callApi(url, requestOptions, 'download_data');
        // }
        if (form_name !== "") {
            const newCsvReport = {
                ...csvReport,
                filename: form_name + '||' + formData[0].form_version
            };
            setCsvReport(newCsvReport);
            callApi(url, requestOptions, 'set_form_data_for_download');
        }
    }, [form_name]);

    const custom_1_val = (val) => {
        setCustom1([])
        setCustom1(prevState => [
            ...prevState,
            val
        ]);
        // setCustom1(val);
    }

    const custom_2_val = (val) => {
        setCustom2(prevState => [
            ...prevState,
            val
        ]);
        // setCustom2(val);
    }
    const custom_3_val = (val) => {
        setCustom3(prevState => [
            ...prevState,
            val
        ]);
        // setCustom3(val);
    }
    const custom_4_val = (data, val) => {
        setFormData(data);
        setCustom4(prevState => [
            ...prevState,
            val
        ]);
        return
        var url = `${base_url}calls/get-form-name`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                c1: custom_1.toString(),
                c2: custom_2.toString(),
                c3: custom_3.toString(),
                c4: val.toString(),
                proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""

            })
        };
        callApi(url, requestOptions, 'form_name_data');
        // setCustom4(val);
    }

    const downloadDFR = () => {
        setDownloading(true)
        console.log('custom_1--------------', custom_1)
        // return ErrorNotify("LLLLLLLLL")
        // return
        if (from_date === '') {
            ErrorNotify('Please select From Date');
            return
        }
        if (to_date === '') {
            ErrorNotify('Please select To Date');
            return
        }

        if (date_type === '') {
            ErrorNotify('Please enter date type');
            return;
        }

        if (custom_1?.length === 0 || custom_1[0] === undefined || custom_1[0] === '') {
            console.log(custom_1[0]);
            ErrorNotify(`Please select ${hierarchyForDFR?.c1}`);
            return;
        }
        if (custom_2?.length === 0 || custom_2[0] === undefined || custom_2[0] === '') {
            ErrorNotify(`Please select ${hierarchyForDFR?.c2}`);
            return;
        }
        if (custom_3?.length === 0 || custom_3[0] === undefined || custom_3[0] === '') {
            ErrorNotify(`Please select ${hierarchyForDFR?.c3}`);
            return;
        }
        if (custom_4?.length === 0 || custom_4[0] === undefined || custom_4[0] === '') {
            ErrorNotify(`Please select ${hierarchyForDFR?.c4}`);
            return;
        }
        // if (affiliation === '') {
        //     swal('Please select affiliation value');
        //     return;
        // }
        if (form_name === '') {
            ErrorNotify('Please select form name');
            return;
        }
        if (!csvReport.data || csvReport.data.length === 0) {
            // Data not found, show the error message
            ErrorNotify('Data not found');
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 1000);
            return;
        }

        var url = `${base_url}raw-data`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "from_date": from_date,
                "to_date": to_date,
                "date_type": date_type,
               "custom1": custom_1 && custom_1[custom_1?.length - 1],
                "custom2": custom_2 && custom_2[custom_2?.length - 1],
                "custom3": custom_3 && custom_3[custom_3?.length - 1],
                "custom4": custom_4 && custom_4[custom_4?.length - 1],
                // "form_name": "clink_updated_form||6",
                "form_name": `${form_name}||${formData[0].form_version}`,
                // "affiliation": affiliation
            })
        };
        callApi(url, requestOptions, 'download_data');

    }

    const exportXLS = async (dfrData) => {
        console.log("xlsReport", dfrData);
        try {
          // Collect all unique keys from the dfrData, excluding "meta_data"
          const uniqueKeys = new Set();
          dfrData.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (key !== "meta_data") {
                uniqueKeys.add(key);
              }
            });
          });
    
          // Convert the set of unique keys to an array
          const keysArray = Array.from(uniqueKeys);
    
          // Create a new workbook and worksheet
          var wb = XLSX.utils.book_new();
    
          // Map the dfrData to the format required for the worksheet
          var wsData = dfrData.map((item) => {
            const rowData = {};
            keysArray.forEach((key) => {
              if (key === "tag" && Array.isArray(item[key])) {
                rowData[key] = item[key].map((i) => i);
              } else if (key === "applications" && Array.isArray(item[key])) {
                rowData[key] = item[key].map((i) => i);
              } else {
                rowData[key] = item[key];
              }
            });
            return rowData;
          });
    
          // Add headers as the first row
          wsData.unshift(
            keysArray.reduce((acc, key) => ({ ...acc, [key]: key }), {})
          );
    
          // Create the worksheet from the data
          var ws = XLSX.utils.json_to_sheet(wsData, { skipHeader: true });
    
          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    
          // Write the workbook to a file
          XLSX.writeFile(wb, "recruitment_form.xlsx");
        } catch (error) {
          console.error("Error exporting to XLSX:", error);
        }
        setDownloading(false)
      };

    const callApi = (url, requestOptions, flag = '') => {
        setDataIsSet(false);
        // var str = '';
        fetch(url, requestOptions).then(res => res.json()).then(data => {
            switch (flag) {
                case 'download_data':
                    setDownloading(true)

                    csvReport.data.splice(0);
                    csvReport.headers = null;

                    exportXLS(data?.data)

                    if (data?.message !== "Data not Found.") {
                        
                        // If data exists and is not empty, generate headers
                        // Object.keys(data?.data && data?.data[0])?.forEach((key) => {
                        //     headers.push({
                        //         label: key,
                        //         key: key
                        //     });
                        // });
                       
                        
                    }
                    console.log("data?.data?.length", data?.data);

                    


                    // console.log('heraders---------', headers)
                    // console.log('form_name=====', form_name,"***********",formData[0].form_version)
                    // if (data?.message !== "Data not Found.") {
                    //     csvReport = {
                    //         data: data?.data,
                    //         headers: headers,
                    //         filename: `${form_name}||${formVersion}.csv`,
                    //     };
                    // }
                    
                    // if (data?.message === "Data not Found.") {
                    //     console.log("TTTTTTTTTTTTTTTT");
                    //     csvReport.data.splice(0);
                    //     csvReport.headers = null;



                    // }
                    // csvReport.filename = form_name + '||' + formData[0].form_version+".csv";

                    console.log("csvReportcsvReport", csvReport);
                    setDataIsSet(true);
                    // window.location.reload(false);

                    break;
                case 'set_form_data_for_download':
                    
                    if (data.status !== 200) {
                        //swal(data.message)
                        //break;
                        setDownloading(false)
                    }

                    if (data.message === 'Data not Found.') {
                        setDownloading(false)
                        break;
                    }

                    if (data && data.data && data.data.length > 0) {
                        setDownloading(false)
                        // If data exists and is not empty, generate headers
                        Object.keys(data.data[0]).forEach((key) => {
                            headers.push({
                                label: key,
                                key: key
                            });
                        });
                    }
                    csvReport = {
                        data: data?.data,
                        headers: headers,
                        filename: form_name + formData[0].form_version + ".csv"
                    };
                    console.log("DFRRRRRR",data,data?.message,data?.data);
                    
                    console.log("csvReportcsvReport", csvReport);
                    setDataIsSet(true);
                    setDownloading(false)


                    break;
                case 'form_name_data':
                    console.log('form name======', data);

                    if (data?.status == 200 || data?.status == 201) {
                        SuccessNotify(data.message, {
                            icon: "success",
                        })
                        setFormData(data.data);

                    }
                    else if (data?.status >= 400 || data?.status <= 422) {
                        if (data?.status == 401) {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            localStorage.clear()
                            navigate("/");
                            return;
                        }
                        else {
                            ErrorNotify(data.message, {
                                icon: "error",
                            });
                            return;
                        }
                    }
                    else {
                        ErrorNotify(data.message, {
                            icon: "error",
                        });
                        return;
                    }

                    if (data.status !== 200) {
                        ErrorNotify(data.message);
                        return;
                    }
                    break;
            }
        }).catch(err => {
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
        });
    }

    const dfrCallDate = [{ value: "call_date", label: "Call Date" }, { value: "evaluation_date", label: "Evaluation Date" }].map((item) => ({
        value: item?.value,
        label: item?.label
    }));

    const options = formData.map((i, j) => ({
        value: i.form_name,
        label: i.display_name
    }));

    const handleFormNameChange = (selectedOption) => {
        setFormName(selectedOption.value);
    };

    return (
        <>
            {/* <Header />
            <Sidebar /> */}
            <div>
                <main>
                    <ToastContainer />
                    <div className="main-contents">
                        <div className="d-none" id="success_section">
                            <div className="page_error success mb-12">
                                <div className="alert alert-info text-center" id="success_msg"></div>
                            </div>
                        </div>
                        <div className="d-none" id="failure_section">
                            <div className="page_error failure mb-12">
                                <div className="alert alert-info text-center" id="failure_msg">
                                </div>
                                <button className="remove_error_msg"></button>
                            </div>
                        </div>

                        <div className="ip-header sc-head sc-collapse">
                            <div className="container-fluid">
                                <div className="sc-header d-flex justify-content-between align-items-center">
                                    <h3 className="sc-title d-flex align-items-center"><span>DFR</span></h3>
                                </div>
                            </div>
                        </div>

                        <nav className="ip-breadcrumb" aria-label="breadcrumb">
                            <ul className="breadcrumb mb-0">
                                <li className="breadcrumb-item active"><span>DFR</span></li>
                            </ul>
                        </nav>

                        <div className="ip-section">
                            <div className="container-fluid">
                                <div className="ip-form p-0">
                                    <div className="form-contents">
                                        <form action="" method="post" className="filter_page_form" id="filter_page_form">
                                            <input type="hidden" className="txt_csrfname" name="csrf_test_name" value="2f5396b97c047600347cc8c34e88d544" />
                                            <div className="form-row">
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>From</span><span className="mandatory">*</span></label>
                                                    <input type="date" className="form-control" placeholder="From Date" required="" onChange={(e) => setFromDate(e.target.value)} />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>To</span><span className="mandatory">*</span></label>
                                                    <input className="form-control" type="date" required="" onChange={(e) => setToDate(e.target.value)} />
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>Date Type</span><span className="mandatory">*</span></label>
                                                    {/* <select className="form-control" required="" onChange={(e) => setDateType(e.target.value)}>
                                                        <option selected="" value='call_date'>Call Date</option>
                                                        <option value='evaluation_date'>Evaluation Date</option>
                                                    </select> */}
                                                    <Select
                                                        options={dfrCallDate}
                                                        defaultValue={{ value: "call_date", label: "Call Date" }}
                                                        onChange={(e) => setDateType(e?.value)}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                        }}
                                                    />
                                                </div>

                                                <CustomHierarchy custom_1_val={custom_1_val} setHierarchyForDFR={setHierarchyForDFR} setFormDataDfr={setFormDataDfr} custom_2_val={custom_2_val} custom_3_val={custom_3_val} custom_4_val={custom_4_val} fromDate={from_date} toDate={to_date} datetype={date_type} />

                                                {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label className="form-label"><span>Affiliation</span><span className="mandatory">*</span></label>
                                                    <select id="filter_affiliation" className="form-control" required="" onChange={(e) => setAffiliation(e.target.value)}>
                                                        <option value="All" selected="">All</option>
                                                        <option value="CenturyLink">CenturyLink</option>
                                                        <option value="MattsenKumar">MattsenKumar</option>
                                                    </select>
                                                </div> */}
                                                {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>Form Name</span><span className="mandatory">*</span></label>
                                                    <select className="form-control" onChange={(e) => {setFormName(e.target.value);setFormVersion(formData?.filter(i=>i?.form_name===e.target.value)[0]?.form_version)}}>
                                                        <option value='' selected disabled>Select</option>
                                                        {formData.map((i, j) => {
                                                            return (
                                                                <>
                                                                    <option value={i.form_name}>{i.display_name}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </select>
                                                </div> */}
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label>
                                                        <span>Form Name</span>
                                                        <span className="mandatory">*</span>
                                                    </label>
                                                    <Select
                                                        className=""
                                                        value={options.find((option) => option.value === form_name)}
                                                        options={options}
                                                        onChange={(e)=>[handleFormNameChange(e),setDownloading(true)]}
                                                        styles={{
                                                            control: (provided) => ({
                                                                ...provided,
                                                                borderRadius: '100px', // Adjust the border-radius value as per your needs
                                                            }),
                                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                        }}
                                                        placeholder="Select"
                                                    />
                                                </div>
                                                {/* <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>Category</span></label>
                                                    <select className="form-control custom-multiselectfilter" multiple="">
                                                        <option value='' selected disabled>Select</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-3 form-group">
                                                    <label><span>Attribute</span></label>
                                                    <select className="form-control custom-multiselectfilter" multiple="">
                                                        <option value='' selected disabled>Select</option>
                                                    </select>
                                                </div> */}
                                            </div>
                                        </form>
                                    </div>
                                    {/* <CSVLink {...csvReport}>
                                        <button className=" dt-button buttons-excel buttons-html5" aria-controls="summary_table" type="button"><span>Export</span></button>
                                    </CSVLink> */}
                                    <div className="form-footer form-footer text-right align-items-center mx-4">
                                        {csvReport.data && csvReport.data.length > 0 && !downloading ? 
                                            
                                                <button type="button" style={{width:"12%"}} className="btn btn-primary ml-auto" onClick={downloadDFR}>
                                                    Download
                                                </button>
                                            
                                         :
                                        downloading ?
                                                <button type="button" style={{width:"12%"}} disabled className="btn btn-primary ml-auto" onClick={downloadDFR}>
                                                    Download   <div class="spinner-border" role="status" style={{ height: "15px", width: "15px",marginLeft:"30px" }}>
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </button> :
                                                
                                                    <button type="button" style={{width:"12%"}} className="btn btn-primary ml-auto" onClick={downloadDFR}>
                                                        Download
                                                    </button>
                                                }
                                    </div>
                                    {/* {isSet && <CSVLink {...csvReport}/>} */}
                                    {/* <DownloadDFR downloadDFR={downloadDFR} csvReportData={csvReportData} /> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default DFR;