import logo from './logo.svg';
import './App.css';
import { Route, Routes, Link, useNavigate,BrowserRouter, Switch } from 'react-router-dom';
import React, { Component, useEffect, useState } from 'react';
import Login from './auth/loginComponent';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import ActivationUser from './auth/ActivationUser';
import Dashboard from './Admin/components/Dashboard';
import { Helmet } from 'react-helmet';
import AddUser from './Admin/components/AddUser';
import AddAgent from './Admin/components/AddAgent';
import Tickets from './Admin/components/Tickets';
import Roles from './Admin/components/Roles';
import RolesPermissions from './Admin/components/RolesPermissions';
import FormPreview from './Admin/components/FormPreview';
import NewFormPreview from './Admin/components/NewFormPreview';
import $ from "jquery";

import EvaluationSummary from './Admin/components/EvaluationSummary';
import AddEvaluation from './Admin/components/AddEvaluation';
import CallList from './Admin/components/CallList';
import MyEvaluations from './Admin/components/MyEvaluations';
import UnAssignedCalls from './Admin/components/UnAssignedCalls';
import AuditHistory from './Admin/components/audits/AuditHistory';
import MyAudits from './Admin/components/audits/MyAudits';
import AvailableAudits from './Admin/components/audits/AvailableAudits';
import FormListing from './Admin/components/FormListing';
import Feedback from './Admin/components/Feedback';

import SSAccounts from './Admin/components/smartspeech/SSAccounts';
import SSApplications from './Admin/components/smartspeech/SSApplications';
import SSTags from './Admin/components/smartspeech/SSTags';

import SSCallDetails from './Admin/components/smartspeech/SSCallDetails';
import SSCallSearch from './Admin/components/smartspeech/SSCallSearch';
import Profile from './Admin/components/Profile/Profile';

import SSCallUpload from './Admin/components/smartspeech/SSCallUpload';
import SSDashboard from './Admin/components/smartspeech/SSDashboard';
import SSQaAutomation from './Admin/components/smartspeech/SSQaAutomation';
import Dispute from './Admin/components/Dispute';
import Alert from './Admin/components/Alert';
import ListCalibration from './Admin/components/calibration/ListCalibration';
import MyCalibration from './Admin/components/calibration/MyCalibration';
import StartCalibration from './Admin/components/calibration/StartCalibration';
import SchedulelistCalibration from './Admin/components/calibration/SchedulelistCalibration';


// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Dashboards from './Admin/components/Dashboards';
import KpiDashboard from './Admin/components/kpiDashboard/Dashboard';
import AboveGoalDetails from './Admin/components/kpiDashboard/evaluations/AboveGoalDetails';
import AgentEvaluations from './Admin/components/kpiDashboard/evaluations/AgentEvaluations';
import AutoFailDetails from './Admin/components/kpiDashboard/evaluations/AutoFailDetails';
import BelowGoalDetails from './Admin/components/kpiDashboard/evaluations/BelowGoalDetails';
import CoachedDetails from './Admin/components/kpiDashboard/evaluations/CoachedDetails';
import CompletedEvaluations from './Admin/components/kpiDashboard/evaluations/CompletedEvaluations';
import MyEvaluationSummery from './Admin/components/kpiDashboard/evaluations/MyEvaluationSummery';
import EscalationDetails from './Admin/components/kpiDashboard/dispute/EscalationDetails';
import EscalationStatusClosed from './Admin/components/kpiDashboard/dispute/EscalationStatusClosed';
import EscalationStatusDeclined from './Admin/components/kpiDashboard/dispute/EscalationStatusDeclined';
import EscalationStatusExpired from './Admin/components/kpiDashboard/dispute/EscalationStatusExpired';
import EscalationStatusOpen from './Admin/components/kpiDashboard/dispute/EscalationStatusOpen';
import InvalidEscalation from './Admin/components/kpiDashboard/dispute/InvalidEscalation';
import ValidEscalation from './Admin/components/kpiDashboard/dispute/ValidEscalation';
import Analysis from './Admin/components/kpiDashboard/kpis/Analysis';
import AuditPerformance from './Admin/components/kpiDashboard/PerformanceCharts/AuditPerformance';
import CampaignPerformance from './Admin/components/kpiDashboard/PerformanceCharts/CampaignPerformance';
import DisputePerformance from './Admin/components/kpiDashboard/PerformanceCharts/DisputePerformance';
import EvaluationPerformance from './Admin/components/kpiDashboard/PerformanceCharts/EvaluationPerformance';
import ScheduledReport from './Admin/components/ScheduleReport/ScheduleReport';

import EvaluationStatus from './Admin/components/kpiDashboard/PerformanceCharts/EvaluationStatus';
import LOBPerformance from './Admin/components/kpiDashboard/PerformanceCharts/LOBPerformance';
import VendorPerformance from './Admin/components/kpiDashboard/PerformanceCharts/VendorPerformance';
import LocationPerformance from './Admin/components/kpiDashboard/PerformanceCharts/LocationPerformance';
import Hierarchy from './Admin/components/Hierarchy';
import DFR from './Admin/components/DFR';
import SSCallList from './Admin/components/smartspeech/SSCallList';
import SSTranscribeFiles from './Admin/components/smartspeech/SSTranscribeFiles';
import Header from './shared/Header';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import Logout from './auth/Logout';
import DataTableView from './shared/DataTable';
import CreateCategory from "./Admin/components/lmsDashboard/category/CreateCategory";
import CategoryList from "./Admin/components/lmsDashboard/category/CategoryList";
import LessonsList from "./Admin/components/lmsDashboard/lessons/LessonsList";
import AddLesson from "./Admin/components/lmsDashboard/lessons/AddLessons";
import CreateTraining from "./Admin/components/lmsDashboard/training/CreateTraining";
import TrainingList from "./Admin/components/lmsDashboard/training/TrainingList";
import CreateQuestions from "./Admin/components/lmsDashboard/questions/CreateQuestions";
import QuestionsList from "./Admin/components/lmsDashboard/questions/QuestionsList";
import CreateQuestionSingle from "./Admin/components/lmsDashboard/questions/CreateQuestionSingle";
import AddTest from "./Admin/components/lmsDashboard/test/AddTest";
import TestList from "./Admin/components/lmsDashboard/test/TestList";
import TestSubmission from './Admin/components/lmsDashboard/test/TestSubmission';
import NoAccess from './shared/NoAccess';
import TestView from './Admin/components/lmsDashboard/test/TestView';
import TestResult from './Admin/components/lmsDashboard/test/TestResult';
import AtaRawData from './Admin/components/audits/AtaRawData';
import DraftEvaluation from './Admin/components/DraftEvaluation';
import Reviewed from './Admin/components/Reviewed'

import SSCallListNew from './Admin/components/smartspeech/SSCallListNew';
import { ErrorNotify } from './shared/Toast';
import swal from 'sweetalert';
import PageNotFound from './shared/PageNotFound';
import SSApplicationLog from './Admin/components/smartspeech/SSApplicationLog';
import AgentCoachingServicesLevels from './Admin/components/Reports/Evaluations/AgentCoachingServicesLevels';
import AgentEvaluationSummary from './Admin/components/Reports/Evaluations/AgentEvaluationSummary';
import AgentPerformanceNew from './Admin/components/Reports/Evaluations/AgentPerformanceNew';
import AgentEvaluationTrending from './Admin/components/Reports/Evaluations/AgentEvaluationTrending';
import AttributeComments from './Admin/components/Reports/Evaluations/AttributeComments';
import AttributeTrending from './Admin/components/Reports/Evaluations/AttributeTrending';
import EnterpriseEvaluationTrending from './Admin/components/Reports/Evaluations/EnterpriseEvaluationTrending';
import AutoFailReports from './Admin/components/Reports/AutoFail/AutoFailReports';
import AutoFailSummary from './Admin/components/Reports/AutoFail/AutoFailSummary';
import AutoFailTrending from './Admin/components/Reports/AutoFail/AutoFailTrending';
import AutoFailDetailsNew from './Admin/components/Reports/AutoFail/AutoFailDetailsNew';
import EvaluationDetails from './Admin/components/Reports/Evaluations/EvaluationDetails';
import CoachedReport from './Admin/components/Reports/Coached/CoachedReport';
import DisputStatus from './Admin/components/Reports/Dispute/DisputeStatus';
import DisputeDetailsNew from './Admin/components/Reports/Dispute/DisputeDetailsNew';
import PerformanceThresoldSummary from './Admin/components/Reports/Miscellaneous/PerformanceThresoldSummary';
import EvaluatorPerformanceNew from './Admin/components/Reports/Miscellaneous/EvaluatorPerformanceNew';
import TeamPerformance from './Admin/components/Reports/Miscellaneous/TeamPerformance';
import CalibirationDetailsNew from './Admin/components/Reports/Miscellaneous/CalibirationDetailsNew';
import IdleTimerContainer from './Admin/components/IdleTimerContainer';
import SSTagReprocessLog from './Admin/components/smartspeech/SSTagReprocessLog';
import UserLoginHistory from './Admin/components/UserLoginHistory';
import TrainingCallReport from './Admin/components/Reports/Miscellaneous/TrainingCallReport';
import UsersPermissions from './Admin/components/UsersPermissions';
import SSTagReport from './Admin/components/smartspeech/SSTagReport';
import BaseDashboard from './Admin/components/DashboardNew/BaseDashboard';
import MainDashboard from './Admin/components/UniversalDashboard/MainDashboard';
import BaseDashboardForApp from './Admin/components/DashboardNew/BaseDashboardForApp';
import UniversalFormTable from './Admin/components/UniversalDashboard/UniversalFormTable';
import Reports from './Admin/components/smartspeech/Reports';
import SSDFR from './Admin/components/smartspeech/SSDFR';
import FavoriteReport from './Admin/components/FavoriteReport/FavoriteReport';
import FormCreation from './Admin/components/FormCreation';
import FormDetails from './Admin/components/FormDetails';




const App = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loginToken'));
  const base_name = process.env.PUBLIC_URL;

  
  
  // const checkForInactivity = () => {
  //   const expireTime = localStorage.getItem("expireTime")
  //   if(expireTime<Date.now()){
  //     console.log("Log out");
  //   }
  // }

  // const updateExpireTime = () => {
  //   const expireTime = Date.now()+1000
  //   localStorage.setItem("expireTime",expireTime)
  // }

  // useEffect(()=>{
  //   const interval = setInterval(()=>{
  //     checkForInactivity();
  //   },1000);
  //   return () => clearInterval(interval)
  // })


  // useEffect(()=>{
  //   console.log("PPPPPPPPPPPPPPPP");
  //   updateExpireTime();
  //   window.addEventListener("click",updateExpireTime);
  //   window.addEventListener("keypress",updateExpireTime)
  //   window.addEventListener("scroll",updateExpireTime)
  //   window.addEventListener("mousemove",()=>{console.log("HHHHHHHHHHHHHHH")})

  const role = localStorage.getItem("loginUserRole");

  const userRole = localStorage.getItem("userRole");
  const defaultPassword = localStorage.getItem("defaultPassword");
  const loginToken   = localStorage.getItem("loginToken");

    
  useEffect(()=>{
    let pageLoads = window.location.pathname.split('/')
    if(pageLoads?.includes('new-form-preview')){
     $("document").ready(function () {
       $("body").addClass("sidebar-active");
       $(".sidebar").addClass("active");
       $(".sb-toggle-btn").addClass("active");
     });
    }
    else{
     $("document").ready(function () {
       $("body").removeClass("sidebar-active");
       $(".sidebar").removeClass("active");
       $(".sb-toggle-btn").removeClass("active");
     });
    }
   },[window.location.pathname])
  const isLoggedIn = (val) => {
    console.log("isLoggedIn", val);
    setLoggedIn(val);
  };
  useEffect(()=>{
    if(loginToken === '' || loginToken === null || loginToken === undefined){
      navigate("/");
    }
  },[loginToken,navigate])

  return (
    <div>
        <IdleTimerContainer loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
        {loggedIn!==null && loggedIn!==undefined && defaultPassword == '0' && <Header isLoggedIn={isLoggedIn} />}
          {loggedIn!==null && loggedIn!==undefined && defaultPassword == '0' && <Sidebar isLoggedIn={isLoggedIn} />}
          <Routes>
            {/* <Route exact path="/" element={<DataTableView />} /> */}
            <Route exact path="/" element={<Login isLoggedIn={isLoggedIn} />} />
            {/* <Route path='/login' element={<Logout isLoggedIn={isLoggedIn}/>} /> */}
            {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
            <Route path="/reset-password" element={defaultPassword === '1' ? <ResetPassword />:<PageNotFound isLoggedIn={isLoggedIn} />} />
            {/* <Route path="/new-user-activation" element={<ActivationUser />} /> */}

            <Route path="/dashboards" element={<Dashboards />} />
            <Route path="/hierarchy" element={<Hierarchy />} />

            <Route path="/kpi-dashboard" element={ <KpiDashboard />} />

            <Route path="/add-user" element={<AddUser />} />
            <Route path="/add-agent" element={<AddAgent />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/roles-permissions" element={<RolesPermissions />} />
            <Route path="/userHistory" element={<UserLoginHistory />} />

            <Route path="/form-listing" element={<FormListing />} />
            <Route path="/form-creation" element={<FormCreation />} />
            <Route path="/form-details" element={<FormDetails />} />
            <Route path="/form-preview" element={<FormPreview />} />
            <Route path="/new-form-preview" element={<NewFormPreview />} />
            <Route path="/draft-evaluation" element={<DraftEvaluation />} />
            <Route path="/evaluation-summary" element={<EvaluationSummary />} />
            <Route path="/add-call" element={<AddEvaluation />} />
            <Route path="/call-list" element={<CallList />} />
            <Route path="/universalForm-call-list" element={<UniversalFormTable />} />
            <Route path="/my-calls" element={<MyEvaluations />} />
            <Route path="/unassigned-calls" element={<UnAssignedCalls />} />
            <Route path="/audit-history" element={<AuditHistory />} />
            <Route path="/my-audits" element={<MyAudits />} />
            <Route path="/available-audits" element={<AvailableAudits />} />
            <Route path="/ata-raw-data" element={<AtaRawData />} />
            <Route path="/reviewed" element={<Reviewed />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/agent-coaching-service-levels" element={<AgentCoachingServicesLevels />} />
            <Route path="/agent-evaluation-summary" element={<AgentEvaluationSummary />} />
            <Route path="/agent-performance" element={<AgentPerformanceNew />} />
            <Route path="/agent-evaluation-trending" element={<AgentEvaluationTrending />} />
            <Route path="/attribute-comments" element={<AttributeComments />} />
            <Route path="/attribute-trending" element={<AttributeTrending />} />
            <Route path="/evaluation-details" element={<EvaluationDetails />} />
            <Route path="/enterprise-evaluation-trending" element={<EnterpriseEvaluationTrending />} />
            <Route path="/autofail-details" element={<AutoFailDetailsNew />} />
            <Route path="/autofail-report" element={<AutoFailReports />} />
            <Route path="/autofail-summary" element={<AutoFailSummary />} />
            <Route path="/autofail-trending" element={<AutoFailTrending />} />
            <Route path="/coached-report" element={<CoachedReport />} />
            <Route path="/dispute-status" element={<DisputStatus />} />
            <Route path="/dispute-detail" element={<DisputeDetailsNew />} />
            <Route path="/favourite" element={<FavoriteReport />} />

            <Route path="/performance-threshold-summary" element={<PerformanceThresoldSummary />} />
            <Route path="/evaluator-performance" element={<EvaluatorPerformanceNew />} />
            <Route path="/team-performance" element={<TeamPerformance />} />
            <Route path="/training-call" element={<TrainingCallReport />} />
            <Route path="/calibration-detail" element={<CalibirationDetailsNew />} />
            <Route path="/users-permissions" element={<UsersPermissions />}   />
        <Route path="/scheduled_report" element={<ScheduledReport /> } />

            <Route path="/smartspeech/folders" element={<SSAccounts />} />
            <Route path="/tags-report" element={<SSTagReport />} />
            <Route
              path="/smartspeech-applications"
              element={ <SSApplications />}
            />
            <Route
              path="/smartspeech-report"
              element={<Reports/>}
            />
            <Route
              path="/smartspeech-tags"
              element={<SSTags />}
            />
            <Route
              path="/ss-tag-reprocess-log"
              element={ <SSTagReprocessLog />}
            />
            <Route
              path="/smartspeech-applications-log"
              element={ <SSApplicationLog />}
            />
            <Route
              path="/smartspeech-call-details"
              element={<SSCallDetails />}
            />
             <Route path="/profile" element={<Profile />} />
            <Route path="/smartspeech-call-search" element={<SSCallSearch />} />
            <Route path="/smartspeech-call-upload" element={<SSCallUpload />} />
            <Route
              path="/smartspeech-transcribe-files"
              element={<SSTranscribeFiles />}
            />
            <Route path="/smartspeech-call-list" element={<SSCallList />} />
            <Route path="/new-smartspeech-call-list" element={<SSCallListNew />} />
            <Route path="/smartspeech-dashboard" element={<SSQaAutomation />} />
            <Route path="/smartspeech-dashboard-new" element={<BaseDashboard/>} />
            <Route path="/smartspeech-app-dashboard" element={<BaseDashboardForApp/>} />
            <Route
              path="/universal-dashboard"
              element={<MainDashboard />}
            />
            <Route
              path="/smartspeech-qa-automation"
              element={<SSDashboard />}
            />
            <Route path="/dispute" element={<Dispute />} />
            <Route path="/alert" element={<Alert /> } />
            <Route path="/review-calibration" element={<ListCalibration />} />
            <Route path="/my-calibration" element={<MyCalibration />} />
            <Route path="/start-calibration" element={ <StartCalibration />} />
            <Route
              path="/schedulelist-calibration"
              element={<SchedulelistCalibration />}
            />

         
<Route
              path="/evaluations/above-goal-details/:fromList"
              element={<AboveGoalDetails />}
            />
            <Route
              path="/evaluations/agent-evaluation/:fromList"
              element={<AgentEvaluations />}
            />
            <Route
              path="/evaluations/auto-fail-details/:fromList"
              element={<AutoFailDetails />}
            />
            <Route
              path="/evaluations/below-goal-details/:fromList"
              element={<BelowGoalDetails />}
            />
            <Route
              path="/evaluations/coached-details/:fromList"
              element={<CoachedDetails />}
            />
            <Route
              path="/evaluations/completed-evaluations/:fromList"
              element={<CompletedEvaluations />}
            />
            <Route
              path="/evaluations/my-evaluation-summery/:fromList"
              element={<MyEvaluationSummery />}
            />

            <Route
              path="/dispute/escalation-details/:fromList"
              element={<EscalationDetails />}
            />
            <Route
              path="/dispute/escalation-status-closed"
              element={<EscalationStatusClosed />}
            />
            <Route
              path="/dispute/escalation-status-declined"
              element={<EscalationStatusDeclined />}
            />
            <Route
              path="/dispute/escalation-status-expired"
              element={<EscalationStatusExpired />}
            />
            <Route
              path="/dispute/escalation-status-open"
              element={<EscalationStatusOpen />}
            />
            <Route
              path="/dispute/invalid-escalation"
              element={<InvalidEscalation />}
            />
            <Route
              path="/dispute/valid-escalation"
              element={<ValidEscalation />}
            />

            <Route path="/kpis/analysis" element={<Analysis />} />

            <Route
              path="/performance_charts/audit_performance"
              element={<AuditPerformance />}
            />
            <Route
              path="/performance_charts/dispute_performance"
              element={<DisputePerformance />}
            />
            <Route
              path="/performance_charts/evaluation_performance"
              element={<EvaluationPerformance />}
            />
            <Route
              path="/performance_charts/evaluation_status"
              element={<EvaluationStatus />}
            />
            <Route
              path="/performance_charts/lob_performance"
              element={<LOBPerformance />}
            />
            <Route
              path="/performance_charts/vendor_performance"
              element={<VendorPerformance />}
            />
            <Route
              path="/performance_charts/location_performance"
              element={<LocationPerformance />}
            />
            <Route
              path="/performance_charts/campaign_performance"
              element={<CampaignPerformance />}
            />
              <Route path="/smartspeech-dfr-report" element={<SSDFR /> } />

            <Route path="/dfr" element={<DFR />} />
            {/* <Route path="/create-categories" element={role !== 'QS' && role !== 'QE' ? <CreateCategory /> : <NoAccess />} />
            <Route path="/categories-list" element={role !== 'QS' && role !== 'QE' ? <CategoryList /> : <NoAccess/>} />
            <Route path="/lessons-list" element={role !== 'QS' && role !== 'QE' ? <LessonsList /> : <NoAccess />} />
            <Route path="/add-lessons" element={role !== 'QS' && role !== 'QE'  ? <AddLesson /> : <NoAccess />} />
            <Route path="/create-training" element={role !== 'QS' && role !== 'QE'  ? <CreateTraining /> : <TrainingList />} />
            <Route path="/training-list" element={<TrainingList />} />
            <Route path="/add-test" element={role !== 'QS'  && role !== 'QE' ? <AddTest /> : <NoAccess />} />
            <Route path="/test-list" element={role !== 'QS' && role !== 'QE' ? <TestList /> : <NoAccess/>} />
          <Route path="/test-submission" element={role !== 'QS' && role !== 'QE' ? <TestSubmission /> : <NoAccess/>} /> */}
            <Route path="/create-questions" element={role !== 'QS' && role !== 'QE' ? <CreateQuestionSingle /> : <NoAccess />} />
            <Route path="/trainings" element={<CategoryList />} />
            <Route path="/test-view" element={role !== 'QS' ? <TestView /> : <NoAccess />} />
            <Route path="/test-result" element={<TestResult />} />
          </Routes>
          {loggedIn && <Footer />}
          {/* {!localStorage.getItem('loginToken') ?
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/new-user-activation" element={<ActivationUser />} />
            </Routes>
            :
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} /> 
              <Route path="/add-user" element={<AddUser />} />
              <Route path="/add-agent" element={<AddAgent />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path='/roles' element={<Roles />} />
              <Route path='/roles-permissions' element={<RolesPermissions />} />
              <Route path='/form-preview' element={<FormPreview />} />
              <Route path='/evaluation-summary' element={<EvaluationSummary />} />
              <Route path='/add-evaluation' element={<AddEvaluation />} />
              <Route path='/call-list' element={<CallList/>} />
              <Route path='/my-calls' element={<MyEvaluations/>} />
              <Route path='/unassigned-calls' element={<UnAssignedCalls/>} />
              <Route path='/audit-history' element={<AuditHistory/>} />
              <Route path='/my-audits' element={<MyAudits/>} />
              <Route path='/available-audits' element={<AvailableAudits/>} />
            </Routes>
          } */}
        </div>
  );
}

export default App;