import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { components } from "react-select";
import { ErrorNotify, SuccessNotify } from '../../shared/Toast';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
const base_url = process.env.REACT_APP_AUTH_BASE_URL;
const FormDetails = () => {
    const [hierarchyData, setHierarchyData] = useState([]);
    const [defaultValueC1, setdefaultValueC1] = useState([]);
    const [defaultValueC2, setdefaultValueC2] = useState([]);
    const [defaultValueC3, setdefaultValueC3] = useState([]);
    const [defaultValueC4, setdefaultValueC4] = useState([]);
    const [assignValue, setAssignValue] = useState('');
    const [feedbackTatError, setFeedbackTatError] = useState("");
    const [passRateError, setPassRateError] = useState("");
    const [formWeightError, setFormWeightError] = useState("");
    const [feedbackValue, setFeedbackValue] = useState("48");
    const [passRateValue, setPassRateValue] = useState("100");
    const [formWeightValue, setFormWeightValue] = useState("0");
    const [c1Data, setC1Data] = useState([]);
    const [c2Data, setC2Data] = useState([]);
    const [c3Data, setC3Data] = useState([]);
    const [c4Data, setC4Data] = useState([]);
    const [formName, setFormName] = useState('');
    const [formData, setFormData] = useState([]);
    const [defaultFormList, setDefaultFormList] = useState([]);
    const [formDisplayName, setFormDisplayName] = useState("");
    const [formEffectiveDate, setFormEffectiveDate] = useState("");








    useEffect(() => {
        getCustomHierarchyData();
        getDefaultForm()
    }, []);

    const getDefaultForm = () => {
        var url = `${base_url}forms/get-forms-list`;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },

        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }

            setDefaultFormList(data.data);
        }).catch(err => {
            console.log(err);
        });
    }


    const getCustomHierarchyData = async () => {
        var url = `${base_url}hierarchy/get-custom-hierarchy`;


        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('hierarchy data======', data);
            if (isNaN(data.status)) {
                // Logout();
                return
            }
            var arr = [];
            if (data?.status == 200) {
                data?.data?.map((i, j) => {
                    arr[i.custom1_id] = i.custom1_name;
                    arr[i.custom2_id] = i.custom2_name;
                    arr[i.custom3_id] = i.custom3_name;
                    arr[i.custom4_id] = i.custom4_name;
                })
                setHierarchyData(arr);

            }
            else if (data?.status >= 400 && data?.status <= 422) {
                if (data?.status === 401) {
                    localStorage.clear()
                }
                return ErrorNotify(data.message, { icon: "error", });
            }


        }).catch(err => {
            return ErrorNotify("Network Error, Please Try Again !", {
                icon: "error",
            });
        });

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
        };

        var url = `${base_url}custom/1`;
        await fetch(url, requestOptions).then(res => res.json()).then(data => {
            setC1Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }
    const handleC2Change = (e) => {
        console.log("defaultValueC1sldkjdshfdhgfhj", e);


        setdefaultValueC2('');
        setdefaultValueC3('');
        setdefaultValueC4('');
        setFormName('')
        setAssignValue('')
        setC3Data([])
        setC4Data([])




        var url = `${base_url}custom/2`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: e.map(i => i?.value),
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('c2 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC1(e?.map(i => i?.value));
            { console.log("c1Datac1Data", defaultValueC1) }
            // props.getC1Value(e);
            setC2Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC3Change = (e) => {


        setdefaultValueC3('');
        setdefaultValueC4('');
        setFormName('')
        setAssignValue('')
        setC4Data([])


        var url = `${base_url}custom/3`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: e.map(i => i?.value)
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC2(e.map(i => i?.value));
            // props.getC2Value(e);
            setC3Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleC4Change = (e) => {


        setdefaultValueC4('');
        setFormName('')
        setAssignValue('')


        var url = `${base_url}custom/4`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: e.map(i => i?.value),
            })
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status != 200) {
                // alert(data.message);
                return;
            }
            setdefaultValueC3(e.map(i => i?.value));
            // props.getC3Value(e);
            setC4Data(data.data);
        }).catch(err => {
            console.log(err);
        });
    }
    const handleC5Change = (e) => {



        if (e !== '') {
            var url = `${base_url}calls/get-form-name`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                },
                body: JSON.stringify({
                    c1: defaultValueC1,
                    c2: defaultValueC2,
                    c3: defaultValueC3,
                    c4: e.value,
                    proxy_user_id: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? JSON.parse(localStorage.getItem("settedProxy"))?.value : "",
                    proxy_by: localStorage.getItem("settedProxy") !== null && localStorage.getItem("settedProxy") !== undefined ? localStorage.getItem("loginUserUniqueid") : ""
                })
            };

            fetch(url, requestOptions).then(res => res.json()).then(data => {
                console.log('form data======', data);
                // if (data.status != 200) {
                //     ErrorNotify(data.message);
                //     return;
                // }
                // setdefaultValueC3(e);
                // setFormData(prevState => [
                //     ...prevState,
                //     data.data
                // ]);
                setFormData(data.data)
                setdefaultValueC4(e.map(i => i?.value));
                // console.log('-------------', data.data)
            }).catch(err => {
                console.log(err);
            });
        }
    }
    const handleFeedbackTat = (e, type) => {
        const inputValue = e.target.value;

        if (type === "feedback_tat") {
            if (/^\d*$/.test(inputValue)) {
                setFeedbackValue(inputValue);
                setFeedbackTatError(''); // Clear the error if valid
            }
            else {
                setFeedbackTatError('Please enter an integer value'); // Show error for non-integer input
            }
        }
        if (type === "pass_rate") {
            if (/^\d*$/.test(inputValue)) {
                setPassRateValue(inputValue);
                setPassRateError(''); // Clear the error if valid
            }
            else {
                setPassRateError('Please enter an integer value'); // Show error for non-integer input
            }
        }
        if (type === "form_weight") {
            if (/^\d*$/.test(inputValue)) {
                setFormWeightValue(inputValue);
                setFormWeightError(''); // Clear the error if valid
            }
            else {
                setFormWeightError('Please enter an integer value'); // Show error for non-integer input
            }
        }

    };


    const optionsC1 = c1Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC2 = c2Data?.map((item) => ({
        value: item,
        label: item
    }));
    const optionsC3 = c3Data?.map((item) => ({
        value: item,
        label: item
    }));

    const optionsC4 = c4Data?.map((item) => ({
        value: item,
        label: item
    }));

    const optionsFormData = formData?.map((i) => ({
        value: `${i.form_name}||${i.form_version}`,
        label: `${i.display_name}(V${i.form_version}.0)`
    }));

    const allForms = defaultFormList?.map((i) => ({
        value: `${i.form_name}||${i.form_version}`,
        label: `${i.form_name} (V${i.form_version})`.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
    }));

    console.log("formName", formEffectiveDate);



    const Option = (props) => {

        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const ValueContainer = ({ children, ...props }) => {
        if (props?.selectProps?.value?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    <span style={{ fontSize: "12px" }}>{`${props?.selectProps?.value?.length} selected`}</span>
                </components.ValueContainer>
            );
        } else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };

    const handleFormCreation = () => {
        if(defaultValueC1?.length===0){
            return ErrorNotify("Select hierarchy value 1")
        }
        if(defaultValueC2?.length===0){
            return ErrorNotify("Select hierarchy value 2")
        }
        if(defaultValueC3?.length===0){
            return ErrorNotify("Select hierarchy value 3")
        }

        if(defaultValueC4?.length===0){
            return ErrorNotify("Select hierarchy value 4")
        }
        if(passRateValue===""){
            return ErrorNotify("Select passrate")
        }
        if(formWeightValue===""){
            return ErrorNotify("Select form weight")
        }
        if(feedbackValue===""){
            return ErrorNotify("Select feedback")
        }
        if(formName===""){
            return ErrorNotify("Select form name")
        }
        if(formDisplayName===""){
            return ErrorNotify("Select form display")
        }
        if(formEffectiveDate===""){
            return ErrorNotify("Select form effective date")
        }
        var url = `${base_url}forms/upload-form-details`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({

                custom1: defaultValueC1,
                custom2: defaultValueC2,
                custom3: defaultValueC3,
                custom4: defaultValueC4,
                pass_rate: passRateValue,
                form_weightage: formWeightValue,
                feedback_tat: feedbackValue,
                form_name: formName?.split("||")[0],
                form_version: formName?.split("||")[1],
                display_name: formDisplayName,
                effective: formEffectiveDate
            })


        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('c3 data======', data);
            if (data.status === 200) {
                // alert(data.message);
                
                return SuccessNotify(data?.message);
            }
            else{
                return ErrorNotify(data?.message);
            }
           
        }).catch(err => {
            console.log(err);
        });


    }

    return (
        <div>
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>

                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">
                            <div className="sc-header d-flex justify-content-between align-items-center">
                                <h3 className="sc-title d-flex align-items-center"><span>Form Details</span></h3>
                            </div>
                        </div>
                    </div>

                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item active"><span>Form Details</span></li>
                        </ul>
                    </nav>
                    <div className="ip-section">
                        <div className="container-fluid">
                            <div className="ip-form p-0">
                                <div className="form-contents">
                                    <div className="row">
                                        <div className="col-sm-3 col-md-3 col-lg-3 rounded form-group">
                                            <label><span>{hierarchyData.c1}</span><span className="mandatory">*</span></label>
                                            <Select options={optionsC1}
                                                value={defaultValueC1 !== '' ? optionsC1.find((option) => option.value === defaultValueC1) : ''}
                                                isSearchable={true} required={true} onChange={(e) => handleC2Change(e)}
                                                isMulti
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),

                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),

                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={true}
                                                allowSelectAll={true}
                                                components={{
                                                    Option,
                                                    ValueContainer: ValueContainer,
                                                }}

                                            />
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 rounded form-group">
                                            <label><span>{hierarchyData.c2}</span><span className="mandatory">*</span></label>
                                            <Select options={optionsC2}
                                                id="optionC2Id"
                                                value={defaultValueC2 !== '' ? optionsC2.find((option) => option.value === defaultValueC2) : ''}
                                                isMulti
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}
                                                isSearchable={true} required={true} onChange={(e) => handleC3Change(e)}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={true}
                                                allowSelectAll={true}
                                                components={{
                                                    Option,
                                                    ValueContainer: ValueContainer,
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 rounded form-group">
                                            <label><span>{hierarchyData.c3}</span><span className="mandatory ">*</span></label>
                                            <Select options={optionsC3}
                                                value={defaultValueC3 !== '' ? optionsC3.find((option) => option.value === defaultValueC3) : ''}
                                                isMulti
                                                id="optionsC3Id"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}

                                                isSearchable={true} required={true} onChange={(e) => handleC4Change(e)}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={true}
                                                allowSelectAll={true}
                                                components={{
                                                    Option,
                                                    ValueContainer: ValueContainer,
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 rounded form-group">
                                            <label><span>{hierarchyData.c4}</span><span className="mandatory">*</span></label>
                                            <Select options={optionsC4}
                                                value={defaultValueC4 !== '' ? optionsC4.find((option) => option.value === defaultValueC4) : ''}
                                                id="optionsC4Id"
                                                isMulti
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}
                                                isSearchable={true} required={true}
                                                // onChange={(e) => handleC5Change(e)}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isClearable={true}
                                                allowSelectAll={true}
                                                components={{
                                                    Option,
                                                    ValueContainer: ValueContainer,
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 rounded form-group">
                                            <label><span>Form</span><span className="mandatory">*</span></label>
                                            {/* <select className="form-control" required="" onChange={(e) => setFormName(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {formData.map((i, j) => {
                                                        return (<option value={`${i.form_name}||${i.form_version}`}>{i.display_name}{' (V '}{i.form_version}{'.0)'}</option>)
                                                    })}
                                                </select> */}
                                            <Select options={allForms}
                                                value={formName !== '' ? allForms.find((option) => option.value === formName) : ''}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderRadius: '100px',
                                                        boxShadow: 'none', // Remove the blue box shadow
                                                        borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                        '&:hover': {
                                                            borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                        },
                                                        '&:focus': {
                                                            outline: 'none', // Remove the blue outline on focus
                                                            borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                            boxShadow: 'none', // Remove the blue box shadow on focus
                                                        },
                                                        '&:active': {
                                                            borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                            boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                        },
                                                    }),
                                                    indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                        color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options
                                                    }),

                                                }}
                                                isSearchable={true} required={true} onChange={(e) => setFormName(e.value)} />
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                                            <label><span>Form Display Name</span><span className="mandatory">*</span></label>
                                            <input type='text' className='form-control' value={formDisplayName} onChange={(e) => setFormDisplayName(e?.target?.value)} placeholder='Form Display Name' style={{ height: "38px" }} />
                                        </div>

                                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                                            <label><span>Form Effective Date</span><span className="mandatory">*</span></label>
                                            <input type='date' className='form-control' value={formEffectiveDate} onChange={(e) => setFormEffectiveDate(e?.target?.value)} max={moment().format("YYYY-MM-DD")} placeholder='Form Effective Date' style={{ height: "38px" }} />
                                        </div>

                                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                                            <label><span>Feedback Tat</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control" placeholder="Feedback Tat" value={feedbackValue} onInput={(e) => handleFeedbackTat(e, "feedback_tat")} style={{ height: "38px" }} />
                                            {feedbackTatError && <span style={{ color: 'red' }}>{feedbackTatError}</span>}

                                        </div>

                                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                                            <label><span>Pass Rate</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control" placeholder="Pass Rate" value={passRateValue} onInput={(e) => handleFeedbackTat(e, "pass_rate")} style={{ height: "38px" }} />
                                            {passRateError && <span style={{ color: 'red' }}>{passRateError}</span>}

                                        </div>

                                        <div className="col-sm-3 col-md-3 col-lg-3 form-group">
                                            <label><span>Form Weight</span><span className="mandatory">*</span></label>
                                            <input type="text" className="form-control" placeholder="Form Weight" value={formWeightValue} onInput={(e) => handleFeedbackTat(e, "form_weight")} style={{ height: "38px" }} />
                                            {formWeightError && <span style={{ color: 'red' }}>{formWeightError}</span>}

                                        </div>

                                        <div className="col-sm-3 col-md-3 col-lg-3 form-group my-4">
                                            <button type='submit' className='btn btn-primary w-100 h-100' onClick={() => handleFormCreation()}>Create</button>


                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    )
}

export default FormDetails