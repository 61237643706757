import React, { useEffect } from "react";
import { useNavigate, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Login from "../auth/loginComponent";
import { ErrorNotify, SuccessNotify, DismissToast } from "./Toast";
import Select from "react-select";


const Header = (props) => {

    const navigate = useNavigate();
    const base_name = process.env.PUBLIC_URL;
    const base_url = process.env.REACT_APP_AUTH_BASE_URL;

    // const sessionRefresh = async() => {
    //     var requestOptions = {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
    //         },
    //     };
    //     await fetch(`${base_url}refresh`, requestOptions).then(res => res.json()).then(data => {
    //         console.log("DDDDDDDDDDDDD".data);

    //     })
    // }
    // let expiryT = localStorage.getItem('expiryTime')
    // setInterval(() => {

    //      const checkTokenAndRedirect = () =>{
    //         const loginToken = localStorage.getItem('loginToken');

    //         if (!loginToken) {

    //         }
    //       } 
    //       checkTokenAndRedirect()

    // }, 1000);

    // var url = `${base_url}hierarchy/show`;
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({
    //             'id': '',
    //             'token': localStorage.getItem('loginToken')
    //         })
    //     };

    // setInterval(async() => {
    //     await fetch(url, requestOptions).then((res) => res.json()).then(data => {
    //         console.log("data.status",data.status);
    //         if (data.status === "Token is Expired") {
    //             localStorage.clear();
    //             window.location.href = `${process.env.PUBLIC_URL}`;

    //         }
    //     })
    // }, 120000);

    // window.addEventListener("click",checkTokenAndRedirect);
    // window.addEventListener("keypress",checkTokenAndRedirect)
    // window.addEventListener("scroll",checkTokenAndRedirect)
    // window.addEventListener("mousemove",checkTokenAndRedirect)

    useEffect(() => {
        if (props?.isLoggedIn === null) {
            logout()
        }
    }, [props?.isLoggedIn])


    const logout = () => {
        var url = `${base_url}logout`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            if (data?.status == 200 || data?.status == 201) {
                SuccessNotify("Logout Succesfully", {
                    icon: "success"
                });
            }
            else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    localStorage.clear()
                    navigate("/");
                    return;
                }
                else {
                    ErrorNotify(data.message, {
                        icon: "error",
                    });
                    return;
                }
            }
            else {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
        }).catch(err => {
            DismissToast()
            ErrorNotify("Something went wrong, Please contact to Administrator !", {
                icon: "error",
            });
            return;
        });
        localStorage.clear();
        props.isLoggedIn(null)
        // window.location.replace(`/`);
        navigate(`/`);
        // window.location.reload();

    }

    useEffect(() => {
        if (localStorage.getItem('loginData') !== null && JSON.parse(localStorage.getItem("loginData")) != '') {
            // console.log('loginData=======', (JSON.parse(localStorage.getItem('loginData')).role_details.user_group ))
        }
    }, []);

    const proxyList = JSON.parse(localStorage.getItem("proxyAccess"))?.map((item) => ({
        value: item?.userId,
        label: item?.name
    }));

    const getProxyDetails = (name,userId) => {
        if(!userId){
            return ErrorNotify("User id not found")
        }
        if(userId===""){
            return ErrorNotify("User id not found")
        }
        var url = `${base_url}proxy-user`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                user_id: userId
            })

        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            console.log('proxy_Users_Details', data);


            if (data?.status == 200 || data?.status == 201) {
                localStorage.setItem("settedProxy", JSON.stringify({label:name,value:userId}));
                localStorage.setItem("proxyUserModule", JSON.stringify(data.moduleName));
                localStorage.setItem("proxyName", data.name);
                localStorage.setItem("proxyRoleId", data.roleId);
                localStorage.setItem("proxyUserEmail", data.userEmail);
                localStorage.setItem("proxyUserId", data.userId);
                localStorage.setItem("proxyUserName", data.username);
                localStorage.setItem("proxyRoleDetails", JSON.stringify(data.roleDetails));
                localStorage.setItem("proxyUserRole", data.proxyUserRole);
    
                setTimeout(() => {
                    return SuccessNotify(`Proxy Successfully switched inside ${name} profile!`)
                }, 1000);
                
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
    
              } else if (data?.status >= 400 || data?.status <= 422) {
                if (data?.status == 401) {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  localStorage.clear();
                  navigate("/");
                  return;
                } else {
                  ErrorNotify(data.message, {
                    icon: "error",
                  });
                  return;
                }
              } else {
                ErrorNotify(data.message, {
                  icon: "error",
                });
                return;
              }
          
        }).catch((err) => {
          DismissToast();
          ErrorNotify("Something went wrong. Please contact to Administrator !", {
            icon: "error",
          });
          return;
        })
    }
    const exitProxy = () => {
            localStorage.removeItem("proxyUserModule");
            localStorage.removeItem("proxyName");
            localStorage.removeItem("proxyRoleId");
            localStorage.removeItem("proxyUserEmail");
            localStorage.removeItem("proxyUserId");
            localStorage.removeItem("proxyUserName");
            localStorage.removeItem("settedProxy");
            localStorage.removeItem("proxyRoleDetails");
            localStorage.removeItem("proxyUserRole");
            setTimeout(() => {
                window.location.reload()
                
            }, 1000);
    }
    return (
        localStorage.getItem('loginToken') !== null && (
            <header className="ip-gradient">
                <div className="container-fluid">

                    <div className="row justify-content-between align-items-center">

                        <div className="col-auto">
                            <button type="button" className="sb-toggle-btn">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        {console.log("XXXXXXXXXX", localStorage.getItem("proxyAccess"))}


                        <div className="col-auto d-flex align-items-center">
                            {
                                localStorage.getItem("proxyAccess") !== "null" &&
                                <div className="d-flex align-items-center" style={{ marginLeft: "10px" }}>
                                    <b>Proxy Access :</b>
                                    {console.log("LKKLKLKLLKLKLK", JSON.parse(localStorage.getItem("settedProxy")))}
                                    <Select
                                        options={proxyList}
                                        defaultValue={
                                            localStorage.getItem("proxyUserName")!==null && 
                                            localStorage.getItem("proxyUserId")!==null &&
                                            JSON.parse(localStorage.getItem("settedProxy"))!==undefined && 
                                            [{label:JSON.parse(localStorage.getItem("settedProxy"))?.label,value:JSON.parse(localStorage.getItem("settedProxy"))?.value}]
                                        }
                                        className="mx-4"
                                        placeholder="Select Proxy"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: '100px',
                                                width: '200px',
                                                boxShadow: 'none', // Remove the blue box shadow
                                                borderColor: '#ccc', // Set a custom border color, or use 'transparent' if you don't want any border color
                                                '&:hover': {
                                                    borderColor: '#ccc', // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover

                                                },
                                                '&:focus': {
                                                    outline: 'none', // Remove the blue outline on focus
                                                    borderColor: '#ccc', // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                    boxShadow: 'none', // Remove the blue box shadow on focus
                                                },
                                                '&:active': {
                                                    borderColor: '#ccc', // Add this to handle the blue outline when an option is selected
                                                    boxShadow: 'none', // Add this to handle the blue box shadow when an option is selected
                                                },
                                            }),
                                            indicatorSeparator: (provided) => ({ ...provided, display: 'none', }),
                                            option: (provided, state) => ({
                                                ...provided,
                                                background: state.isSelected ? '#fff' : 'transparent', // Remove background highlight from non-selected options
                                                color: state.isSelected ? '#000' : '#333', // Change text color of non-selected options


                                            }),

                                        }}
                                        onChange={(e) => getProxyDetails(e?.label,e?.value)}
                                    />
                                    {
                                        localStorage.getItem("proxyUserModule")!==null&&
                                    
                                    <span style={{backgroundColor:"orange",cursor:"pointer",color:"black",padding:"7px",paddingTop:"1px",borderRadius:"100px",height:"25px"}} onClick={()=>exitProxy()}><span>Exit Proxy </span><i class="las la-sign-out-alt" style={{fontSize:"18px"}}></i></span>
                                    }
                                    </div>
                            }

                            <div className="header-notification mx-4">
                                <button type="button" className="notification-trigger">
                                    <i className="las la-bell"></i>
                                </button>
                                <div className="notification-list">
                                    <ul>
                                        <li className="text-center">
                                            <p className="mb-0">There is no notification</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dropdown user-dropdown">
                                <button className="btn dropdown-toggle profile-button" type="button" data-toggle="dropdown" aria-expanded="false" title={localStorage.getItem('loginUserUniqueid')}>
                                    <span className="avatar-left">
                                        <span className="avatar-text">{localStorage.getItem('proxyUserName') !== null && localStorage.getItem('proxyUserName') !== undefined ? localStorage.getItem('proxyUserName'):localStorage.getItem('loginUserName')}</span>
                                        <span className="small-text avatar-text">{JSON.parse(localStorage.getItem('proxyRoleDetails')) !== null && JSON.parse(localStorage.getItem('proxyRoleDetails')) !== undefined ? JSON.parse(localStorage.getItem('proxyRoleDetails'))?.user_group + ' ' + JSON.parse(localStorage.getItem('proxyRoleDetails'))?.user_type  :  localStorage.getItem('loginData') !== null && JSON.parse(localStorage.getItem("loginData")) != '' && JSON.parse(localStorage.getItem('loginData')).role_details !== undefined ? JSON.parse(localStorage.getItem('loginData')).role_details.user_group + ' ' + JSON.parse(localStorage.getItem('loginData')).role_details.user_type : ''}</span>
                                    </span>
                                    <span className="avatar-status avatar-online"><img src={`${base_name}/assets/bootstrap/images/user_pic.png`} alt="avatar" /></span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="usermenu">
                                    <li><Link class="dropdown-item" to="/profile"><i className="las la-user"></i><span>Profile</span></Link></li>
                                    <li><a onClick={logout} className="dropdown-item"><i className="las la-sign-out-alt"></i><span>Logout</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    )
}

export default Header;