import React, { useState } from "react";
import { useEffect } from "react";
import swal from 'sweetalert';
import AWS from 'aws-sdk'
import { useNavigate } from "react-router-dom";
import OverlayPopover from '../../../controls/OverlayPopover'
import Popover from 'react-bootstrap/Popover';
import { ErrorNotify, SuccessNotify } from "../../../shared/Toast";
import { ToastContainer } from "react-toastify";
import MultiSelection from "../../../controls/MultiSelect";
import DataTableView from "../../../shared/DataTable";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import $, { removeData } from 'jquery';
import { TabTitle } from "../../../shared/GenralFunctioin/TableTitle";
import ModalShow from '../../../controls/Modal'
import Select from 'react-select';
import { components } from "react-select";




const base_url = process.env.REACT_APP_AUTH_BASE_URL;
let modelArr1 = [];
let modelArr2 = [];
let labelAndAppIdFolder = [];
let onlyAppId = [];
var finalModalArr = {};
var advancedSettingsArr = [];
var callDeliveryString = '';
let metaContent = []
var csvReport = {
    data: [],
};
var pdfReport = {
    data: [],
};
var xlsReport = {
    data: [],
};

// var automaticallyTypeArr = [];

const SSAccounts = () => {
    TabTitle('SmartSpeech-Folder :: NEQQO');

    var checked = '';
    const navigate = useNavigate();

    const [modalVal, setModalVal] = useState('');
    const [modalVal2, setModalVal2] = useState('');
    const [folderUpdationData, setFolderUpdationData] = useState([]);

    const [folderName, setFolderName] = useState('');
    const [speakersValue, setSpeakersValue] = useState('');
    const [defaultSpeakersValue, setDefaultSpeakersValue] = useState('');
    const [PCIText, setPCIText] = useState('');
    const [PCIAudio, setPCIAudio] = useState(false);
    const [audioType, setAudioType] = useState('');
    const [appName, setAppName] = useState([]);
    const [modelValue, setModelValue] = useState('');

    const [channelLeftValue, setChannelLeftValue] = useState('');
    const [channelRightValue, setChannelRightValue] = useState('');
    const [channelValue, setChannelValue] = useState('');

    const [appListData, setAppListData] = useState([]);
    const [langList, setLangList] = useState([]);
    const [folderList, setFolderList] = useState([]);

    const [fileTypeIdsArr, setFileTypeIdsArr] = useState([]);
    const [automaticallyTypeArr, setAutomaticallyTypeArr] = useState([]);

    const [CCDMCheckedValue, setCCDMCheckedValue] = useState(true);

    const [file, setFile] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [pending, setPending] = React.useState(true);
    const [isLoading, setLoading] = useState(false);
    const [callIdArr, setCallIdArr] = useState([]);
    const [folderID, setFolderID] = useState('');
    const [isDataFound, setIsDataFound] = useState(false);

    const [from_date, setFromDate] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'));
    const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'));

    const [callDeliveryMethod, setCallDeliveryMethod] = useState('');
    const [callDeliveryValue, setCallDeliveryValue] = useState('');
    const [callDeliveryMethodStr, setCallDeliveryMethodStr] = useState('');

    const [sftp_data, setSFTPData] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [sSHPrivatekey, setSSHPrivatekey] = useState('');

    const [awsdata, setAWSData] = useState([]);
    const [aWSAccesskey, setAWSAccesskey] = useState('');
    const [aWSSecretAccesskey, setAWSSecretAccesskey] = useState('');
    const [aWSRegion, setAWSRegion] = useState('');

    const [oauthdata, setOAuthData] = useState([]);
    const [clientID, setClientID] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [grantType, setGrantType] = useState('');
    const [tokenUrl, setTokenUrl] = useState('');
    const [alreadySelected, setAlreadySelected] = useState([]);

    const [keyData, setkeyData] = useState('');
    const [cmfKeyData, setCmfKeyData] = useState('');
    const [valueData, setValueData] = useState('');
    const [asrOptionsData, setAsrOptionsData] = useState([]);
    const [cmfOptionsData, setCmfOptionsData] = useState([]);
    const [folderNameValidation, setFolderNameValidation] = useState('');
    const [showCheckBoxModal, setShowCheckBoxModal] = useState('');
    const [renderData, setRenderData] = useState('');
    const [downloadFileLoading, setDownloadFileLoading] = useState(false);
    const [downloadFolderName, setDownloadFolderName] = useState('');
    const [metaFile, setMetaFile] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [dataArray, setDataArray] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [modalBool, setModalBool] = useState(false);
    const [bucketInfo, setBucketInfo] = useState([]);
    const [appData, setAppData] = useState([]);
    const [applicationValue, setApplicationValue] = useState([]);


    const ValueContainer2 = ({ children, ...props }) => {
        const { getValue } = props;
        let selectedOption = getValue().length;

        if (applicationValue && applicationValue?.length > 0) {
            return (
                <components.ValueContainer {...props}>
                    <span style={{ fontSize: "12px" }}>{`${applicationValue?.length} selected`}</span>
                </components.ValueContainer>
            );
        } else {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
    };


    const Option = (props) => {

        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    useEffect(() => {
        if (localStorage.getItem('loginToken') === '' || localStorage.getItem('loginToken') === null) {
            localStorage.clear();
            navigate("/");
            //window.location.reload();
        }

    }, []);

    useEffect(() => {
        var url = `${base_url}folder/send-bucket-credentials`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'get-bucket-credentials');

    }, [])




    const Logout = () => {
        localStorage.clear();
        navigate("/");
        window.location.reload();
    }
    // useEffect(() => {
    //     const key = "AKIA5DE6DN3A7TZYFZXO";
    //     const secret = "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";
    //     const region = "ap-south-1";
    //     const bucket = "vocisample";

    //     AWS.config.update({
    //         key,
    //         secret,
    //         region,
    //     });

    //     const s3 = new AWS.S3({ apiVersion: 'latest' });
    //     console.log("SESESE",s3);
    //     const listObjects = async () => {
    //     try {
    //         const data = await s3.listObjects({ Bucket: bucket }).promise();
    //         console.log('Objects in the bucket:', data.Contents);
    //     } catch (error) {
    //         console.error('Error listing objects:', error);
    //     }
    //     };

    //     listObjects();

    // }, []);

    useEffect(() => {
        if (!CCDMCheckedValue) {
            setCallDeliveryMethod(folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0]);
            setCallDeliveryValue(folderUpdationData?.call_back_delivery_method?.split('.')[1]);
            setCallDeliveryMethodStr(folderUpdationData?.call_back_delivery_method?.split()[0]);
            automaticallyTypeArr.push([]);

            setUsername('');
            setPassword('');
            setSSHPrivatekey('');

            setAWSAccesskey('');
            setAWSSecretAccesskey('');
            setAWSRegion('');
            setClientID('');
            setClientSecret('');
            setGrantType('');
            setTokenUrl('');
        }
    }, [CCDMCheckedValue])

    useEffect(() => {
        var url = `${base_url}folder/all-application-list`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'app-list');

        var url = `${base_url}folder/all-language-list`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'lang-list');

        var url = `${base_url}folder/folder-list`;

        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };

        callApi(url, requestOptions, 'folder-list');

    }, []);

    // for uplaoding calls 
    const uploadFileData = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('userId', localStorage.getItem('loginUserUniqueid'));
        data.append('folder_id', folderID);

        // setIsDataFound(true);
        if (file.length > 0) {
            // if (file.length > 5) {
            //     ErrorNotify('You can upload upto 5 files at a time');
            //     return;
            // }
            // alert(file[0].size / 1024)
            // const fileSize = file[0].size
            if (file[0].size <= 1024) {
                ErrorNotify('You can upload upto files of 1GB at a time');
                return;
            }
            Object.keys(file).map((i, j) => {
                let fileType = file[i].name.split('.').pop();
                if (fileType !== 'zip') {
                    setIsDataFound(false);
                    ErrorNotify(`'${fileType}' file type is not supported! Please select only zip file types.`);
                    return;
                }
                setIsDataFound(true);
                data.append('files[]', file[i])
            })
        } else {
            setIsDataFound(false);
            ErrorNotify('Please select file');
            return;
        }

        var url = `${base_url}callspeech/uploadmultiplefile`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: data
        };
        //callApi(url, requestOptions, 'upload-call');

    }

    const handleFileSelect = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('userId', localStorage.getItem('loginUserUniqueid'));
        data.append('folder_id', folderID);

        if (file.length > 0) {
            let isFileTypeErrorShown = false;
            let typeOfFile = '';
            for (let i = 0; i < file.length; i++) {
                let totalFileSize = 0;

                Object.keys(file).map((i, j) => {
                    let fileType = file[i].name.split('.').pop();
                    if (!['zip', 'wav', 'mp3'].includes(fileType)) {
                        setIsDataFound(false);
                        isFileTypeErrorShown = true;
                        typeOfFile = fileType

                        return;
                    }
                    setIsDataFound(true);
                    const currentFileSize = file[j]?.size;
                    totalFileSize += currentFileSize;
                    //data.append('files[]', file[i])

                })

                if (totalFileSize >= 1024 * 1024 * 1024) {
                    // File size exceeds 1 GB
                    ErrorNotify('File size should not exceed 1GB!');
                    return;
                }


            }
            Object.values(file).forEach((i) => {
                data.append('files[]', i);
            });
            if (isFileTypeErrorShown === true && typeOfFile !== '') {
                ErrorNotify(`'${typeOfFile}' file type is not supported! Please select only zip,wav and mp3 file types.`);
            }
        } else {
            // No files selected
            setIsDataFound(false);
            ErrorNotify('Please select at least one file.');
            return;
        }
        const url = `${base_url}callspeech/uploadmultiplefile`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: data
        };
        callApi(url, requestOptions, 'upload-call');
    };


    // application names pushed into arr
    const appNameFunc = (val) => {
        setAppName(val)
    }

    // open modal
    const openModal = (id, is_checked) => {

        if (is_checked) {
            $(`#${id}_input`).attr('data-toggle', 'modal');
            $(`#${id}_input`).attr('data-target', `#${id}`);


        } else {
            $(`#${id}_input`).removeAttr('data-toggle', 'modal');
            $(`#${id}_input`).removeAttr('data-target', `#${id}`);

            // automaticallyTypeArr.push([]);
            // setAutomaticallyTypeArr([])
        }
    }
    const openModal2 = (id, is_checked) => {
        if (document.querySelector('#edit_ccdm2_input').checked || !document.querySelector('#edit_ccdm2_input').checked) {
            $(`#edit_${id}_input`).attr('data-toggle', 'modal');
            $(`#edit_${id}_input`).attr('data-target', `#${id}`);



        } if (document.querySelector('#edit_cmf2_input').checked || !document.querySelector('#edit_cmf2_input').checked) {
            $(`#edit_${id}_input`).attr('data-toggle', 'modal');
            $(`#edit_${id}_input`).attr('data-target', `#${id}`);


        } if (document.querySelector('#edit_arcm2_input').checked || !document.querySelector('#edit_arcm2_input').checked) {
            $(`#edit_${id}_input`).attr('data-toggle', 'modal');
            $(`#edit_${id}_input`).attr('data-target', `#${id}`);


        } else {
            $(`#edit_${id}_input`).removeAttr('data-toggle', 'modal');
            $(`#edit_${id}_input`).removeAttr('data-target', `#${id}`);

            // automaticallyTypeArr.push([]);
            // setAutomaticallyTypeArr([])
        }
    }

    // handle advanced settings value
    const getCheckBoxSubValues = (val, isChecked = '') => {
        if (isChecked) {
            checked = 'checked';
            advancedSettingsArr.push(val);
        } else {
            checked = '';
            let index = advancedSettingsArr.indexOf(val);
            if (index > -1) {
                advancedSettingsArr.splice(index, 1);
            }
            setCallDeliveryMethod('https://')
            setUsername('')
            setPassword('')
            setSSHPrivatekey('')

            setAWSAccesskey('')
            setAWSSecretAccesskey('')
            setAWSRegion('')

            setClientID('')
            setClientSecret('')
            setGrantType('')
            setTokenUrl('')
        }
        // console.log('advancedSettingsArr-------', advancedSettingsArr)
    }
    const getCheckBoxSubValues2 = (val, isChecked = '') => {
        if (isChecked) {
            checked = 'checked';
            advancedSettingsArr.push(val);
        } else {
            checked = '';
            let index = advancedSettingsArr.indexOf(val);
            if (index > -1) {
                advancedSettingsArr.splice(index, 1);
            }
            setCallDeliveryMethod('https://')
            setUsername('')
            setPassword('')
            setSSHPrivatekey('')

            setAWSAccesskey('')
            setAWSSecretAccesskey('')
            setAWSRegion('')

            setClientID('')
            setClientSecret('')
            setGrantType('')
            setTokenUrl('')
        }
        // console.log('advancedSettingsArr-------', advancedSettingsArr)
    }

    // handle update folder
    const handleUpdateFolder = () => {
        let uniqueAppName = appName?.filter((i, n) => appName.indexOf(i) !== n)
        console.log("uniqueAppName", uniqueAppName);
        if(applicationValue?.length===0){
            return ErrorNotify("Please select an application")
          }
        var url = `${base_url}folder/update-folder`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'folder_name': folderName !== '' ? folderName : folderUpdationData.folder_name,
                'created_by': localStorage.getItem('loginUserUniqueid'),
                'speaker_number': speakersValue !== '' ? speakersValue : folderUpdationData.speaker_number,
                'audio_type': audioType !== '' ? audioType : folderUpdationData.audio_type,
                'pci_redact_audio': folderUpdationData.pci_redact_audio,
                'pci_redact_text': folderUpdationData.pci_redact_text,
                'agent_channel_value': channelValue !== '' ? channelValue : folderUpdationData.agent_channel_value,
                'agent_channel_name': channelValue !== '' ? channelValue : folderUpdationData.agent_channel_value,
                'application': uniqueAppName?.length > 0 ? uniqueAppName : appName,
                'advanced_settings': advancedSettingsArr !== '' ? advancedSettingsArr : folderUpdationData.advanced_settings,
                'model': finalModalArr.model !== undefined ? finalModalArr.model : folderUpdationData.model,

                'automatically_send_type': automaticallyTypeArr !== '' ? automaticallyTypeArr : folderUpdationData.automatically_send_type,
                'call_back_delivery_method': callDeliveryMethodStr !== '' ? callDeliveryMethodStr : folderUpdationData.call_back_delivery_method,
                'sftp_data': sftp_data !== undefined ? sftp_data : folderUpdationData.sftp_data,
                'awsdata': awsdata !== undefined ? awsdata : folderUpdationData.awsdata,
                'oauthdata': oauthdata !== undefined ? oauthdata : folderUpdationData.oauthdata,
                'asr': asrOptionsData !== undefined ? asrOptionsData : folderUpdationData.asrOptionsData,
                //'matadata': cmfOptionsData !== undefined ? cmfOptionsData : folderUpdationData.cmfOptionsData,
                'matadata': metaContent.length > 0 ? metaContent.split(",") : [],
                'folder_id': folderID

            })
        };
        if (folderNameValidation) {
            ErrorNotify('Folder name Only contain A-Z 0-9 with no spaces');
            return;
        }
        if (folderName === "") {
            ErrorNotify('Please enter folder name');
            return;
        }
        if (speakersValue === '') {
            ErrorNotify('Please select speakers value');
            return;
        }
        if (defaultSpeakersValue === '') {
            ErrorNotify('Please select speakers value');
            return;
        }
        if (audioType === 'Select') {
            ErrorNotify('Please select audio type value');
            return;
        }
        else {

            callApi(url, requestOptions, 'update-folder-data');
        }
    }

    // handle creating folder api
    const handleCreateFolder = () => {
        if (folderName === '') {
            ErrorNotify('Please enter folder name');
            return;
        }
        if (folderNameValidation) {
            ErrorNotify('Folder name Only contain A-Z 0-9 with no spaces');
            return;
        }
        if (speakersValue === '') {
            ErrorNotify('Please enter speakers value');
            return;
        }
        if (audioType === '') {
            ErrorNotify('Please enter audio type value');
            return;
        } if (audioType === 'Select') {
            ErrorNotify('Please enter audio type value');
            return;
        } else {
            if (audioType === 'mono') {
                modelArr2 = [];
            }
        }
        // if (!appName.length) {
        //     ErrorNotify('Please enter application name');
        //     return;
        // }
        if (modelValue === 'Select') {
            ErrorNotify('Please enter model value');
            return;
        }
        if (modelValue === '') {
            ErrorNotify('Please enter model value');
            return;
        } else {
            let ModelArr = [];
            if (modelArr1.length && modelArr2.length) {
                ModelArr.push(modelArr1[0], modelArr2[0]);
            } else {
                if (modelArr1.length) {
                    ModelArr.push(modelArr1[0]);
                }
                if (modelArr2.length) {
                    ModelArr.push(modelArr2[0]);
                }
            }
            finalModalArr = { 'model': ModelArr }
        }
        var url = `${base_url}folder/add-folder`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                'folder_name': folderName,
                'created_by': localStorage.getItem('loginUserUniqueid'),
                'speaker_number': speakersValue,
                'audio_type': audioType,
                'pci_redact_audio': PCIAudio,
                'pci_redact_text': PCIText,
                'agent_channel_value': channelValue,
                'agent_channel_name': channelValue,
                'application': appName,
                'advanced_settings': advancedSettingsArr,
                'model': finalModalArr.model,

                'automatically_send_type': automaticallyTypeArr,
                'call_back_delivery_method': callDeliveryMethodStr,
                'sftp_data': sftp_data,
                'awsdata': awsdata,
                'oauthdata': oauthdata,
                'asr': asrOptionsData,
                'matadata': metaContent.length > 0 ? metaContent.split(",") : []
            })
        };

        callApi(url, requestOptions, 'add-folder');
    }

    // create model arr    
    const modelFunc1 = (lang_id, val, langVal) => {
        modelArr1 = [];
        modelArr1.push({
            'model_name': val,
            'model_value': val,
            'language_id': lang_id,
            'language_name': langVal,
        });
        finalModalArr = { 'model': [{ language_id: lang_id, language_name: langVal }] }
    }

    // set automatically values
    const setAutomaticallyType = (type, is_checked) => {
        if (is_checked) {
            automaticallyTypeArr.push(type);
        } else {
            let index = automaticallyTypeArr.indexOf(type);
            if (index > -1) {
                automaticallyTypeArr.splice(index, 1);
            }
        }
    }

    // adding cmf options function
    const addCMFOptions = () => {

        console.log("1111111111111", metaContent);

        const metaContentArray = metaContent.length > 0 ? metaContent.split(",") : [];
        metaContentArray.push(cmfKeyData);
        folderUpdationData?.metadata?.length > 0 && folderUpdationData?.metadata?.push(cmfKeyData)

        // Now, metaContentArray contains the updated array with cmfKeyData added to the end.
        // If you want to update metaContent with the modified array, use the following line:
        metaContent = metaContentArray.join(",");


        setCmfKeyData("")
        setTimeout(() => {
            document.getElementById("custom_key").value = ""
        }, 1000);

    }

    const removeCMFOptions = (val) => {
        console.log("222222222222");
        if (metaContent && metaContent.length > 0) {
            const metaContentArray = metaContent.length > 0 ? metaContent.split(",") : [];
            const indexToRemove = metaContentArray.indexOf(val);

            if (indexToRemove !== -1) {
                metaContentArray.splice(indexToRemove, 1); // Remove 1 element at the indexToRemove
                metaContent = metaContentArray.join(","); // Update metaContent with the modified array
            }
            console.log("metaContent", metaContent);
        }
    };



    // adding ase options function
    const addASROptions = () => {
        asrOptionsData.push(`${keyData}:${valueData}`);
    }

    // remove asr options function
    const removeASROptions = (val) => {
        let index = asrOptionsData.indexOf(val);
        if (index > -1) {
            asrOptionsData.splice(index, 1);
        }
    }

    // reset values
    const resetAllValues = (val) => {
        if (val === 'https://' || val === 'http://' || val === 'File system') {
            setUsername('')
            setPassword('')
            setSSHPrivatekey('')

            setAWSAccesskey('')
            setAWSSecretAccesskey('')
            setAWSRegion('')

            setClientID('')
            setClientSecret('')
            setGrantType('')
            setTokenUrl('')
        }
        if (val === 'SFTP') {
            setAWSAccesskey('')
            setAWSSecretAccesskey('')
            setAWSRegion('')

            setClientID('')
            setClientSecret('')
            setGrantType('')
            setTokenUrl('')
        }
        if (val === 'AWS S3') {
            setUsername('')
            setPassword('')
            setSSHPrivatekey('')

            setClientID('')
            setClientSecret('')
            setGrantType('')
            setTokenUrl('')
        }

        if (val === 'OAuth https://') {
            setUsername('')
            setPassword('')
            setSSHPrivatekey('')

            setAWSAccesskey('')
            setAWSSecretAccesskey('')
            setAWSRegion('')
        }

    }

    useEffect(() => {
        if (folderUpdationData?.advanced_settings !== undefined) {

            const ccdmInput = document.querySelector('#edit_ccdm2_input');
            const cmfInput = document.querySelector('#edit_cmf2_input');
            const arcmInput = document.querySelector('#edit_arcm2_input');
            const rdrInput = document.querySelector('#edit_rdr2_input');
            const checkbox_opt_00012 = document.querySelector('#checkbox_opt_00012');
            const checkbox_opt_00022 = document.querySelector('#checkbox_opt_00022');
            const checkbox_opt_00032 = document.querySelector('#checkbox_opt_00032');
            const cbdelivery = document.querySelector('#cbdelivery');
            const port = document.querySelector('#port');
            ccdmInput.checked = folderUpdationData?.advanced_settings?.includes("Configure callback delivery method");
            cmfInput.checked = folderUpdationData?.advanced_settings?.includes("Add/remove custom metadata fields");
            arcmInput.checked = folderUpdationData?.advanced_settings?.includes("Add/remove ASR options");
            rdrInput.checked = folderUpdationData?.advanced_settings?.includes("Reject duplicate records by filename");
            checkbox_opt_00012.checked = folderUpdationData?.automatically_send_type?.includes("json");
            checkbox_opt_00022.checked = folderUpdationData?.automatically_send_type?.includes("text");
            checkbox_opt_00032.checked = folderUpdationData?.automatically_send_type?.includes("wav");
            if (folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0] === "http") {
                cbdelivery.value = "http://"
            }
            if (folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0] === "https") {
                cbdelivery.value = "https://"
            }
            if (folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0] === "AWS S3") {
                cbdelivery.value = "AWS S3"
            }
            if (folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0] === "SFTP") {
                cbdelivery.value = "SFTP"
            }
            if (folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0] === "File system") {
                cbdelivery.value = "File system"
            }
            if (folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0] === "OAuth https") {
                cbdelivery.value = "OAuth https://"
            }
            port.value = folderUpdationData?.call_back_delivery_method?.split('.')[1] ? folderUpdationData?.call_back_delivery_method?.split('.')[1] : `${folderUpdationData?.call_back_delivery_method?.split(':')[1]}${folderUpdationData?.call_back_delivery_method?.split(':')[2]}`
            if (port.value === "undefinedundefined") {
                port.value = ""
            }
        }
    }, [folderUpdationData]);

    useEffect(() => {
        if (folderUpdationData?.aws_access_details !== undefined) {
            const awsaccesskey = document.querySelector('#awsaccesskey');
            const awssecretkey = document.querySelector('#awssecretkey');
            const region = document.querySelector('#region');
            if (awsaccesskey !== null && awssecretkey !== null && region !== null) {
                setCallDeliveryValue('AWS S3')
                awsaccesskey.value = folderUpdationData?.aws_access_details[0]?.key_id === undefined ? '' : folderUpdationData?.aws_access_details[0]?.key_id
                awssecretkey.value = folderUpdationData?.aws_access_details[0]?.secret_key === undefined ? '' : folderUpdationData?.aws_access_details[0]?.secret_key
                region.value = folderUpdationData?.aws_access_details[0]?.region === undefined ? '' : folderUpdationData?.aws_access_details[0]?.region
            }
        }

        if (folderUpdationData?.oauth_details !== undefined) {
            setCallDeliveryValue('OAuth https://')
            const clientid = document.querySelector('#clientid');
            const clientsecret = document.querySelector('#clientsecret');
            const grantype = document.querySelector('#grantype');
            const tokenurl = document.querySelector('#tokenurl');
            if (clientid !== null && clientsecret !== null && grantype !== null) {
                clientid.value = folderUpdationData?.oauth_details[0]?.client_id === undefined ? '' : folderUpdationData?.oauth_details[0]?.client_id
                clientsecret.value = folderUpdationData?.oauth_details[0]?.client_secret === undefined ? '' : folderUpdationData?.oauth_details[0]?.client_secret
                grantype.value = folderUpdationData?.oauth_details[0]?.grant_type === undefined ? '' : folderUpdationData?.oauth_details[0]?.grant_type
                tokenurl.value = folderUpdationData?.oauth_details[0]?.token_url === undefined ? '' : folderUpdationData?.oauth_details[0]?.token_url

            }

        } if (folderUpdationData?.sftp_details !== undefined) {
            setCallDeliveryValue('SFTP')
            const username = document.querySelector('#username');
            const password = document.querySelector('#password');
            const sshprivate = document.querySelector('#sshprivate');
            if (username !== null && password !== null && sshprivate !== null) {
                username.value = folderUpdationData?.sftp_details[0]?.username === undefined ? '' : folderUpdationData?.sftp_details[0]?.username
                password.value = folderUpdationData?.sftp_details[0]?.password === undefined ? '' : folderUpdationData?.sftp_details[0]?.password
                sshprivate.value = folderUpdationData?.sftp_details[0]?.ssh_private_key === undefined ? '' : folderUpdationData?.sftp_details[0]?.ssh_private_key

            }
        }

    }, [showCheckBoxModal, renderData])

    const handleModalValidation = () => {
        if (modalVal === "ccdm") {
            document.querySelector('#ccdm_input').checked = false;
        }
        if (modalVal === "cmf") {
            document.querySelector('#cmf_input').checked = false;
        }
        if (modalVal === "arcm") {
            document.querySelector('#arcm_input').checked = false;
        }
    }

    const handleModalValidation2 = () => {

        if (modalVal2 === "ccdm2" && document.querySelector('#edit_ccdm2_input').checked) {
            document.querySelector('#edit_ccdm2_input').checked = false;
        }
        else if (modalVal2 === "ccdm2" && !document.querySelector('#edit_ccdm2_input').checked) {
            document.querySelector('#edit_ccdm2_input').checked = true;
            document.querySelector('#ccdm2').style.display = 'none';

        }
        else if (modalVal2 === "cmf2" && document.querySelector('#edit_cmf2_input').checked) {
            document.querySelector('#edit_cmf2_input').checked = false;
        }

        else if (modalVal2 === "cmf2" && !document.querySelector('#edit_cmf2_input').checked) {
            document.querySelector('#edit_cmf2_input').checked = true;
        }
        else if (modalVal2 === "arcm2" && document.querySelector('#edit_arcm2_input').checked) {
            document.querySelector('#edit_arcm2_input').checked = false;
        }
        else if (modalVal2 === "arcm2" && !document.querySelector('#edit_arcm2_input').checked) {
            document.querySelector('#edit_arcm2_input').checked = true;
        }

    }



    // open modal when clicked on advance setting optiions
    const advanceSettingSubmitFunc = (val) => {
        let str = [];
        //let callDeliveryStr = '';

        if (callDeliveryValue === '') {
            ErrorNotify('Please enter call delivery value');
            return;
        }

        //callDeliveryStr = `${callDeliveryMethod}#${callDeliveryValue}`;

        setCallDeliveryMethodStr(callDeliveryMethodStr ? callDeliveryMethodStr : folderUpdationData?.call_back_delivery_method?.split()[0]);

        if (val === 'ccdm') {
            if (callDeliveryMethod === 'SFTP') {
                str.push(username, password, sSHPrivatekey);
                setSFTPData(str);
            }
            if (callDeliveryMethod === 'AWS S3') {
                str.push(aWSAccesskey, aWSSecretAccesskey, aWSRegion);
                setAWSData(str);
            }
            if (callDeliveryMethod === 'OAuth https://') {
                str.push(clientID, clientSecret, grantType, tokenUrl);
                setOAuthData(str);
            }
            //$(`#ccdm_input`).removeAttr('data-toggle', 'modal');
            //$(`#ccdm_input`).removeAttr('data-target', `#ccdm`);
            // $('#ccdm').modal('hide');
            document.querySelector('#ccdm').style.display = 'none';
        }
        if (val === 'ccdm2') {
            if (callDeliveryMethod === 'SFTP') {
                str.push({
                    username: username ? username : folderUpdationData?.sftp_details?.[0]?.username,
                    password: password ? password : folderUpdationData?.sftp_details?.[0]?.password,
                    ssh_private_key: sSHPrivatekey ? sSHPrivatekey : folderUpdationData?.sftp_details?.[0]?.ssh_private_key
                });
                setSFTPData(str);
                setAWSData([]);
                setOAuthData([]);
            }
            if (callDeliveryMethod === 'AWS S3') {
                str.push({ key_id: aWSAccesskey, secret_key: aWSSecretAccesskey, region: aWSRegion });
                setAWSData(str);
                setSFTPData([]);
                setOAuthData([]);
            }
            if (callDeliveryMethod === 'OAuth https://') {
                str.push({ client_id: clientID, client_secret: clientSecret, grant_type: grantType, token_url: tokenUrl });
                setOAuthData(str);
                setAWSData([]);
                setSFTPData([]);

            }
            if (callDeliveryMethod === 'https://') {
                //str.push({client_id:clientID, client_secret:clientSecret, grant_type:grantType, token_url:tokenUrl});
                setOAuthData([]);
                setAWSData([]);
                setSFTPData([]);

            }
            if (callDeliveryMethod === 'http://') {
                //str.push({client_id:clientID, client_secret:clientSecret, grant_type:grantType, token_url:tokenUrl});
                setOAuthData([]);
                setAWSData([]);
                setSFTPData([]);

            }
            if (callDeliveryMethod === 'File system') {
                //str.push({client_id:clientID, client_secret:clientSecret, grant_type:grantType, token_url:tokenUrl});
                setOAuthData([]);
                setAWSData([]);
                setSFTPData([]);

            }
            //$(`#ccdm_input`).removeAttr('data-toggle', 'modal');
            //$(`#ccdm_input`).removeAttr('data-target', `#ccdm`);
            // $('#ccdm').modal('hide');
            document.querySelector('#ccdm').style.display = 'none';
        }

    }
    AWS.config.update({
        accessKeyId: bucketInfo?.key,
        secretAccessKey: bucketInfo?.secret,
        region: bucketInfo?.region
    });

    const s3 = new AWS.S3()
    console.log("JJJJJJJJJJJ", bucketInfo?.$bucket);
    const handleDownload = async (data, type) => {
        // const baseUrl = process.env.REACT_APP_AWS_S3_BUCKET;
        const baseUrl = `https://${bucketInfo?.$bucket}.s3.${bucketInfo?.region}.amazonaws.com/`
        try {
            // Make API request to get the data
            // Create a new zip folder
            const zip = new JSZip();

            // Iterate over the data and create files in the zip folder
            if (data?.data?.length > 0) {
                const promises = data?.data?.map(async (item, index) => {
                    const { call_id, date, json_data, tran_text, folder_path } = item;
                    const rootFolder = zip.folder(downloadFolderName);
                    // const dateFolder = rootFolder.folder(date).folder(call_id);
                    const dateFolder = rootFolder.folder(date);
                    console.log("fileTypeIdsArr", data?.data);


                    if (fileTypeIdsArr?.includes('json') && json_data && Object.keys(json_data)?.length > 0) {
                        const jsonData = JSON.stringify(item, null, 2);
                        dateFolder.file(`${call_id}.json`, jsonData);
                    }


                    if (fileTypeIdsArr.includes('text') && tran_text) {
                        const textContent = tran_text.map(line => line + '\n').join('');
                        dateFolder.file(`${call_id}.txt`, textContent)
                    }

                    if (fileTypeIdsArr.includes('wav')) {
                        const wavFilePath = folder_path + call_id + '.wav';
                        console.log("wavFilePath", wavFilePath);
                        const url = `${baseUrl}${wavFilePath}`;
                        const fileName = url.split('/').pop()
                        const params = {
                            Bucket: bucketInfo?.$bucket,
                            Key: wavFilePath,
                        };
                        console.log("params", params);
                        const wavData = await s3.getObject(params).promise();
                        console.log("wavData", wavData);
                        try {
                            // Use await to wait for the promise to resolve
                            // const wavData = await s3.getObject(params).promise();
                            // console.log("wavData",wavData);
                            const file = new Blob([wavData.Body], { type: 'audio/wav' });
                            dateFolder.file(fileName, file);
                            console.log("Successfully Download");
                        } catch (error) {
                            if (error.code === "NoSuchKey") {
                                console.error(`Error: File '${fileName}' does not exist.`);
                                ErrorNotify(`File '${fileName}' does not exist.`);
                            } else {
                                console.log("error.code", error.code);
                                console.error(`Error downloading WAV file: ${error}`);
                                ErrorNotify(`Error : downloading WAV file: ${error}`);
                            }
                        }

                    }
                });

                await Promise.all(promises);

                zip.generateAsync({ type: 'blob' }).then((blob) => {
                    saveAs(blob, downloadFolderName);
                    setDownloadFileLoading(false)
                    SuccessNotify("Successfully downloaded")
                });
            } else {
                ErrorNotify('No data found');
                setDownloadFileLoading(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                return;
            }



        } catch (error) {
            setDownloadFileLoading(false)
            console.error('Error:', error);
        }
    };


    // callApi function to call all apis
    const callApi = async (url, requestOptions, flag = '') => {
        await fetch(url, requestOptions).then(res => res.json()).then(data => {

            switch (flag) {

                case 'download-data-files':
                    console.log('download-data-files---------', data);
                    handleDownload(data, fileTypeIdsArr)
                    break;

                case 'upload-call':
                    console.log('upload call-------', (data));
                    if (data.status !== 200) {
                        swal(data.error);
                        return
                    }
                    if (data.status === 300) {
                        swal(data.error);
                        return
                    }
                    if (data.status === 200) {
                        SuccessNotify(data.success);
                        setIsDataFound(false);
                        setTimeout(() => {
                            navigate('/smartspeech-call-list')
                            window.location.reload();
                            return;
                        }, 5600);
                    }
                    setFileData(JSON.parse(data.data).files);
                    setPending(false);
                    setLoading(false);
                    break;

                case 'app-list':
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data.status === 200) {
                        // setUnivAppListData(data.data.application.filter((i, j) => i.application_type === 'universal').map(app => app));
                        setAppListData(data?.data);

                    }
                case 'get-bucket-credentials':
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data?.status === 200) {
                        setBucketInfo(data?.data)
                        console.log("bucket", data);
                    }
                    break;
                case 'lang-list':
                    if (isNaN(data.status)) {
                        Logout();
                        return;
                    }
                    if (data.status === 200) {
                        setLangList(data?.data);
                    }
                    break;
                case 'folder-list':
                    if (data.status === 200) {
                        setFolderList(data.data);
                        csvReport = {
                            data: data?.data,
                            headers: [
                                { label: 'Folder Name', key: 'folder_name' },
                                { label: 'Model (Channel)', key: 'model_language_name' },
                                { label: 'Speakers', key: 'speaker_number' },
                                { label: 'Audio Type', key: 'audio_type' },
                                { label: 'Created At', key: 'created_at' },
                            ],
                            filename: "folder_list.csv"

                        };
                    }
                    console.log("csvReportcsvReport", csvReport);
                    console.log('folder-list-------', data)
                    break;

                case 'update-folder-data':
                    setFolderName(folderUpdationData.folder_name)
                    if (folderNameValidation) {
                        ErrorNotify('Folder name Only contain A-Z 0-9 with no spaces');
                        return;
                    }
                    if (folderName === "") {
                        ErrorNotify('Please enter folder name');
                        return;
                    }
                    if (speakersValue === '') {
                        ErrorNotify('Please enter speakers value');
                        return;
                    }
                    if (defaultSpeakersValue === '') {
                        ErrorNotify('Please enter speakers value');
                        return;
                    }

                    else {
                        console.log('update-folder-data------', data)
                        if (data.status === 200) {
                            SuccessNotify(data.message);
                            setTimeout(() => {
                                window.location.reload();
                            }, 5600);
                        }
                        else {
                            ErrorNotify(data.message);
                        }
                    }
                    break;

                case 'update-folder':
                    console.log('update folder------', data);
                    setAlreadySelected([])
                    // setIsDataFound(true);
                    if (data.status === 200 && appListData?.length>0) {
                        setAlreadySelected([])
                        data?.data?.map((i) => {
                            const checkedData = {
                                value: i.application_id.map((i) => i),
                                label: i.application_id?.map((i) =>
                                    appListData?.filter((item) => item?.application_id === i)
                            ),
                        };
                        appListData?.map(i => {
                            setAppData(prevData => [...prevData, { label: i?.application_name, value: i?.application_id }]);
                        })
                        console.log("appListDataappListData",checkedData);
                            checkedData?.label?.map(i => {
                                setApplicationValue((prevSelected) => [
                                    ...prevSelected,
                                    { label: i[0]?.application_name, value: i[0]?.application_id },
                                ])
                            })

                            console.log("appListDataappListData", checkedData);

                            setAlreadySelected((prevSelected) => [
                                ...prevSelected,
                                checkedData,
                            ]);
                        });
                        setFolderUpdationData(data.data[0]);
                        console.log(data.data[0]);
                    }
                    break;

                case 'add-folder':
                    setIsDataFound(true);
                    if (data.status === 200) {
                        SuccessNotify(data.message);
                        setTimeout(() => {
                            setIsDataFound(false);
                            window.location.reload()
                            return;
                        }, 5600);
                    }
                    console.log('add folder------', data)
                    break;

                default:
                    break;
            }
        });
    }


    const columns1 = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'Name',
            },
            {
                Header: 'Model (Channel)',
                accessor: 'Model',
            },
            {
                Header: 'Speakers',
                accessor: 'Speakers',
            },

            {
                Header: 'Audio Type',
                accessor: 'Audio_Type',
            },

            {
                Header: 'Created At',
                accessor: 'Created',
            },
        ],
        []
    )

    const data1 = React.useMemo(

        () => folderList?.filter((item) => {
            return searchValue.toLowerCase() === '' ? item && item : item.folder_name && item.folder_name.toLowerCase().includes(searchValue) || item.audio_type && item.audio_type.toLowerCase().includes(searchValue);
        }).map((i, j) => ({


            action: [<span className="col-actions sorting_1"><ul className="action-strip primary iconic">
                <li><a data-toggle="modal" data-target="#upload_folder_modal" href="javascript:void(0)" onClick={() => [setFolderID(i.folder_id)]}><i className="las la-upload"></i></a><span className="iconic-tooltip">Upload</span></li>
                {/* <li><a data-toggle="modal" onClick={() => [setFolderID(i.folder_id), setDownloadFolderName(i.folder_name)]} data-target="#download_modal" href="javascript:void(0)"><i className="las la-download"></i></a><span className="iconic-tooltip">Download</span></li> */}
                {/* <li><a onClick={() => deleteFolder(i.folder_id)} href="javascript:void(0)"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span></li> */}
                <li><a data-toggle="modal" id={`edit_folder_${j}`} onClick={() => [setFolderID(i.folder_id), updateFolder(i.folder_id, j, i.folder_name, i.speaker_number, i.audio_type, i.model, i.metadata)]} href="javascript:void(0)"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                {/* <li><a data-toggle="modal" data-target="#download_modal" href="javascript:void(0)"><i className="las la-download"></i></a><span className="iconic-tooltip">Download</span></li> */}
                <li>
                    {/* <a onClick={() => deleteFolder(i.folder_id)} href="javascript:void(0)"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span> */}
                    {/* 
                    <OverlayTrigger
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        rootClose={true}
                        overlay={
                            <Popover>
                                <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                <Popover.Body bsPrefix="pop-body" as="div">
                                    <button type="button" className="btn btn-primary" onClick={() => deleteFolder(i.folder_id)}>Yes</button>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <i style={{ fontSize: 20 }} className="las la-trash"></i>
                    </OverlayTrigger> */}

                    <OverlayPopover
                        trigger="click"
                        key="bottom"
                        placement="bottom"
                        rootClose={true}
                        overlay={
                            <Popover>
                                <Popover.Header as="h1">Are you sure you want to delete ?</Popover.Header>
                                <Popover.Body bsPrefix="pop-body" as="div">
                                    <button type="button" className="btn btn-primary" onClick={() => deleteFolder(i.folder_id)}>Yes</button>
                                </Popover.Body>
                            </Popover>}
                    >
                        <i style={{ fontSize: 20 }} className="las la-trash"></i>
                    </OverlayPopover>
                </li>

                {/* <li><a data-toggle="modal" id={`edit_folder_${j}`} onClick={() => updateFolder(i.folder_id, j, i.folder_name, i.speaker_number, i.audio_type, i.model)} href="javascript:void(0)"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span>
                </li> */}
            </ul></span>],
            Name: [<span>{i.folder_name}</span>],
            //Model: [<span>{i.model.length === 0 ? i.model[0].model_name : i.model[0].model_name}</span>],
            Model: [<span>{i?.model_language_name}</span>],
            Speakers: [<span>{i.speaker_number}</span>],
            Audio_Type: [<span>{i.audio_type}</span>],
            Created: [<span>{i.created_at}</span>],
        }))
    );
    // for updating folder
    const updateFolder = (folder_id = '', j, folder_name, speaker_number, audio_type, model, metaData) => {
        setFolderName(folder_name)
        setSpeakersValue(speaker_number)
        setAudioType(audio_type)
        setDefaultSpeakersValue(speaker_number)
        setAppName(onlyAppId)
        setCallDeliveryMethod('SFTP')
        metaContent = metaData?.join(",")

        $(`#edit_folder_${j}`).attr('data-target', '#update_folder_modal');
        var url = `${base_url}folder/getfolderdetails/${folder_id}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            }
        };
        callApi(url, requestOptions, 'update-folder');
    }

    // for deleting folder
    const deleteFolder = (folder_id = '') => {
        var url = `${base_url}folder/delete-folder`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify(
                {
                    "folder_id": folder_id,
                    "updated_by": localStorage.getItem('loginUserUniqueid'),
                    "folder_status": 'false'
                }
            )
        };

        fetch(url, requestOptions).then(res => res.json()).then(data => {
            // console.log('==========', data);
            if (data.status != 200) {
                ErrorNotify(data.message, {
                    icon: "error",
                });
                return;
            }
            SuccessNotify(data.message);
            var url = `${base_url}folder/folder-list`;

            var requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
                }
            };

            callApi(url, requestOptions, 'folder-list');
        })

    }

    const openUploadSection = (folder_id) => {

    }

    const columns2 = [
        { selector: (row) => row.Hash, name: "#", sortable: true },
        { selector: (row) => row.Organization, name: "Organization", sortable: true },
        { selector: (row) => row.Company, name: "Company", sortable: true },
        { selector: (row) => row.Short_Name, name: "Short Name", sortable: true },

        { selector: (row) => row.Data_Retention_days, name: "Data Retention (days)", sortable: true },
        { selector: (row) => row.Timezone, name: "Timezone", sortable: true },
        { selector: (row) => row.Created, name: "Created", sortable: true },
        { selector: (row) => row.Action, name: "Action", sortable: true },

    ];
    const columns3 = [
        { selector: (row) => row.Hash, name: "#", sortable: true },
        { selector: (row) => row.User_Name, name: "User Name", sortable: true },
        { selector: (row) => row.Email, name: "Email", sortable: true },
        { selector: (row) => row.Full_Name, name: "Full Name", sortable: true },

        { selector: (row) => row.Company, name: "Company", sortable: true },

        { selector: (row) => row.Action, name: "Action", sortable: true },

    ];
    const data10 = [
        {
            Company: [<p className=" text-nowrap td-link">MattsenKumar</p>],
            Short_Name: [<p className=" text-nowrap">MK</p>],
            Usage_hours: [<p className=" text-nowrap">6,005.1 of unlimited</p>],
            Data_Retention_days: [<p className=" text-nowrap">Unlimited</p>],
            Auth_Token: [<p className=" text-nowrap"><a href="javasscript:void(0)" className="td-link text-primary">Show...</a></p>],
            Cloud_Token: [<p className=" text-nowrap">None</p>],
            Registration: [<p className=" text-nowrap"><a href="javasscript:void(0)" className="td-link text-primary">Register...</a></p>],
            Permissions: [<p className=" text-nowrap"><a href="javasscript:void(0)" className="td-link text-primary">View Users</a></p>],
            Created: [<p className=" text-nowrap">2021-02-23</p>],
        },


        {
            Company: [<p className=" text-nowrap td-link">MattsenKumar</p>],
            Short_Name: [<p className=" text-nowrap">MK</p>],
            Usage_hours: [<p className=" text-nowrap">6,005.1 of unlimited</p>],
            Data_Retention_days: [<p className=" text-nowrap">Unlimited</p>],
            Auth_Token: [<p className=" text-nowrap"><a href="javasscript:void(0)" className="td-link text-primary">Show...</a></p>],
            Cloud_Token: [<p className=" text-nowrap">None</p>],
            Registration: [<p className=" text-nowrap"><a href="javasscript:void(0)" className="td-link text-primary">Register...</a></p>],
            Permissions: [<p className=" text-nowrap"><a href="javasscript:void(0)" className="td-link text-primary">View Users</a></p>],
            Created: [<p className=" text-nowrap">2021-02-23</p>],
        },

    ];
    const data2 = [

        {
            Hash: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                </ul>
            </td>],
            Organization: [<td className="text-nowrap">All Connect</td>],
            Company: [<td className="text-nowrap">MattsenKumar</td>],
            Short_Name: [<td className="text-nowrap">MK-AllConnect</td>],
            Data_Retention_days: [<td className="text-nowrap">Unlimited</td>],
            Timezone: [<td className="text-nowrap">US/Eastern	</td>],
            Created: [<td className="text-nowrap">2021-02-23	</td>],
            Action: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span></li>
                </ul>
            </td>],
        },



        {
            Hash: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                </ul>
            </td>],
            Organization: [<td className="text-nowrap">All Connect</td>],
            Company: [<td className="text-nowrap">MattsenKumar</td>],
            Short_Name: [<td className="text-nowrap">MK-AllConnect</td>],
            Data_Retention_days: [<td className="text-nowrap">Unlimited</td>],
            Timezone: [<td className="text-nowrap">US/Eastern	</td>],
            Created: [<td className="text-nowrap">2021-02-23	</td>],
            Action: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span></li>
                </ul>
            </td>],
        },
        {
            Hash: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                </ul>
            </td>],
            Organization: [<td className="text-nowrap">All Connect</td>],
            Company: [<td className="text-nowrap">MattsenKumar</td>],
            Short_Name: [<td className="text-nowrap">MK-AllConnect</td>],
            Data_Retention_days: [<td className="text-nowrap">Unlimited</td>],
            Timezone: [<td className="text-nowrap">US/Eastern	</td>],
            Created: [<td className="text-nowrap">2021-02-23	</td>],
            Action: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span></li>
                </ul>
            </td>],
        },

    ];
    const data3 = [
        {
            Hash: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                </ul>
            </td>],
            User_Name: [<td className="text-nowrap">akanksha.sharma</td>],
            Email: [<td className="text-nowrap">akanksha.sharma@mattsenkumar.com</td>],
            Full_Name: [<td className="text-nowrap">Akanksha Sharma</td>],
            Company: [<td className="text-nowrap">MattsenKumar</td>],
            Action: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span></li>
                </ul>
            </td>],
        },

        {
            Hash: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-edit"></i></a><span className="iconic-tooltip">Edit</span></li>
                </ul>
            </td>],
            User_Name: [<td className="text-nowrap">akanksha.sharma</td>],
            Email: [<td className="text-nowrap">akanksha.sharma@mattsenkumar.com</td>],
            Full_Name: [<td className="text-nowrap">Akanksha Sharma</td>],
            Company: [<td className="text-nowrap">MattsenKumar</td>],
            Action: [<td className="text-nowrap text-center">
                <ul className="action-strip primary iconic">
                    <li><a href="#"><i className="las la-trash"></i></a><span className="iconic-tooltip">Delete</span></li>
                </ul>
            </td>],
        },



    ];

    // adding checkboxes values of file types while downloading data files
    const getFileTypeValues = (val, is_checked) => {
        if (is_checked) {
            fileTypeIdsArr.push(val);
        } else {
            let index = fileTypeIdsArr.indexOf(val);
            if (index > -1) {
                fileTypeIdsArr.splice(index, 1);
            }
        }
        // console.log('fileTypeIdsArr--------', fileTypeIdsArr)
    }

    // download data files when clicked on download icon on forlder listing
    const handleDownloadDataFiles = (e) => {
        setDownloadFileLoading(true)
        e.preventDefault();
        if (!fileTypeIdsArr.length) {
            ErrorNotify('Please select at least one file type');
            return;
        }
        if (from_date === '') {
            ErrorNotify('Please select from date');
            return;
        }
        if (to_date === '') {
            ErrorNotify('Please select to date');
            return;
        }
        // url for calling api for downloading data file
        var url = `${base_url}callspeech/bulk-download`;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('loginToken')
            },
            body: JSON.stringify({
                "folder_id": folderID,
                "from_date": from_date,
                "to_date": to_date,
                "types": fileTypeIdsArr
            })
        };

        callApi(url, requestOptions, 'download-data-files');
    }


    const restrictSpaceSpecial = (value) => {
        setFolderName(value);
        setFolderNameValidation(value);
        const regex = /^[A-Za-z0-9]*$/;
        const isValid = regex.test(value);

        if (isValid) {
            setFolderNameValidation('');
        } else {
            setFolderNameValidation('Special characters are not allowed');
        }
        // var k;
        // document.all ? k = e.keyCode : k = e.which;
        // return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }
    const resetVal = () => {
        setModalBool(true)
        setFolderName('')
        setSpeakersValue('')
        setAudioType('')
        setDefaultSpeakersValue('')
        setAppName([])
    }

    let count = 0;
    function handleKeyUp(event) {
        count++
        if (count === 3 && event.key === "Backspace" || count === 3 && event.key === "Delete" || count === 1 && event.key === "Backspace" || count === 1 && event.key === "Delete") {
            setFolderName('')
        }

    }



    const uploadMetaData = () => {
        metaContent = []
        setFolderUpdationData(prevData => ({
            ...prevData,
            metadata: []
        }));
        if (metaFile?.target?.files[0] === undefined) {
            ErrorNotify("Please select .txt file")
            return
        }
        if (metaFile?.target?.files[0]?.name.split(".")[1] !== "txt") {
            ErrorNotify(`${metaFile?.target?.files[0]?.name.split(".")[1]} file type is not supported! Please select only .txt file`)
            return
        }
        const file = metaFile.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            console.log("readerreader", e.target.result);
            metaContent = metaContent?.length > 0 ? e.target.result + `,${metaContent}` : e.target.result
            setDataArray(e.target.result)
        };
        reader.readAsText(file)
        setTimeout(() => {
            document.getElementById("upload").value = ""
        }, 1000);
    }
    console.log("bucketInfo", bucketInfo?.key);



    return (
        <div className="page-wrapper" style={{ backgroundImage: "none" }}>
            {/* <Header />
            <Sidebar /> */}
            <main>
                <ToastContainer />
                <div className="main-contents">
                    <div className="ip-header sc-head sc-collapse">
                        <div className="container-fluid">

                            <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                {/* <h4 className="table-title">Folders List</h4> */}
                                <div className="sc-header">
                                    <h3 className="sc-title mb-0">Folders</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="ip-breadcrumb" aria-label="breadcrumb">
                        <ul className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><span>Smartspeech</span></li>
                            <li className="breadcrumb-item active"><span>Folders</span></li>
                        </ul>
                    </nav>
                    <div className="d-none" id="success_section">
                        <div className="page_error success mb-12">
                            <div className="alert alert-info text-center" id="success_msg"></div>
                        </div>
                    </div>
                    <div className="d-none" id="failure_section">
                        <div className="page_error failure mb-12">
                            <div className="alert alert-info text-center" id="failure_msg">
                            </div>
                            <button className="remove_error_msg"></button>
                        </div>
                    </div>
                    <div className="ip-section mt-5">
                        <div className="container-fluid">
                            <div className="  data_list_div">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    {/* <h4 className="table-title">Folders List</h4> */}
                                    {/* <div className="d-flex align-items-center"> */}
                                    {/* <div className="table-header-group d-inline-flex align-items-center mr-2">
                                            <div className="table-header-control mr-2">
                                                <input type="text" className="form-control" placeholder="Search Companies" />
                                            </div>
                                            <button className="btn btn-outline-primary btn-md"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                        </div> */}
                                    {/* <div className="table-header-group d-inline-flex align-items-center ml-auto mb-3 mr-2">
                                            <a href="#create_folder_modal" onClick={resetVal} data-toggle="modal" className="btn btn-outline-primary btn-md">Create Folder</a>
                                        </div> */}
                                    {/* </div> */}
                                </div>


                            </div>

                        </div>
                        <DataTableView title="Folder List" columns={columns1} isFolderCreation={true} setModalBool={setModalBool} searchValue={searchValue} allData={folderList} isCSVReport={true} isPDFReport={true} isXLSReport={true} csvReport={csvReport} pdfReport={pdfReport} xlsReport={xlsReport} setSearchValue={setSearchValue} data={data1} loading={false} totalRec={data1.length} quickSearch={true} onlyForForms={true} evaluatorSearch={false} />
                    </div>
                    {/* <div className="ip-section mt-5">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div">
                                <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title">Organizations List</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="table-header-group d-inline-flex align-items-center mr-2">
                                            <div className="table-header-control mr-2">
                                                <input type="text" className="form-control" placeholder="Search Organizations" />
                                            </div>
                                            <button className="btn btn-outline-primary btn-md"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable columns={columns2} data={data2} pagination />

                            </div>
                        </div>
                    </div> */}
                    {/* <div className="ip-section mt-5">
                        <div className="container-fluid">
                            <div className="ip-table p-0 data_list_div">
                                <div className="table-header d-flex flex-wrap align-items-center justify-content-between">
                                    <h4 className="table-title">User Accounts</h4>
                                    <div className="d-flex align-items-center">
                                        <div className="table-header-group d-inline-flex align-items-center mr-2">
                                            <div className="table-header-control mr-2">
                                                <input type="text" className="form-control" placeholder="Search Users" />
                                            </div>
                                            <button className="btn btn-outline-primary btn-md"><span>Search</span><span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span></button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable columns={columns3} data={data3} pagination />

                            </div>
                        </div>
                    </div> */}
                </div>
            </main>
            {/* <div className="modal fade category-modal" id="organization-modal" role="dialog" aria-labelledby="category-modal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Create New Organization</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pb-0">
                            <div className="form-group">
                                <label>Organization Name</label>
                                <input type="text" className="form-control" placeholder="Organization Name" />
                            </div>
                            <div className="form-group">
                                <label>Company</label>
                                <select className="form-control custom-select">
                                    <option>Select a Company...</option>
                                    <option>MattsenKumar</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Short Name</label>
                                <input type="text" className="form-control" placeholder="Short Name" />
                                <p className="text-danger">Can only contain alphanumeric characters and underscores; no spaces; prefixed with the company's short name.</p>
                            </div>
                            <div className="form-group">
                                <label>Retain data for # days</label>
                                <input type="text" className="form-control" placeholder="Retain data" />
                                <p className="text-danger">Must be a whole number between 1-3,650</p>
                            </div>
                            <div className="form-group">
                                <label>Timezone</label>
                                <select className="form-control custom-select">
                                    <option>Africa/Abidjan</option>
                                    <option>Africa/Accra</option>
                                    <option>Africa/Addis_Ababa</option>
                                    <option>Africa/Algiers</option>
                                    <option>Africa/Asmara</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary mr-0" data-dismiss="modal">Create</button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <Footer /> */}

            {/* pop up for creating folder starts */}
            <div className={`modal fade modal-fullwidth ${modalBool ? 'show' : 'd-none'}`} id="create_folder_modal" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Create Folder</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <fieldset className="form-part">
                                <legend className="form-part-title">Basic Details</legend>
                                <div className="row">
                                    <div className="col d-block col-sm-6 col-md-4 form-group my-1">
                                        <label><span>Folder Name </span><span className="mandatory">*</span></label>
                                        <input value={folderName} onChange={(e) => [setFolderName(e.target.value), restrictSpaceSpecial(e.target.value)]}
                                            type="text" className="form-control" placeholder="Folder name" required />


                                        {folderNameValidation ? <div className="error" style={{ color: 'red' }} >{folderNameValidation}</div> :
                                            <span className="d-block text-hint">Only contain A-Z 0-9 with no spaces</span>}
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <label><span># of Speakers</span><span className="mandatory">*</span></label>
                                        <select className="form-control" onChange={(e) => { setSpeakersValue(e.target.value); setDefaultSpeakersValue(e.target.value) }} value={speakersValue} required="">
                                            <option defaultValue={defaultSpeakersValue}>Select</option>
                                            {
                                                audioType === "mono" ?
                                                    <>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </>
                                                    :
                                                    audioType === "stereo" ?
                                                        <option value="2">2</option> :
                                                        <>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                        </>



                                            }


                                        </select>
                                        <div className="pci custom-checkbox mt-3">
                                            <input id="pci_text" type="checkbox" className="custom-control-input" onChange={(e) => setPCIText(e.target.checked)} value={PCIText} />
                                            <label for="pci_text" className="custom-control-label">PCI Redact Text</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <label><span>Audio Type</span><span className="mandatory">*</span></label>
                                        <select className="form-control " onChange={(e) => {
                                            setAudioType(e.target.value);
                                            if (e.target.value === "stereo" && defaultSpeakersValue === '1') {
                                                setDefaultSpeakersValue('');
                                                setSpeakersValue('');
                                            } else if (e.target.value === "mono" && defaultSpeakersValue === '2') {

                                                setDefaultSpeakersValue('1');
                                                setSpeakersValue('');
                                            }
                                            else if (e.target.value === "mono") {
                                                setDefaultSpeakersValue('1');
                                            }
                                            else if (e.target.value === "stereo") {
                                                setDefaultSpeakersValue('2');
                                            }
                                            else {
                                                setSpeakersValue('');
                                            }
                                        }} value={audioType} required="">
                                            <option defaultValue="">Select</option>
                                            <option value="mono">Mono</option>
                                            <option value="stereo">stereo</option>
                                        </select>
                                        <div className="pci custom-checkbox mt-3">
                                            <input id="pci_audio" type="checkbox" className="custom-control-input" onChange={(e) => setPCIAudio(e.target.checked)} value={PCIAudio} />
                                            <label for="pci_audio" className="custom-control-label">PCI Redact Audio</label>
                                        </div>
                                    </div>
                                    <div className="col d-block col-sm-6 col-md-4 form-group mb-sm-0">
                                        <label><span>Link to Applications</span></label>
                                        <MultiSelection options={appListData}
                                            customSelectionName={'folder_creation'}
                                            isFilter={false}
                                            selectedApplications={folderUpdationData.application_id}
                                            isFolderCreation={true}
                                            isFolderUpdation={false}
                                            isFormFilter={false}
                                            appNameFunc={appNameFunc} />
                                    </div>

                                    <div className="col d-block col-sm-6 col-md-4 form-group my-0">
                                        <label><span>Model</span><span className="mandatory">*</span></label>

                                        <select className="form-control" style={{ height: "35px" }} onChange={(e) => [setModelValue(e.target.value), modelFunc1(e.target.value, 'Channel 0/ Left', e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)]} required="">
                                            <option defaultValue="">Select</option>
                                            {langList.map((i, j) => {
                                                return (
                                                    <option value={i.language_id}>{i.language_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {audioType === 'stereo' &&
                                        <div className="col d-block col-sm-6 col-md-4 form-group mb-sm-0 my-1">
                                            <label className="form-label">Which agent is on?</label>
                                            <select className="form-control" onChange={(e) => [setChannelValue(e.target.value)]} required="">
                                                <option defaultValue="">Select</option>
                                                <option value="Channel 0/ Left">Channel 0/ Left</option>
                                                <option value="Channel 1/ Right">Channel 1/ Right</option>
                                            </select>
                                        </div>}
                                </div>
                            </fieldset>
                            <fieldset className="form-part">
                                <legend className="form-part-title">Advanced Settings</legend>
                                <div className="row">
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <div className="custom-checkbox">
                                            <input id="ccdm_input" type="checkbox" className="custom-control-input" defaultValue="Configure callback delivery method"
                                                onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked), setModalVal('ccdm'), setCCDMCheckedValue(e.target.checked),
                                                openModal('ccdm', e.target.checked)]}
                                            />
                                            <label for="ccdm_input" className="custom-control-label">Configure callback delivery method</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <div className="custom-checkbox">
                                            <input id="cmf_input" type="checkbox" className="custom-control-input" defaultValue="Add/remove custom metadata fields"
                                                onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked), setModalVal('cmf'), openModal('cmf', e.target.checked)]} />
                                            <label for="cmf_input" className="custom-control-label">Add/remove custom metadata field</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <div className="custom-checkbox">
                                            <input id="arcm_input" type="checkbox" className="custom-control-input" defaultValue="Add/remove ASR options"
                                                onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked), setModalVal('arcm'), openModal('arcm', e.target.checked)]} />
                                            <label for="arcm_input" className="custom-control-label">Add/remove ASR options</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group mb-sm-0">
                                        <div className="custom-checkbox">
                                            <input id="rdr_input" type="checkbox" className="custom-control-input" defaultValue="Reject duplicate records by filename" onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked), openModal('rdr', e.target.checked)]} />
                                            <label for="rdr_input" className="custom-control-label">Reject duplicate records by filename</label>
                                        </div>
                                    </div>
                                    {/* <div className="col col-sm-6 col-md-4 form-group mb-0">
                                        <div className="form-check">
                                            <input id="" type="checkbox" className="form-check-input filled-in" defaultValue="Configure callback option 05" onChange={(e) => getCheckBoxSubValues(e.target.value, e.target.checked)} />
                                            <label for="" className="">Configure callback option 05</label>
                                        </div>
                                    </div> */}
                                </div>
                            </fieldset>
                            <div className="modal-footer justify-content-center pt-0 pb-0">
                                <button onClick={handleCreateFolder} type="button" className={`btn btn-primary ${isDataFound ? 'loading' : ''}`}>
                                    <span>Create Folder</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* pop up for creating folder ends */}

            {/* pop up for updating folder starts */}
            <div className="modal fade modal-fullwidth show" id="update_folder_modal" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Update Folder</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <fieldset className="form-part">
                                <legend className="form-part-title">Basic Details</legend>
                                <div className="row">
                                    <div className="col d-block col-sm-6 col-md-4 form-group my-1">
                                        <label><span>Folder Name </span><span className="mandatory">*</span></label>
                                        <input onKeyUp={handleKeyUp} disabled onChange={(e) => [setFolderName(e.target.value), restrictSpaceSpecial(e.target.value)]}
                                            defaultValue={folderUpdationData.folder_name} type="text" className="form-control" placeholder="Folder name" required="" />
                                        {folderNameValidation ? <div className="error" style={{ color: 'red' }} >{folderNameValidation}</div> :
                                            <span className="d-block text-hint">Only contain A-Z 0-9 with no spaces</span>}
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <label><span># of Speakers</span><span className="mandatory">*</span></label>
                                        {/* <select className="form-control" onChange={(e) => setSpeakersValue(e.target.value)} value={speakersValue !== '' ? speakersValue : folderUpdationData.speaker_number} required="">
                                            <option defaultValue="">Select</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select> */}
                                        <select className="form-control" disabled onChange={(e) => { setSpeakersValue(e.target.value); setDefaultSpeakersValue(e.target.value) }} value={speakersValue} required="">
                                            <option value="">Select</option>
                                            {
                                                audioType === "mono" ?
                                                    <>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                    </>
                                                    :
                                                    audioType === "stereo" ?
                                                        <option value="2">2</option> :
                                                        <>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                        </>



                                            }


                                        </select>
                                        <div className="pci custom-checkbox mt-3">
                                            <input id="pci_text" type="checkbox" className="custom-control-input"
                                                checked={folderUpdationData.pci_redact_text}
                                            //onChange={(e) => setPCIText(e.target.checked)} value={PCIText !== '' ? PCIText : folderUpdationData.pci_redact_text}
                                            //onChange={(e) => setPCIText(e.target.checked)} value={PCIText}
                                            />
                                            <label for="pci_text" className="custom-control-label">PCI Redact Text</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <label><span>Audio Type</span><span className="mandatory">*</span></label>
                                        <select defaultValue="" disabled className="form-control "
                                            //onChange={(e) => [setAudioType(e.target.value)]} 
                                            onChange={(e) => {
                                                setAudioType(e.target.value);
                                                setDefaultSpeakersValue('')
                                                setSpeakersValue('')
                                                // if (e.target.value === "stereo" && defaultSpeakersValue==='1') {
                                                //     setDefaultSpeakersValue('');
                                                //     setSpeakersValue('');
                                                //   } else if (e.target.value === "mono" && defaultSpeakersValue==='2') {

                                                //     setDefaultSpeakersValue('1');
                                                //     setSpeakersValue('');
                                                //   }
                                                //   else if (e.target.value === "mono") {
                                                //     console.log("defaultSpeakersValuedefaultSpeakersValue",defaultSpeakersValue);
                                                //     setDefaultSpeakersValue('1');

                                                //   }
                                                //   else if (e.target.value === "stereo") {
                                                //     setDefaultSpeakersValue('2');
                                                //   }
                                                //   else {
                                                //     setSpeakersValue('');
                                                //   }
                                            }}

                                            value={audioType&&audioType !== '' ? audioType : folderUpdationData.audio_type} required="">
                                            <option defaultValue="">Select</option>
                                            <option value="mono">Mono</option>
                                            <option value="stereo">stereo</option>
                                        </select>
                                        <div className="pci custom-checkbox mt-3">
                                            <input id="pci_audio" type="checkbox" className="custom-control-input"
                                                //  onChange={(e) => setPCIAudio(e.target.checked)} 
                                                //  value={PCIAudio !== '' ? PCIAudio : folderUpdationData.pci_redact_audio} 
                                                checked={folderUpdationData?.pci_redact_audio}
                                            />
                                            <label for="pci_audio" className="custom-control-label">PCI Redact Audio</label>
                                        </div>
                                    </div>
                                    <div className="col d-block col-sm-6 col-md-4 form-group mb-sm-0">
                                        <label><span>Link to Application</span></label>
                                        {/* <MultiSelection options={appListData}
                                        selectedApplications={folderUpdationData.application_id} 
                                         customSelectionName={'folder_updation'} isFilter={false} 
                                         isFolderUpdation={true} isFormFilter={false} appNameFunc={appNameFunc} /> */}

                                        {/* <MultiSelection options={appListData}
                                            customSelectionName={'folder_updation'}
                                            isFilter={false}
                                            selectedApplications={folderUpdationData.application_id}
                                            isFolderUpdation={true}
                                            isFormFilter={false}
                                            alreadySelected={alreadySelected}
                                            labelAndAppIdFolder={labelAndAppIdFolder}
                                            onlyAppId={onlyAppId}
                                            appNameFunc={appNameFunc} /> */}
                                            {console.log("appDataappData",appData)}

                                        <Select
                                            options={appData}
                                            placeholder="Select Application"
                                            isSearchable={true}
                                            isMulti
                                            className=""
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "100px",
                                                    width: "100%",
                                                    boxShadow: "none", // Remove the blue box shadow
                                                    borderColor: "#ccc", // Set a custom border color, or use 'transparent' if you don't want any border color
                                                    "&:hover": {
                                                        borderColor: "#ccc", // Set a custom border color on hover, or use 'transparent' if you don't want any border color on hover
                                                    },
                                                    "&:focus": {
                                                        outline: "none", // Remove the blue outline on focus
                                                        borderColor: "#ccc", // Set a custom border color on focus, or use 'transparent' if you don't want any border color on focus
                                                        boxShadow: "none", // Remove the blue box shadow on focus
                                                    },
                                                    "&:active": {
                                                        borderColor: "#ccc", // Add this to handle the blue outline when an option is selected
                                                        boxShadow: "none", // Add this to handle the blue box shadow when an option is selected
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    display: "none",
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    background: state.isSelected ? "#fff" : "transparent", // Remove background highlight from non-selected options
                                                    color: state.isSelected ? "#000" : "#333", // Change text color of non-selected options

                                                }),
                                            }}
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isClearable={true}
                                            allowSelectAll={true}
                                            components={{
                                                Option,
                                                ValueContainer: ValueContainer2,
                                            }}
                                            value={applicationValue}
                                            onChange={(e, event) => setApplicationValue(e)}

                                        //                                         onChange={(e,event) => 
                                        // [handleFolderChange(e, event), setFolderID(e?.value),e&&Object.keys(e)?.length > 0 ? !whichFilter.includes("filter_by_folder_id") && setWhichFilter((prevValues) => [...prevValues, "filter_by_folder_id"]) : setWhichFilter((prevValues) => prevValues.filter((value) => value !== "filter_by_folder_id"))

                                        //                                         ]}
                                        />
                                    </div>
                                    <div className="col d-block col-sm-6 col-md-4 form-group my-0">
                                        <label><span>Model</span><span className="mandatory">*</span></label>
                                        <select
                                            className="form-control"
                                            style={{ height: "35px" }}
                                            onChange={(e) => {
                                                setModelValue(e.target.value);
                                                const selectedOption = e.nativeEvent.target[e.nativeEvent.target.selectedIndex];
                                                modelFunc1(e.target.value, 'Channel 0/ Left', selectedOption.text);
                                            }}
                                            value={modelValue !== '' ? modelValue : (folderUpdationData?.model !== undefined && folderUpdationData?.model?.[0] !== undefined) ? folderUpdationData?.model[0]?.language_id : ''}
                                            required=""
                                        >
                                            <option value="">Select</option>
                                            {langList?.map((i, j) => (
                                                <option key={j} value={i.language_id}>
                                                    {i.language_name}
                                                </option>
                                            ))}
                                        </select>
                                        {/* {audioType === 'stereo' &&
                                            <>
                                                <div className="ip input-group mb-2 mr-sm-2 mt-4">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">Channel 1/ Right</div>
                                                    </div>
                                                    <div className="input-group-rchild">
                                                        <select className="form-control" onChange={(e) => [setModelValue(e.target.value), modelFunc2(e.target.value, 'Channel 0/ Right', e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)]} required="">
                                                            <option defaultValue="">Select</option>
                                                            {langList.map((i, j) => {
                                                                return (
                                                                    <option value={i.language_id}>{i.language_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        } */}

                                    </div>






















                                    {(audioType === 'stereo' || folderUpdationData.audio_type === 'stereo') &&
                                        <div className="col d-block col-sm-6 col-md-4 form-group mb-sm-0 my-1">
                                            <label className="form-label">Which agent is on?</label>
                                            <select className="form-control" disabled onChange={(e) => [setChannelValue(e.target.value)]} value={channelValue !== '' ? channelValue : folderUpdationData.agent_channel_value} required="">
                                                <option defaultValue="">Select</option>
                                                <option value="Channel 0/ Left">Channel 0/ Left</option>
                                                <option value="Channel 1/ Right">Channel 1/ Right</option>
                                            </select>
                                        </div>}
                                </div>
                            </fieldset>
                            <fieldset className="form-part">
                                <legend className="form-part-title">Advanced Settings</legend>
                                <div className="row">
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <div className="custom-checkbox my-checkbox">
                                            <input
                                                //id={checked === 'checked' ? '' : "ccdm_input"} 
                                                id="edit_ccdm2_input"
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultValue="Configure callback delivery method" onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked),
                                                setModalVal2('ccdm2'),
                                                setShowCheckBoxModal(folderUpdationData?.call_back_delivery_method?.split('.')[0].split(':')[0]),
                                                setCCDMCheckedValue(e.target.checked),
                                                openModal2('ccdm2', e.target.checked),
                                                ]}
                                            />
                                            <label for="edit_ccdm2_input" className="custom-control-label">Configure callback delivery method</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <div className="custom-checkbox">
                                            {folderUpdationData.advanced_settings !== undefined && folderUpdationData.advanced_settings.map((i, j) => {
                                                if (i === 'Add/remove custom metadata fields') {
                                                    checked = 'checked';
                                                }
                                            })}
                                            <input
                                                //id={checked === 'checked' ? '' : "cmf_input"} 
                                                id="edit_cmf2_input"
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultValue="Add/remove custom metadata fields"
                                                onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked),
                                                //checked === 'checked'?checked='':checked='checked',
                                                setModalVal2('cmf2'),
                                                openModal2('cmf2', e.target.checked)]} />
                                            <label for="edit_cmf2_input"
                                                className="custom-control-label"
                                            >Add/remove custom metadata field</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group">
                                        <div className="custom-checkbox">
                                            {folderUpdationData.advanced_settings !== undefined && folderUpdationData.advanced_settings.map((i, j) => {
                                                if (i === 'Add/remove ASR options') {
                                                    checked = 'checked';
                                                }
                                            })}
                                            <input
                                                id="edit_arcm2_input"
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultValue="Add/remove ASR options"
                                                onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked), setModalVal2('arcm2'), openModal2('arcm2', e.target.checked)]} />
                                            <label for="edit_arcm2_input"
                                                className="custom-control-label"
                                            >Add/remove ASR option</label>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6 col-md-4 form-group mb-sm-0">
                                        <div className="custom-checkbox">
                                            {folderUpdationData.advanced_settings !== undefined && folderUpdationData.advanced_settings.map((i, j) => {
                                                if (i === 'Reject duplicate records by filename') {
                                                    checked = 'checked';
                                                }
                                            })}
                                            <input type="checkbox"
                                                className="custom-control-input"
                                                disabled
                                                id="edit_rdr2_input"
                                                onChange={(e) => [getCheckBoxSubValues(e.target.value, e.target.checked),
                                                openModal('rdr2', e.target.checked)]} />
                                            <label for="edit_rdr2_input" className="custom-control-label">Reject duplicate records by filename</label>
                                        </div>
                                    </div>
                                    {/* <div className="col col-sm-6 col-md-4 form-group mb-0">
                                        <div className="form-check">
                                            <input id="" type="checkbox" className="form-check-input filled-in" defaultValue="Configure callback option 05" onChange={(e) => getCheckBoxSubValues(e.target.value, e.target.checked)} />
                                            <label for="" className="">Configure callback option 05</label>
                                        </div>
                                    </div> */}
                                </div>
                            </fieldset>
                            <div className="modal-footer justify-content-center pt-0 pb-0">
                                <button onClick={handleUpdateFolder} type="button" className={`btn btn-primary ${isDataFound ? 'loading' : ''}`}>
                                    <span>Update Folder</span>
                                    <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* pop up for updating folder ends */}

            {/* pop up for download files */}
            <div className="modal fade show multi-level-modal small-modal" id="download_modal" tabindex="-1">
                <div className="modal-dialog">
                    <form className="modal-content" action="" method="post" encType="multipart/form-data">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Download Data Files</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-contents mb-0">
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label text-right">File Type : </label>
                                    <div className="col-sm-8 col-md-9">
                                        <div className="custom-checkbox d-inline-block mr-3">
                                            <input id="checkbox_opt_01" type="checkbox" className="custom-control-input" onChange={(e) => getFileTypeValues('json', e.target.checked)} />
                                            <label for="checkbox_opt_01" className="custom-control-label">JSON</label>
                                        </div>
                                        <div className="custom-checkbox d-inline-block mr-3">
                                            <input id="checkbox_opt_02" type="checkbox" className="custom-control-input" onChange={(e) => getFileTypeValues('text', e.target.checked)} />
                                            <label for="checkbox_opt_02" className="custom-control-label">Text</label>
                                        </div>
                                        <div className="custom-checkbox d-inline-block">
                                            <input id="checkbox_opt_03" type="checkbox" className="custom-control-input" onChange={(e) => getFileTypeValues('wav', e.target.checked)} />
                                            <label for="checkbox_opt_03" className="custom-control-label">WAV</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-4 col-md-3 col-form-label text-right">From :</label>
                                    <div className="col-sm-8 col-md-9">
                                        <input step={1} type="date" max={`${moment().format('YYYY-MM-DD')}T00:00`} className="form-control " autoComplete="off" placeholder="Call Date & Time" required value={from_date} onChange={(e) => [setFromDate(e.target.value)]} />
                                    </div>
                                </div>
                                <div className="form-group row align-items-center mb-0">
                                    <label className="col-sm-4 col-md-3 col-form-label text-right">To :</label>
                                    <div className="col-sm-8 col-md-9">
                                        <input step={1} type="date" min={`${moment().format('YYYY-MM-DD')}T00:00`} className="form-control " autoComplete="off" placeholder="Call Date & Time" required value={to_date} onChange={(e) => [setToDate(e.target.value)]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button onClick={handleDownloadDataFiles} type="submit" className={`btn btn-primary ${downloadFileLoading ? 'loading' : ''}`}>
                                <span>Download</span>
                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>



            {/* pop up for download files */}

            {/* pop up for uploading starts */}
            <div className="modal fade show multi-level-modal small-modal" id="upload_folder_modal" tabindex="-1">
                <div className="modal-dialog">
                    <form className="modal-content" action="" method="post" encType="multipart/form-data">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Call Upload</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-contents mb-0">
                                <div className="form-group">
                                    <label className="form-label">Upload File</label>
                                    <input name="files" multiple className="form-control" type="file" required="" onChange={(e) => { setFile(e.target.files); console.log("e.target.files", e.target.files); }} />
                                </div>
                                {/* <div className="col-sm-4 col-md-4 col-lg-4 form-group">
                                        <div className="text-right">
                                            <a href="" className="btn btn-outline-primary" download="">Download Sample</a>
                                        </div>
                                    </div> */}
                                <div className="form-group mb-0">
                                    <h4>Instructions:</h4>
                                    <ul>

                                        <li>File size should be less than 1 GB.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button type="submit" onClick={(e) => handleFileSelect(e)} className={`btn btn-primary ${isDataFound ? 'loading' : ''}`}>
                                <span>Submit</span>
                                <span className="loader"><span className="spinner-border" role="status" aria-hidden="true"></span></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* pop up for uploading ends */}
            {/* modal for ccdm */}

            <div className="modal fade show multi-level-modal small-modal" id="ccdm" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Configure callback delivery method</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleModalValidation} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label><span>Automatically send...</span></label>
                                <div className="pl-3">
                                    <div className="custom-checkbox d-inline-block mr-3">
                                        <input id="checkbox_opt_0001" type="checkbox" className="custom-control-input" onChange={(e) => setAutomaticallyType('json', e.target.checked)} />
                                        <label for="checkbox_opt_0001" className="custom-control-label">JSON</label>
                                    </div>
                                    <div className="custom-checkbox d-inline-block mr-3">
                                        <input id="checkbox_opt_0002" type="checkbox" className="custom-control-input" onChange={(e) => setAutomaticallyType('text', e.target.checked)} />
                                        <label for="checkbox_opt_0002" className="custom-control-label">Text</label>
                                    </div>
                                    <div className="custom-checkbox d-inline-block">
                                        <input id="checkbox_opt_0003" type="checkbox" className="custom-control-input" onChange={(e) => setAutomaticallyType('wav', e.target.checked)} />
                                        <label for="checkbox_opt_0003" className="custom-control-label">WAV</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label><span>Callback delivery Method</span></label>
                                <div className="row no-gutters">
                                    <div className="col-4 col-lg-3">
                                        <select className="form-control" style={{ width: "auto" }} onChange={(e) => [setCallDeliveryMethod(e.target.value), resetAllValues(e.target.value)]}>
                                            <option value="https://">https://</option>
                                            <option value="http://">http://</option>
                                            <option value="File system">File system</option>
                                            <option value="SFTP">SFTP</option>
                                            <option value="AWS S3">AWS S3</option>
                                            <option value="OAuth https://">OAuth https://</option>
                                        </select>
                                    </div>
                                    <div className="col-8 col-lg-9">
                                        <input type="text" className="form-control" style={{ width: "250px", marginLeft: "30px" }} placeholder="host:port/path/to/folder" onChange={(e) => setCallDeliveryValue(e.target.value)} />
                                    </div>
                                </div>
                                <span className="d-block text-hint">Exmaple: username.password@host:port/path/to/folder?option=param</span>
                            </div>

                            {callDeliveryMethod === 'SFTP' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" className="form-control" placeholder="username" onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>SSH Private key</label>
                                        <input type="text" className="form-control" placeholder="SSH Private key" onChange={(e) => setSSHPrivatekey(e.target.value)} />
                                    </div>
                                </div>
                            }
                            {callDeliveryMethod === 'AWS S3' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>AWS access key id</label>
                                        <input type="text" className="form-control" placeholder="AWS Access Key" onChange={(e) => setAWSAccesskey(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>AWS secret access key</label>
                                        <input type="text" className="form-control" placeholder="AWS Secret Access key" onChange={(e) => setAWSSecretAccesskey(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Region (Optional)</label>
                                        <input type="text" className="form-control" placeholder="Region (Optional)" onChange={(e) => setAWSRegion(e.target.value)} />
                                    </div>
                                </div>
                            }
                            {callDeliveryMethod === 'OAuth https://' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Client id</label>
                                        <input type="text" className="form-control" placeholder="Client ID" onChange={(e) => setClientID(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Client secret</label>
                                        <input type="text" className="form-control" placeholder="Client Secret" onChange={(e) => setClientSecret(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Grant Type</label>
                                        <input type="text" className="form-control" placeholder="Grant Type" onChange={(e) => setGrantType(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Token URL</label>
                                        <input type="text" className="form-control" placeholder="Token URL" onChange={(e) => setTokenUrl(e.target.value)} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button onClick={() => advanceSettingSubmitFunc('ccdm')} type="button" className="btn btn-primary"><span>Submit</span></button>
                        </div>
                    </div>
                </div>
            </div>








            <div className="modal fade show multi-level-modal small-modal" id="ccdm2" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Configure callback delivery method</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleModalValidation2} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label><span>Automatically send...</span></label>
                                <div className="pl-3">
                                    <div className="custom-checkbox d-inline-block mr-3">
                                        <input id="checkbox_opt_00012" type="checkbox"
                                            // className="custom-control-input" 
                                            onChange={(e) => setAutomaticallyType('json', e.target.checked)} />
                                        <label for="checkbox_opt_00012"
                                        //  className="custom-control-label"
                                        >JSON</label>
                                    </div>
                                    <div className="custom-checkbox d-inline-block mr-3">
                                        <input id="checkbox_opt_00022" type="checkbox"
                                            // className="custom-control-input" 
                                            onChange={(e) => setAutomaticallyType('text', e.target.checked)} />
                                        <label for="checkbox_opt_00022"
                                        // className="custom-control-label"
                                        >Text</label>
                                    </div>
                                    <div className="custom-checkbox d-inline-block">
                                        <input id="checkbox_opt_00032" type="checkbox"
                                            //  className="custom-control-input"
                                            onChange={(e) => setAutomaticallyType('wav', e.target.checked)} />
                                        <label for="checkbox_opt_00032"
                                        //className="custom-control-label"
                                        >WAV</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label><span>Callback delivery Method</span></label>
                                <div className="row no-gutters">
                                    <div className="col-4 col-lg-3">
                                        <select className="form-control"
                                            style={{ width: "auto" }}
                                            id="cbdelivery"
                                            onChange={(e) => [setCallDeliveryMethod(e.target.value), setShowCheckBoxModal(e.target.value), setRenderData(e.target.value), resetAllValues(e.target.value)]}>
                                            <option value="https://">https://</option>
                                            <option value="http://">http://</option>
                                            <option value="File system">File system</option>
                                            <option value="SFTP">SFTP</option>
                                            <option value="AWS S3">AWS S3</option>
                                            <option value="OAuth https://">OAuth https://</option>
                                        </select>
                                    </div>
                                    <div className="col-8 col-lg-9">
                                        <input type="text" id="port" style={{ width: "250px", marginLeft: "30px" }} className="form-control" placeholder="host:port/path/to/folder" onChange={(e) => setCallDeliveryValue(e.target.value)} />
                                    </div>
                                </div>
                                <span className="d-block text-hint">Exmaple: username.password@host:port/path/to/folder?option=param</span>
                            </div>

                            {callDeliveryMethod === 'SFTP' && !showCheckBoxModal === '' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" className="form-control" placeholder="username" onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>SSH Private key</label>
                                        <input type="text" className="form-control" placeholder="SSH Private key" onChange={(e) => setSSHPrivatekey(e.target.value)} />
                                    </div>
                                </div>
                            }
                            {showCheckBoxModal === 'SFTP' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" className="form-control" id="username" placeholder="username" onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control" id="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>SSH Private key</label>
                                        <input type="text" className="form-control" id="sshprivate" placeholder="SSH Private key" onChange={(e) => setSSHPrivatekey(e.target.value)} />
                                    </div>
                                </div>
                            }

                            {callDeliveryMethod === 'AWS S3' && !showCheckBoxModal === '' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>AWS access key isssd</label>
                                        <input type="text" className="form-control" id="awsaccesskey" placeholder="AWS Access Key" onChange={(e) => setAWSAccesskey(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>AWS secret access key</label>
                                        <input type="text" className="form-control" placeholder="AWS Secret Access key" onChange={(e) => setAWSSecretAccesskey(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Region (Optional)</label>
                                        <input type="text" className="form-control" placeholder="Region (Optional)" onChange={(e) => setAWSRegion(e.target.value)} />
                                    </div>
                                </div>
                            }



                            {showCheckBoxModal === 'AWS S3' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>AWS access key</label>
                                        <input type="text" className="form-control" id="awsaccesskey" placeholder="AWS Access Key" onChange={(e) => setAWSAccesskey(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>AWS secret access key</label>
                                        <input type="text" className="form-control" id="awssecretkey" placeholder="AWS Secret Access key" onChange={(e) => setAWSSecretAccesskey(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Region (Optional)</label>
                                        <input type="text" className="form-control" id="region" placeholder="Region (Optional)" onChange={(e) => setAWSRegion(e.target.value)} />
                                    </div>
                                </div>
                            }











                            {callDeliveryMethod === 'OAuth https://' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Client id</label>
                                        <input type="text" className="form-control" placeholder="Client ID" onChange={(e) => setClientID(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Client secret</label>
                                        <input type="text" className="form-control" placeholder="Client Secret" onChange={(e) => setClientSecret(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Grant Type</label>
                                        <input type="text" className="form-control" placeholder="Grant Type" onChange={(e) => setGrantType(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Token URL</label>
                                        <input type="text" className="form-control" placeholder="Token URL" onChange={(e) => setTokenUrl(e.target.value)} />
                                    </div>
                                </div>
                            }
                            {showCheckBoxModal === 'OAuth https' &&
                                <div className="form-group-container">
                                    <div className="form-group">
                                        <label>Client id</label>
                                        <input type="text" className="form-control" id="clientid" placeholder="Client ID" onChange={(e) => setClientID(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Client secret</label>
                                        <input type="text" className="form-control" id="clientsecret" placeholder="Client Secret" onChange={(e) => setClientSecret(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Grant Type</label>
                                        <input type="text" className="form-control" id="grantype" placeholder="Grant Type" onChange={(e) => setGrantType(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label>Token URL</label>
                                        <input type="text" className="form-control" id="tokenurl" placeholder="Token URL" onChange={(e) => setTokenUrl(e.target.value)} />
                                    </div>
                                </div>
                            }


                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button onClick={() => {
                                advanceSettingSubmitFunc('ccdm2'); handleModalValidation2();

                            }}
                                type="button" className="btn btn-primary"><span>Submit</span></button>
                        </div>
                    </div>
                </div>
            </div>


























            {/* modal for ccdm */}
            {/* modal for arcm */}
            <div className="modal fade show multi-level-modal" id="arcm2" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Add/remove ASR options</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleModalValidation2} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group asr-field">
                                <label className=""><span>Enter key\value pair</span></label>
                                <div className="row no-gutters">
                                    <div className="col-auto flex-grow-1 mr-2"><input type="text"
                                        defaultValue={JSON.stringify(folderUpdationData?.metadata && folderUpdationData?.metadata)}
                                        className="form-control" placeholder="Key" onChange={(e) => setkeyData(e.target.value)} /></div>
                                    <div className="col-auto flex-grow-1"><input type="text" className="form-control" placeholder="Value" onChange={(e) => setValueData(e.target.value)} /></div>
                                    <div className="col-auto pl-2">
                                        <a href="#" className="d-block plus-btn asr-add-btn" onClick={addASROptions}></a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className=""><span>ASR options</span></label>
                                <div className="asrfield-collection">
                                    {asrOptionsData.map((i, j) => {
                                        return (
                                            <div className="data-chip"><span>{i}</span>
                                                <button onClick={() => removeASROptions(i)} type="button" className="chip-del"><i className="las la-times"></i></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button type="button" className="btn btn-secondary"><span>Back</span></button>
                            <button type="button" onClick={() => document.querySelector('#arcm').style.display = 'none'} className="btn btn-primary"><span>Create Folder</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show multi-level-modal" id="arcm" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Add/remove ASR options</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleModalValidation} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group asr-field">
                                <label className=""><span>Enter key\value pair</span></label>
                                <div className="row no-gutters">
                                    <div className="col-auto flex-grow-1 mr-2"><input type="text" className="form-control" placeholder="Key" onChange={(e) => setkeyData(e.target.value)} /></div>
                                    <div className="col-auto flex-grow-1"><input type="text" className="form-control" placeholder="Value" onChange={(e) => setValueData(e.target.value)} /></div>
                                    <div className="col-auto pl-2">
                                        <a href="#" className="d-block plus-btn asr-add-btn" onClick={addASROptions}></a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className=""><span>ASR options</span></label>
                                <div className="asrfield-collection">
                                    {asrOptionsData.map((i, j) => {
                                        return (
                                            <div className="data-chip"><span>{i}</span>
                                                <button onClick={() => removeASROptions(i)} type="button" className="chip-del"><i className="las la-times"></i></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button type="button" className="btn btn-secondary"><span>Back</span></button>
                            <button type="button" onClick={() => document.querySelector('#arcm').style.display = 'none'} className="btn btn-primary"><span>Create Folder</span></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal for arcm */}

            {/* modal for cmf */}
            <div className="modal fade show multi-level-modal" id="cmf" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Add/remove custom metadata fields</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleModalValidation} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group asr-field">
                                <label className=""><span>Upload Metadata</span></label>
                                <div className="row">
                                    <div className="col-auto "><input type="file" id="upload" className="form-control" onChange={(e) => setMetaFile(e)} /></div>
                                    <div className="col-auto pl-2">
                                        <div class="col-auto tooltip-outer">
                                            <button type="submit" id="uploadKeySubmit" onClick={uploadMetaData} class="btn btn-primary btn-tooltip" fdprocessedid="0svlh5">
                                                <span><i class="las la-upload"></i></span>
                                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <label className="my-3"><span>Enter key\value pair</span></label>
                                <div className="row no-gutters">
                                    <div className="col-auto flex-grow-1"><input type="text" className="form-control" id="custom_key" placeholder="Key" onChange={(e) => setCmfKeyData(e.target.value)} /></div>
                                    <div className="col-auto pl-2">
                                        <a href="#" className="d-block plus-btn asr-add-btn" onClick={addCMFOptions}></a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className=""><span>CMF options</span></label>
                                <div className="asrfield-collection">
                                    {console.log("OOOOOOOOOOOO", typeof metaContent)}
                                    {metaContent?.length > 0 && metaContent?.split(',').map((i, j) => {
                                        return (
                                            <div className="data-chip"><span>{i}</span>
                                                <button onClick={() => removeCMFOptions(i)} type="button" className="chip-del"><i className="las la-times"></i></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            {/* <button type="button" className="btn btn-secondary"><span>Back</span></button> */}
                            <button type="button" onClick={() => document.querySelector('#cmf').style.display = 'none'} className="btn btn-primary"><span>Submit</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show multi-level-modal" id="cmf2" tabindex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header ip-gradient">
                            <h5 className="modal-title">Add/remove custom metadata fields</h5>
                            <button type="button" className="close" data-dismiss="modal" onClick={handleModalValidation2} aria-label="Close"></button>
                        </div>
                        {/* <div className="modal-body">
                            <div className="form-group asr-field">
                                <label className=""><span>Enter key\value pair</span></label>
                                <div className="row no-gutters">
                                    <div className="col-auto flex-grow-1"><input type="text"
                                        defaultValue={JSON.stringify(folderUpdationData?.metadata && folderUpdationData?.metadata)}
                                        className="form-control" placeholder="Key" onChange={(e) => setCmfKeyData(e.target.value)} /></div>
                                    <div className="col-auto pl-2">
                                        <a href="#" className="d-block plus-btn asr-add-btn" onClick={addCMFOptions}></a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className=""><span>CMF options</span></label>
                                <div className="asrfield-collection">
                                    {cmfOptionsData.map((i, j) => {
                                        return (
                                            <div className="data-chip"><span>{i}</span>
                                                <button onClick={() => removeCMFOptions(i)} type="button" className="chip-del"><i className="las la-times"></i></button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div> */}
                        <div className="modal-body">
                            <div className="form-group asr-field">
                                <label className=""><span>Upload Metadata</span></label>
                                <div className="row">
                                    <div className="col-auto "><input type="file" id="upload" className="form-control" onChange={(e) => setMetaFile(e)} /></div>
                                    <div className="col-auto pl-2">
                                        <div class="col-auto tooltip-outer">
                                            <button type="submit" id="uploadKeySubmit" onClick={uploadMetaData} class="btn btn-primary btn-tooltip" fdprocessedid="0svlh5">
                                                <span><i class="las la-upload"></i></span>
                                                <span class="loader"><span class="spinner-border" role="status" aria-hidden="true"></span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <label className="my-3"><span>Enter key\value pair</span></label>
                                <div className="row no-gutters">
                                    <div className="col-auto flex-grow-1"><input type="text" className="form-control" id="custom_key" placeholder="Key" onChange={(e) => setCmfKeyData(e.target.value)} /></div>
                                    {/* <div className="col-auto flex-grow-1"><input type="text" className="form-control" placeholder="Value" onChange={(e) => setValueData(e.target.value)} /></div> */}
                                    {/* <div className="col-auto pl-2"><button type="button" className="plus-btn asr-add-btn"></button></div> */}
                                    <div className="col-auto pl-2">
                                        <a href="#" className="d-block plus-btn asr-add-btn" onClick={addCMFOptions}></a>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className=""><span>CMF options</span></label>
                                <div className="asrfield-collection">
                                    {console.log("OOOOOOOOOOOO", metaContent.length > 0 && metaContent.split(','))}
                                    {folderUpdationData?.metadata?.length > 0 ? folderUpdationData?.metadata?.map((i, j) => {
                                        return (
                                            <div className="data-chip"><span>{i}</span>
                                                <button onClick={() => removeCMFOptions(i)} type="button" className="chip-del"><i className="las la-times"></i></button>
                                            </div>
                                        )
                                    })
                                        :
                                        metaContent.length > 0 && metaContent.split(',').map((i, j) => {
                                            return (
                                                <div className="data-chip"><span>{i}</span>
                                                    <button onClick={() => removeCMFOptions(i)} type="button" className="chip-del"><i className="las la-times"></i></button>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center border-top">
                            <button type="button" onClick={() => { document.querySelector('#cmf2').style.display = 'none'; handleModalValidation2() }} className="btn btn-primary"><span>Submit</span></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal for cmf */}
        </div >
    )
}

export default SSAccounts;